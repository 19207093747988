import React, { Fragment, useEffect, useRef } from 'react';
import { setToastNotification } from '../utils/helpers';

const PaypalCheckout = ({
  setIsShow,
  setLoading,
  username,
  startPurchase,
  validatePaypalPurchase,
}) => {
  const paypalRef = useRef();
  useEffect(() => {
    if (!window.mybutton)
      window.mybutton = window.paypal
        .Buttons({
          style: {
            layout: 'horizontal',
            height: 50,
            width: 150,
            shape: 'rect',
            size: 'responsive',
            tagline: false,
            display: 'paypal',
          },
          createOrder: async (data, actions) => {
            const _quantity =
              window.document.getElementById('quantity').value ||
              window.document.getElementById('button_quantity')?.innerText;
            const payload = {
              username,
              payment_method: 'paypal',
              currency: 'USD',
              items: [{ type: 'credits', quantity: _quantity }],
            };
            const purchaseInfo = await startPurchase(payload);
            return actions.order.create({
              purchase_units: [
                {
                  reference_id: purchaseInfo.uid,
                  invoice_id: purchaseInfo.uid,
                  amount: {
                    value: _quantity / 1000,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setLoading(false);
            setIsShow(false);
            const payload = {
              uid: order.purchase_units[0].reference_id,
              tx: data.orderID,
            };
            const purchase = await validatePaypalPurchase(payload);
            console.log(purchase);
            if (order.status === 'COMPLETED' && purchase.success === 'OK') {
              setToastNotification('Credit purchase successful', 'success');
            }
            window.location.reload();
          },
          onError: (err) => {
            setToastNotification(err, 'error');
            console.error(err);
          },
        })
        .render(paypalRef.current);
  }, [setLoading, setIsShow, startPurchase, validatePaypalPurchase, username]);
  return (
    <Fragment>
      <div ref={paypalRef} />
    </Fragment>
  );
};

export default PaypalCheckout;
