import React, { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import UploadLoader from '../UploadLoader';
import { toFixedWithoutRounding } from '../../utils/helpers';

const SellBook = ({
  sellbook,
  loading,
  setInstanceToSell,
  setIsShowPrice,
  setIsShowManage,
  cancelSell,
  buyToken,
  addToCart,
  cart,
  removeFromCart,
  username,
  series,
  usd_price,
  collectible,
  isUser,
  price_cart,
  removePriceCart,
  addPriceCart,
  handleSelectAll,
  marketLoading,
  setMarketLoading,
}) => {
  const [page, setPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [localSellbook, setLocalSellbook] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % sellbook.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalSellbook(
      sellbook.filter((d) => String(d?.series) === String(series)).slice(offset, endOffset),
    );
    setPage(Math.ceil(sellbook.length / itemPerPage));
  }, [sellbook, series, offset]);
  if (!isUser) {
    return (
      <Fragment>
        {marketLoading ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className='collectible__action__buttons nft__table__max__height my-3'>
              <div className='nft__table__header'>
                <div className='nft__table__header__content'>
                  <div className='nft__table__row'>
                    <div className='nft__table__row__item nft__table__market'>
                      Edition
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__market__series'>
                      Series
                    </div>
                    <div className='nft__table__row__item nft__table__market'>
                      Owner
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__market__price'>
                      Price
                    </div>
                    <div className='nft__table__row__item nft__table__market  nft__table__market__buttons'></div>
                    {/* sellbook[0]?.account !== username */}
                    {sellbook.some(
                      (d) => d.account !== username && d.series === series,
                    ) && (
                      <div className='nft__table__row__item nft__table__market nft__table__market__actions'>
                        {' '}
                        Action
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='nft__table'>
                <div className='nft__table__content'>
                  {loading && sellbook ? (
                    <div className='nft__table__row'>
                      <div className='nft__table__row__item'>
                        {' '}
                        <UploadLoader />
                      </div>
                    </div>
                  ) : (
                    localSellbook
                      .filter((sb) => sb.series === series)
                      ?.map((instance) => {
                        return (
                          <div
                            className='nft__table__row'
                            key={instance?.nft_id}
                          >
                            <div className='nft__table__row__item nft__table__market'>
                              No. {instance?.edition}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__market__series'>
                              {`${instance?.series
                                .split('_')[1]
                                .replace(/-/g, ' ')
                                .toUpperCase()}...`}
                            </div>
                            <div className='nft__table__row__item nft__table__market'>
                              {instance?.account}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__market__price'>
                              {instance.price} {instance.symbol}( $
                              {toFixedWithoutRounding(
                                instance.price * usd_price,
                              )}
                              )
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__market__buttons'>
                              {instance.account === username ? (
                                <div className='flex'>
                                  <button
                                    disabled={true}
                                    className='btn btn-success btn-sm m-1'
                                    onClick={() => {
                                      setInstanceToSell(instance);
                                      setIsShowPrice(true);
                                    }}
                                  >
                                    <i className='fa fa-cogs'></i>
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-info btn-sm m-1'
                                    onClick={() => {
                                      setInstanceToSell(instance);
                                      setIsShowManage(true);
                                    }}
                                  >
                                    <i className='fa fa-pencil-alt'></i>
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-danger btn-sm m-1'
                                    onClick={() => {
                                      cancelSell(instance.nft_id);
                                      setMarketLoading(true);
                                    }}
                                  >
                                    <i className='fa fa-times'></i>
                                  </button>
                                </div>
                              ) : (
                                <div className='nft__market__button__group d-flex justify-content-end w-100'>
                                  <button
                                    className='nft__buy__button'
                                    onClick={() => {
                                      buyToken(
                                        instance.price,
                                        instance.nft_id.toString(),
                                      );
                                      setMarketLoading(true);
                                    }}
                                  >
                                    Buy
                                  </button>
                                  <button
                                    className='nft__bid__button'
                                    disabled={true}
                                    onClick={() => {
                                      buyToken(
                                        instance.price,
                                        instance.nft_id.toString(),
                                      );
                                      setMarketLoading(true);
                                    }}
                                  >
                                    Bid
                                  </button>
                                </div>
                              )}
                            </div>
                            {sellbook.some(
                              (d) =>
                                d.account !== username && d.series === series,
                            ) && (
                              <div className='nft__table__row__item nft__table__market nft__table__market__actions'>
                                {cart.find(
                                  (item) => item.nft_id === instance.nft_id,
                                ) === undefined ? (
                                  <button
                                    className='nft__add__cart__button'
                                    onClick={() =>
                                      addToCart({
                                        ...instance,
                                        thumbnail: collectible.thumbnail,
                                        name: collectible.name,
                                        description: collectible.description,
                                      })
                                    }
                                  >
                                    <i
                                      className='fa fa-shopping-cart'
                                      aria-hidden='true'
                                    ></i>{' '}
                                    {''}
                                    <span>add</span>
                                  </button>
                                ) : (
                                  <button
                                    className={
                                      cart.find(
                                        (item) =>
                                          item.nft_id === instance.nft_id,
                                      ) !== undefined
                                        ? `nft__del__cart__button btn-danger`
                                        : ''
                                    }
                                    onClick={() =>
                                      removeFromCart(instance.nft_id)
                                    }
                                  >
                                    <i
                                      className='fa fa-shopping-cart'
                                      aria-hidden='true'
                                    ></i>{' '}
                                    <span>del</span>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
            <div className='pagination__action__buttons'>
              <ReactPaginate
                breakLabel='...'
                nextLabel={<button>Next</button>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={page}
                activeClassName='active'
                previousLabel={<button>Prev</button>}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        )}
      </Fragment>
    );
  }
  if (isUser) {
    return (
      <>
        <div className='collectible__action__buttons text-center my-3'>
          <div className='nft__table__header'>
            <div className='nft__table__header__content'>
              <div className='nft__table__row'>
                <div className='nft__table__row__item nft__table__market'>
                  Edition
                </div>
                <div className='nft__table__row__item nft__table__market'>
                  Series
                </div>
                <div className='nft__table__row__item nft__table__market'>
                  Creator
                </div>
                <div className='nft__table__row__item nft__table__market'>
                  Price
                </div>
                <div className='nft__table__row__item nft__table__market'>
                  Action
                </div>
                <div className='nft__table__row__item nft__table__market'>
                  <input
                    type='checkbox'
                    name='select_all'
                    // checked={allSelected}
                    onChange={(e) => {
                      // console.log(sellbook);
                      handleSelectAll(e, sellbook);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='nft__table'>
            <div className='nft__table__content'>
              {localSellbook
                ?.filter((inst) => inst?.series === series)
                ?.map((instance) => {
                  if (!sellbook)
                    return (
                      <div className='nft__table__row'>
                        <UploadLoader />
                      </div>
                    );
                  return (
                    <div className='nft__table__row' key={instance?.nft_id}>
                      <div className='nft__table__row__item nft__table__market'>
                        No. {instance?.edition}
                      </div>
                      <div className='nft__table__row__item nft__table__market'>
                        {instance?.series
                          .split('_')[1]
                          .replace(/-/g, ' ')
                          .toUpperCase()}
                      </div>
                      <div className='nft__table__row__item nft__table__market'>
                        {instance?.account}
                      </div>
                      <div className='nft__table__row__item nft__table__market'>
                        {instance.price} (
                        {toFixedWithoutRounding(instance.price * usd_price)}{' '}
                        USD)
                      </div>
                      <div className='nft__table__row__item nft__table__market'>
                        <div className='flex'>
                          <button
                            disabled={true}
                            className='btn btn-success btn-sm m-1'
                            onClick={() => {
                              setInstanceToSell(instance);
                              setIsShowPrice(true);
                            }}
                          >
                            <i className='fa fa-dollar-sign'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-info btn-sm m-1'
                            onClick={() => {
                              setInstanceToSell(instance);
                              setIsShowManage(true);
                            }}
                          >
                            <i className='fa fa-pencil-alt'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-danger btn-sm m-1'
                            onClick={() => {
                              cancelSell(instance.nft_id);
                              setMarketLoading(true);
                            }}
                          >
                            <i className='fa fa-times'></i>
                          </button>
                        </div>
                      </div>
                      <div className='nft__table__row__item nft__table__market'>
                        <input
                          type='checkbox'
                          name={instance.nft_id}
                          id=''
                          checked={
                            price_cart.includes(instance.nft_id?.toString())
                              ? true
                              : false
                          }
                          onChange={() =>
                            price_cart.includes(instance.nft_id?.toString())
                              ? removePriceCart(instance.nft_id?.toString())
                              : addPriceCart([instance.nft_id?.toString()])
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className='pagination__action__buttons'>
          <ReactPaginate
            breakLabel='...'
            nextLabel={<button>Next</button>}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={page}
            activeClassName='active'
            previousLabel={<button>Prev</button>}
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    );
  }
};

export default SellBook;
