import { CONVERT_CREDITS, GET_CREDITS } from './types';
import axios from '../utils/axios';

export const convertCredits = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post('/credits/convert', payload);
    dispatch({
      type: CONVERT_CREDITS,
      payload: data.balances,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getCredits = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.get('/credits/get');
    dispatch({
      type: GET_CREDITS,
      payload: data,
    });
  } catch (err) {
    console.error(err.message);
  }
}