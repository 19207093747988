import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../assets/images/HERO_IMG.png';

export const Hero = () => {
  return (
    <div className='hero__wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='hero__header__text'>
              <h1>
                Create. Mint <br /> & Explore <br />
                <span>Collectible TUNZ</span>
              </h1>
              <p>
                Create, Mint and Explore Music NFT's from the world's top
                artists. More than 70k NFTs already minted and waiting to be
                yours.
              </p>

              <div className='hero__header__buttons'>
                <Link to='/register'>Get Started</Link>
              </div>
            </div>
            <div className='hero__stats'>
              <div className='hero__stats__item'>
                <div className='hero__stats__item__text'>
                  <h3>
                    75k <span>+</span>
                  </h3>
                  <p>
                    Total Number of <br /> NFTS
                  </p>
                </div>
              </div>
              <div className='hero__stats__item'>
                <div className='hero__stats__item__text'>
                  <h3>
                    340k <span>+</span>
                  </h3>
                  <p>
                    Total Number of <br /> TRX
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='hero__header__image'>
              <img src={hero} alt='hero' />
              <p>
                <i>
                  Sandi Thom is Scottish singer-songwriter and has now minted
                  her chart topping hit 'I Wish I Was A Punk Rocker' as a
                  collectible Music NFT. Grab your edition now on the market.
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
