import React from 'react'

const EarningCard = ({earning}) => {
  return (
    <div className='earning__card__wrapper'>
      <div className='earning__card__thumbnail__wrapper'>
        <img
          className='earning__card__thumbnail'
          src={earning?.series_thumbnail}
          alt={earning?.series}
        />
        {/* <small>{earning?.creator}</small> */}
      </div>
      <div className='earning__card__item collection__name'>
        <strong style={{ marginRight: 'auto' }}>name</strong>
        <span style={{ marginRight: 'auto' }}>{earning?.name}</span>
      </div>
      <div className='earning__card__item'>
        <strong>max editions </strong>
        <span>{earning?.editions}</span>
      </div>
      <div className='earning__card__item'>
        <strong>units sold</strong>
        <span className='text-right'>{earning?.sold} editions </span>
      </div>
      <div className='earning__card__item'>
        <strong>price</strong>
        <div>
          <span className='text-right'>{earning?.price} </span>
          <span className='text-right'>{earning?.symbol} </span>
        </div>
      </div>
      <div className='earning__card__item'>
        <strong>total earned</strong>
        <div>
          <span className='text-right'>{earning?.total} </span>
          <span className='text-right'>{earning?.symbol} </span>
        </div>
      </div>
    </div>
  );
}

export default EarningCard