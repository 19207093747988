import axios from 'axios';
import { utils } from '@hiveio/dhive';
import { GET_USER_TRANSACTIONS, GET_MARKET_TRANSACTIONS } from './types';
import { sidechain_rpc } from '../utils/constants';
import { parseJSON, setToastNotification } from '../utils/helpers';
import instance from '../utils/axios';

const call = async (endpoint, request) => {
  const postData = {
    jsonrpc: '2.0',
    id: Date.now(),
    ...request,
  };
  let result = null;
  const query = await axios.post(`${sidechain_rpc}/${endpoint}`, postData, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  result = query.data.result;
  return await result;
};

const blockchain = async (request) => await call('blockchain', request);

// eslint-disable-next-line no-unused-vars
const contract = async (request) => await call('contracts', request);

export const getTransactions = (username) => async (dispatch) => {
  const trx = await instance.get(`/transactions/history?types=transfer`);
  const filtered_trx = trx?.data?.filter((t) => t.account === username);
  dispatch({
    type: GET_USER_TRANSACTIONS,
    payload: filtered_trx,
  });
};
export const getMarketTransactions = (query) => async (dispatch) => {
  let params = {};
  if (query.username) {
    params.username = query.username;
  }
  if (query.series) {
    params.series = query.series;
  }
  if (!query.types) {
    params.types =
      'issue,buy,burn,transfer,auction_create,auction_bid,auction_update_bid,auction_cancel_bit,auction_cancel,auction_settle,auction_expired';
  } else {
    params.types = query.types;
  }

  const trx = await instance.get(`/transactions/history`, { params });

  dispatch({
    type: GET_MARKET_TRANSACTIONS,
    payload: trx.data,
  });
};

export const validateTransaction = async (trxId) => {
  let error = false;
  let trx = null;
  let count = 0;

  const request = {
    method: 'getTransactionInfo',
    params: {
      txid: trxId,
    },
  };

  do {
    try {
      trx = await blockchain(request);
      await utils.sleep(3000);
    } catch (e) {
      console.log(e);
    }

    count += 1;
  } while (!trx && count < 10);

  if (trx) {
    const logs = parseJSON(trx.logs);

    if (logs.errors) {
      error = logs.errors[0];
      setToastNotification(error || 'Something went wrong', 'error');
    } else if (trx.contract === 'hivepegged') {
      if (trx.action === 'withdraw') {
        setToastNotification('HIVE withdrawal has been successful.', 'success');
      }
    } else if (trx.contract === 'nftmarket') {
      if (trx.action === 'buy') {
        setToastNotification('NFT buy has been successful.', 'success');
      }
      if (trx.action === 'sell') {
        setToastNotification('NFT sell has been successful.', 'success');
      }
    } else if (trx.contract === 'nft') {
      if (trx.action === 'transfer') {
        setToastNotification('NFT transfer has been successful.', 'success');
      }
    } else if (trx.contract === 'nftairdrops') {
      if (trx.action === 'newAirdrop') {
        setToastNotification('NFT Airdrop has been successful.', 'success');
      }
    } else if (trx.contract === 'tokens') {
      if (trx.action === 'transfer') {
        setToastNotification(
          'Mint Request has been sent successfully.',
          'success',
        );
      }
    } else if (trx.contract === 'nftauction') {
      if (trx.action === 'create') {
        setToastNotification('NFT Auction Created Successfully.', 'success');
      }
      if (trx.action === 'cancel') {
        setToastNotification('NFT Auction has been cancelled', 'success');
      }
      if (trx.action === 'settle') {
        setToastNotification(
          'NFT Auction has been settled with highest bidder',
          'success',
        );
      }
      if (trx.action === 'bid') {
        setToastNotification('NFT bid placed successfully', 'success');
      }
      if (trx.action === 'cancelBid') {
        setToastNotification('NFT bid cancelled successfully', 'success');
      }
    }
  }
};

export const validateInternalTransaction = async (trxId, message = '') => {
  let trx = null;
  let count = 0;

  do {
    try {
      trx = await this.$API.$get('transactions/find', {
        params: { trx_id: trxId },
      });
      await this.$HIVE.sleep(3000);
    } catch (e) {
      console.log(e);
    }

    count += 1;
  } while (!trx && count < 10);
  if (trx) {
    setToastNotification(
      message || 'Transaction has been successful.',
      'success',
    );
  } else {
    setToastNotification('There has been an error.', 'error');
  }
};
