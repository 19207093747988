import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import VideoPlayer from "react-video-markers";
import {
  getUserCollectibles,
  getCollectibles,
  getReceivedCollectibles,
} from '../../actions/collectibles';
import Cards from '../../components/cards/Cards';
import UploadLoader from '../../components/UploadLoader';
import MediaPlayer from '../../components/modals/MediaPlayer';
import { sleep } from '../../utils/helpers';

const ReceivedCollectibles = ({
  received_collectibles,
  collectible_loading,
  getReceivedCollectibles,
  username,
  nfts,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [showPlayer, setShowPlayer] = useState(false);
  const [file, setFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const timer = sleep;
  const handleSearch = async (text) => {
    setSearchText(text);
    setLoading(true);
    await search(text);
    return setLoading(false);
  };
  const search = (text) => {
    const filtered = received_collectibles.filter((received_collectible) => {
      return (
        received_collectible.creator
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        received_collectible.description
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        received_collectible.name.toLowerCase().includes(text.toLowerCase())
      );
    });
    console.log(filtered);
    setFilteredData(filtered);
    return timer(1000);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVolume = (value) => {
    setVolume(value);
  };
  useEffect(() => {
    getReceivedCollectibles({
      account: username,
    });
  }, [getReceivedCollectibles, username]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header'>
              <h6>Here are collections transferred to you by other users</h6>
            </div>
            <div className='search-box d-flex justify-content-center my-3'>
              <form className='d-flex justify-center align-center w-50'>
                <div className='nfttunz__navbar__search__wrapper w-100'>
                  <input
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    className='nfttunz__navbar__search__input text-center w-100'
                    type='search'
                    placeholder='Search by a keyword'
                    aria-label='Search'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'></div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          <div className='row'>
            {received_collectibles === null && collectible_loading ? (
              <UploadLoader size='small' />
            ) : searchText?.length > 0 && !loading && filteredData?.length < 1 ? (
              <div className='col-md-12'>
                <div className='no__results'>
                  <h6 className='text-center mt-5'>
                    No results found for the keyword "{searchText}"
                  </h6>
                </div>
              </div>
            ) : received_collectibles?.length < 1 && !collectible_loading ? (
              <div className='col-md-12'>
                <div className='no__results'>
                  <h6 className='text-center mt-5'>
                    You have not received any received yet...
                  </h6>
                </div>
              </div>
            ) : (
              <Cards
                songs={
                  filteredData.length > 0 ? filteredData : received_collectibles
                }
                showPlayer={showPlayer}
                handleShowPlayer={setShowPlayer}
                setFile={setFile}
                setThumbnail={setThumbnail}
                market={false}
                col={true}
                nfts={nfts}
              />
            )}
          </div>
        </div>
      </div>
      <MediaPlayer
        show={showPlayer}
        handleClose={setShowPlayer}
        handlePause={handlePause}
        handlePlay={handlePlay}
        handleVolume={handleVolume}
        volume={volume}
        isPlaying={isPlaying}
        file={file}
        thumbnail={thumbnail}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  received_collectibles: state.collectibles.received_collectibles,
  collectible_loading: state.collectibles.loading,
  username: state.users.username,
  nfts: state.nfts.instances,
});
export default connect(mapStateToProps, {
  getUserCollectibles,
  getCollectibles,
  getReceivedCollectibles,
})(ReceivedCollectibles);
