import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const NotificationItem = ({ type, notification, readNotifications }) => {
  // const { from, edition, series } = JSON.parse(notification.data);
  if (type === 'buy')
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h6 className='p-2'>You have just bought a song:</h6>
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition: </strong>
            {JSON.parse(notification.data).edition} of series{' '}
            {JSON.parse(notification.data).series} from{' '}
            {JSON.parse(notification.data).seller} for{' '}
            {JSON.parse(notification.data).price}{' '}
            <strong>{JSON.parse(notification.data).symbol}</strong>
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  if (type === 'sell')
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h6>
            You have just <strong>sold</strong> a song:
          </h6>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition: </strong>
            {JSON.parse(notification.data).edition} of series{' '}
            {JSON.parse(notification.data).series} to{' '}
            <strong>{JSON.parse(notification.data).buyer}</strong> for{' '}
            {JSON.parse(notification.data).price}{' '}
            <strong>{JSON.parse(notification.data).symbol}</strong>
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );

  if (type === 'gift') {
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h4>
            You have just{' '}
            <strong>
              {JSON.parse(notification.data).from === notification.account
                ? 'sent an airdrop'
                : 'received an airdrop'}
            </strong>{' '}
            of a song(NFT):
          </h4>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition </strong>
            {JSON.parse(notification.data).edition} of series{' '}
            {JSON.parse(notification.data).series}{' '}
            {JSON.parse(notification.data).from === notification.account ? (
              <>
                to{' '}
                <Link to={`/@${JSON.parse(notification.data).to}`}>
                  {<strong>{JSON.parse(notification.data).to}</strong>}
                </Link>
              </>
            ) : (
              <>
                from{' '}
                <Link to={`/@${JSON.parse(notification.data).from}`}>
                  <strong>{JSON.parse(notification.data).from}</strong>
                </Link>
              </>
            )}
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
            <Link to={`/collectible/${JSON.parse(notification.data).series}`}>
              <strong className='notification__time'>Goto Page</strong>
            </Link>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  }
  if (type === 'royalty_payment') {
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h4>
            You just <strong>earned a royalty</strong> of{' '}
            {JSON.parse(notification.data)?.amount}{' '}
            {JSON.parse(notification.data)?.symbol} from a song(NFT) sold:
          </h4>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition </strong>
            {JSON.parse(notification.data)?.edition} of series{' '}
            {JSON.parse(notification.data)?.series}{' '}
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
            <a
              href={`https://hive-engine.rocks/tx/${
                JSON.parse(notification.data)?.ref_trx
              }`}
              rel='noreferrer'
            >
              <strong className='notification__time'>View Trx</strong>
            </a>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  }
  if (type === 'cancel_bid') {
    const { ref_trx, series, edition, bidder } = JSON.parse(notification.data);
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h4>
            @<Link to={`/@${bidder}`}>{bidder}</Link> has cancelled his bid for:
          </h4>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition </strong>
            {edition} of series {series}{' '}
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
            <a
              href={`https://hive-engine.rocks/tx/${ref_trx}`}
              rel='noreferrer'
            >
              <strong className='notification__time'>View Trx</strong>
            </a>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  }
  if (type === 'received_bid') {
    const { ref_trx, series, edition, bidder } = JSON.parse(notification.data);
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h4>
            @<Link to={`/@${bidder}`}>{bidder}</Link> has placed a bid for:
          </h4>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition </strong>
            {edition} of series {series}{' '}
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
            <a
              href={`https://hive-engine.rocks/tx/${ref_trx}`}
              rel='noreferrer'
            >
              <strong className='notification__time'>View Trx</strong>
            </a>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  }
  if (type === 'settle_auction') {
    const { ref_trx, series, edition, winner, seller } = JSON.parse(
      notification.data,
    );
    return (
      <Fragment>
        {seller === notification.account ? (
          <li className='notification__item__each' key={notification.id}>
            <h4>
              You have settled the auction with @
              <Link to={`/@${winner}`}>{winner}</Link> as the winner for:
            </h4>{' '}
            <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
              <strong>Edition </strong>
              {edition} of series {series}{' '}
              <small className='notification__time'>
                <Moment fromNow>{notification.timestamp}</Moment>
              </small>
              <a
                href={`https://hive-engine.rocks/tx/${ref_trx}`}
                rel='noreferrer'
              >
                <strong className='notification__time'>View Trx</strong>
              </a>
            </p>
            <button
              className='bg-transparent mark-as-read-button mx-0 px-0'
              onClick={() => readNotifications([notification.id])}
            >
              mark as read
            </button>
          </li>
        ) : (
          <li className='notification__item__each' key={notification.id}>
            <h4>
              @<Link to={`/@${seller}`}>{seller}</Link> has settled the auction
              with you for:
            </h4>{' '}
            <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
              <strong>Edition </strong>
              {edition} of series {series}{' '}
              <small className='notification__time'>
                <Moment fromNow>{notification.timestamp}</Moment>
              </small>
              <a
                href={`https://hive-engine.rocks/tx/${ref_trx}`}
                rel='noreferrer'
              >
                <strong className='notification__time'>View Trx</strong>
              </a>
            </p>
            <button
              className='bg-transparent mark-as-read-button mx-0 px-0'
              onClick={() => readNotifications([notification.id])}
            >
              mark as read
            </button>
          </li>
        )}
      </Fragment>
    );
  }
  if (type === 'expired_auction') {
    const { ref_trx, series, edition, seller } = JSON.parse(notification.data);
    return (
      <Fragment>
        <li className='notification__item__each' key={notification.id}>
          <h4>
            @<Link to={`/@${seller}`}>{seller}</Link>'s auction is now ended{' '}
            <Link to={`/auction/${series}`}>click to see</Link> the winner:
          </h4>{' '}
          <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
            <strong>Edition </strong>
            {edition} of series {series}{' '}
            <small className='notification__time'>
              <Moment fromNow>{notification.timestamp}</Moment>
            </small>
            <a
              href={`https://hive-engine.rocks/tx/${ref_trx}`}
              rel='noreferrer'
            >
              <strong className='notification__time'>View Trx</strong>
            </a>
          </p>
          <button
            className='bg-transparent mark-as-read-button mx-0 px-0'
            onClick={() => readNotifications([notification.id])}
          >
            mark as read
          </button>
        </li>
      </Fragment>
    );
  }
  if (type === 'cancel_auction') {
    const { ref_trx, series, edition, seller } = JSON.parse(notification.data);
    return (
      <Fragment>
        {
          <li className='notification__item__each' key={notification.id}>
            <h4>
              @<Link to={`/@${seller}`}>{seller}</Link> has cancelled the
              auction for:
            </h4>{' '}
            <p className='p-2 border-bottom notification-paragraph mx-0 px-0'>
              <strong>Edition </strong>
              {edition} of series {series}{' '}
              <small className='notification__time'>
                <Moment fromNow>{notification.timestamp}</Moment>
              </small>
              <a
                href={`https://hive-engine.rocks/tx/${ref_trx}`}
                rel='noreferrer'
              >
                <strong className='notification__time'>View Trx</strong>
              </a>
            </p>
            <button
              className='bg-transparent mark-as-read-button mx-0 px-0'
              onClick={() => readNotifications([notification.id])}
            >
              mark as read
            </button>
          </li>
        }
      </Fragment>
    );
  }

  return null;
};

export default NotificationItem;
