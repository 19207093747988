import React, { Fragment, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import UploadLoader from '../UploadLoader';
import { copyToClipboard } from '../../utils/helpers';
import Moment from 'react-moment';

const EarningsTransactionHistory = ({ transactions, series, title, username }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [localTrx, setLocalTrx] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemPerPage) % transactions.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalTrx(
      transactions
        ?.filter((trx) => trx.type === 'buy' && trx.account !== username)
        ?.slice(offset, endOffset),
    );
    setPage(Math.ceil(transactions?.length / itemPerPage));

    setLoading(false);
  }, [transactions, series, username, offset]);

  return (
    <Fragment>
      <div className='collectible__action__buttons text-center my-3'>
        <h1 className='market__transaction__header my-4'>{title || 'Transactions'}</h1>
        <div className='nft__table__header'>
          <div className='nft__table__header__content'>
            <div className='nft__table__row'>
              <div className='nft__table__row__item transaction__history__table'>
                Date
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Series
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Edition
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Account
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Type
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Price
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <UploadLoader size='small' />
        ) : (
          <>
            <div className='nft__table'>
              <div className='nft__table__content'>
                {localTrx?.map((trx, i) => {
                  return (
                    <div className='nft__table__row' key={i}>
                      <div className='nft__table__row__item transaction__history__table'>
                        <Moment format='DD/MM/YYYY'>{trx?.timestamp}</Moment>
                      </div>

                      <div
                        datatoggle='tool-tip'
                        dataplacement='top'
                        title='Copy Series'
                        className='nft__table__row__item transaction__history__table pointer'
                        onClick={() => copyToClipboard(trx?.series)}
                      >
                        {trx?.series
                          .split('_')[1]
                          .replace(/-/g, ' ')
                          .toUpperCase()}
                      </div>
                      <div className='nft__table__row__item transaction__history__table'>
                        {trx.data?.edition || 'N/A'}
                      </div>
                      <div className='nft__table__row__item transaction__history__table'>
                        {trx?.account}
                      </div>
                      <div className='nft__table__row__item transaction__history__table'>
                        {trx?.type}
                      </div>
                      <div className='nft__table__row__item transaction__history__table'>
                        {trx.data?.price || 'N/A'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='pagination__action__buttons'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<button>Next</button>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={page}
          activeClassName='active'
          previousLabel={<button>Prev</button>}
          renderOnZeroPageCount={null}
        />
      </div>
    </Fragment>
  );
};

export default EarningsTransactionHistory;
