import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  auth: { loading, authenticated, username, admin }, settings: {admins, adminLoading},
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated && admin !== true && !loading ? (
          <Redirect to='/' />
        ) : !authenticated || !admin ? <Redirect to='/' /> : (
          <Component {...props} />
        )
      }
    />
  );
};
const mapStateToProps = (state) => ({
  auth: state.users,
  settings: state.settings
});
export default connect(mapStateToProps, {})(PrivateRoute);
