import { GET_TOP_CREATORS, GET_HOME_STATS } from './types';
import axios from '../utils/axios';



export const getTopCreators = () => async (dispatch) => {
  try {
    const data = await axios.get('/statistics/top-creators');
    dispatch({
      type: GET_TOP_CREATORS,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getHomeStats = () => async (dispatch) => {
  try {
    const data = await axios.get('/statistics/home-stats');
    dispatch({
      type: GET_HOME_STATS,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};