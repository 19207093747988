import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Loader from '../../../components/Loader';
import { getAdminTransactions } from '../../../actions/market';
import { connect } from 'react-redux'
import AdminTransactionTable from '../../../components/AdminTransactionTable';

const AdminTransactions = ({
  transactions: { transaction_history , loading},
  getAdminTransactions,
}) => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    getAdminTransactions(page);
  }, [getAdminTransactions, page]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row bg__white'>
          <div className='col-md-12'>
            {loading && <Loader />}
            {transaction_history.length < 1 ? (
              <h4 className='text-center p-3 m-0'>
                Oops! No data or transactions at the moment...
              </h4>
            ) : (
              <AdminTransactionTable
                data={transaction_history}
                page={page}
                setPage={setPage}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({ 
  transactions: state.market
 })

export default connect(mapStateToProps, {getAdminTransactions})(AdminTransactions);
