import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import axios from '../../../utils/axios';
import { setToastNotification } from '../../../utils/helpers';
import Loader from '../../../components/Loader';
import ApplicationsTable from '../../../components/ApplicationsTable';

const ArtisteApplications = () => {
  const [localLoading, setLocalLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const getWhitelistApplications = async () => {
    setLocalLoading(true);
    const data = await axios.get('/admin/artiste-applications');
    setApplications(data.data);
    setLocalLoading(false);
  };
  const approveWhitelist = async (username, action, value) => {
    setLocalLoading(true);
    await axios.post('/admin/artiste-whitelist', {
      username,
      action,
      value,
    });
    setLocalLoading(false);
    setToastNotification('Admin action request successful', 'success');
    window.location.reload();
  };

  useEffect(() => {
    getWhitelistApplications();
  }, []);
  return (
    <Fragment>
      <div className='container'>
        <div className='row bg__white'>
          <div className='col-md-12'>
            {localLoading && <Loader />}
            {applications.length < 1 ? (
              <h4 className='text-center p-3 m-0'>
                Oops! No application at the moment...
              </h4>
            ) : (
              <ApplicationsTable
                category='artiste'
                data={applications}
                type='applications'
                approveWhitelist={approveWhitelist}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ArtisteApplications;
