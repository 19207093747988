import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  ADD_SELL_CART,
  REMOVE_SELL_CART,
  CLEAR_SELL_CART,
  ADD_PRICE_CART,
  REMOVE_PRICE_CART,
  CLEAR_PRICE_CART,
} from '../actions/types';

const intialState = {
  cart: JSON.parse(localStorage.getItem('cart')) || [], // array of objects or instances
  sell_cart: [], // sell cart is for sell price
  price_cart: [], // price_cart is for price_cart
  total: Number(localStorage.getItem('cart_total')), // total price
};

export const cartReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_CART:
      localStorage.setItem('cart', JSON.stringify([...state.cart, payload]));
      localStorage.setItem('cart_total', state.total + payload.price);
      return {
        ...state,
        cart: [...state.cart, payload],
        total: state.total + payload.price,
      };

    case REMOVE_FROM_CART:
      const item = state.cart.find((i) => i.nft_id === payload);
      let updatedCart = state.cart.filter((item) => item.nft_id !== payload);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      localStorage.setItem('cart_total', state.total - item.price);
      return {
        ...state,
        cart: state.cart.filter((item) => item.nft_id !== payload),
        total: state.total - item.price,
      };
    case ADD_SELL_CART:
      return {
        ...state,
        sell_cart: [...state.sell_cart, ...payload],
      };
    case REMOVE_SELL_CART:
      return {
        ...state,
        sell_cart: state.sell_cart.filter((s) => s !== payload),
      };
    case CLEAR_SELL_CART:
      return {
        ...state,
        sell_cart: [],
      };
    case ADD_PRICE_CART:
      return {
        ...state,
        price_cart: [...state.price_cart, ...payload],
      };
    case REMOVE_PRICE_CART:
      return {
        ...state,
        price_cart: state.price_cart.filter((s) => s !== payload),
      };
    case CLEAR_PRICE_CART:
      return {
        ...state,
        price_cart: [],
      };
    case CLEAR_CART:
      localStorage.removeItem('cart_total')
      localStorage.removeItem('cart')
      localStorage.removeItem('usd_price')
      return {
        ...state,
        cart: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
