import { GET_COMMUNITIES, GET_COMMUNITY } from '../actions/types';

const initialState = {
  communities: [],
  community: {},
  loading: true,
};

export const communityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMUNITIES:
      return {
        ...state,
        communities: payload,
        loading: false,
      };
    case GET_COMMUNITY:
      return {
        ...state,
        community: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default communityReducer;
