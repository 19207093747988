import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className='accordion-each-item'>
      <div className='accordion-title' onClick={() => setIsActive(!isActive)}>
        <h6>{title}</h6>

        <div>
          {isActive ? (
            <i className='fa fa-minus'></i>
          ) : (
            <i className='fa fa-plus'></i>
          )}
        </div>
      </div>
      {
        <div
          className={`accordion-content ${
            isActive ? 'accordion-show' : 'accordion-hide'
          }`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      }
    </div>
  );
};

export default Accordion;
