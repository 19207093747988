export const accordionData = [
  {
    title: 'What is an NFT?',
    content: `There are more ways to purchase and collect music than ever before, and some of them are virtual!
    You may be wondering if it's possible to collect music in the same way you would collect other types of art. The answer is yes! While the world of NFTs is still young, there are many exciting ways to buy and collect music.`,
  },
  {
    title: 'Who is using the NFTs?',
    content: `Music fans, who appreciate their favorite artists and are interested in buying limited-edition collectibles, can own a piece of memorabilia. A super-fan, who plans on opening an artist’s museum someday, can invest in their future. This category also includes collectors who want to own pieces from every era, genre or region.`,
  },
  {
    title: 'How are they being used?',
    content: `NFTs can be used to buy and sell music, music related merchandise, and anything else you can think of that connects in some way to an artist or song. They're even being used to buy and sell experiences and services from artists like concerts, meet-and-greets, and coaching programs.`,
  },
  {
    title: 'Will they really be around forever?',
    content: `NFTs are a new technology that is still being developed. As such, there’s no way to know how they will develop over time.`,
  },
  {
    title: 'Why would you collect NFTs?',
    content: `Show off your taste in music. Collecting NFTs is a great way to show off your musical taste and knowledge. You can use them to show the world what you think are the best songs, albums, artists, etc. Support your favorite artists. Supporting artists by buying their NFTs is another way to help them out and make sure they get paid for what they do!`,
  },
  {
    title: 'How do I get started?',
    content: `To begin creating and collecting you will need a Hive account and Hive Keychain. Hive Keychain is a browser extension that securely encrypts your keys and allows you to safely and easily authorize transactions on the platform.

    Get a free account on the GET STARTED tab
    Install the Hive Keychain browser extension: <a href='https://hive-keychain.com/' rel='noopener' target='_blank'>https://hive-keychain.com/</a>`,
  },
  {
    title: 'What do I need to use NFTTUNZ?',
    content: `Once you have your account and keychain extension installed, you will need some credits which can be purchased from your wallet tab using PayPl and many crypto coins.`,
  },
  {
    title: 'What is Hive-Engine and why do we need it?',
    content: `Hive-Engine is a smart contract platform built on the Hive Blockchain. One of the smart contracts on Hive-Engine is the NFT contract that NFT Showroom uses to operate the digital marketplace.`,
  },
  {
    title: 'What is SWAP.HIVE?',
    content: `The Hive blockchain uses the HIVE cryptocurrency as the medium of exchange. Hive-Engine utilizes a HIVE backed token called “SWAP.HIVE” to execute all the smart contracts. To interact with NFT Showroom users are required to convert Hive to SWAP.HIVE. You can do this in your wallet on nftshowroom.com by clicking the deposit button and entering the desired amount. Simply sign the transaction with keychain and your Hive will be deposited as SWAP.HIVE.

        There is a 1% fee when converting between HIVE and SWAP.HIVE.
        `,
  },
  {
    title: 'How does TUNZ work?',
    content: ` On TUNZ artists can create limited edition tokenized music, video and fan art that can be sold on the market. Collectors can buy, sell and trade limited edition art. TUNZ also offers artists the option to make the art available for commercial uses, which adds additional security for both the artist and collector.

        Fee structure and secondary sales

        NFT creation costs 0.001 SWAP.HIVE per edition. Upon completion of the initial sale TUNZ collects a 10% commission. Upon completion of secondary market sales TUNZ collects a 10% commission and pays all of it to the original artist.

        Currently items can only be listed with a buy it now price. Bidding is on the roadmap!`,
  },
  {
    title: 'Who owns my creations?',
    content: `As the creator of the token you have digital ownership of your work. We offer two options when creating a token, Private and Limited Reproduction Rights:

        'Private' means the artist retains the full copyright to the art. The buyer may display the art as part of their collection or resell it on the secondary market.

        'Limited Reproduction Rights' means the artist is selling the full commercial rights to the reproducibility of the art. This means the buyer can use the art for commercial projects, which could include making prints, t-shirt designs, website/blog graphics, etc. This does not mean that the purchaser could sell their own secondary licenses for the piece, or could retokenize it themselves.

        Accepted file formats and specs

        Image: JPEG, PNG, and GIF files are supported. 

        Video: MP4 files are supported. Maximum size is 500 MB. You will also need a thumbnail in MP4 format which can include sound. We recommend using H264 codec as that will play reliably in most browsers!
        Audio: MP3 and WAV files are supported. Maximum size is 30 MB and you will need a 3 MB image for the thumbnail.`,
  },
  {
    title: 'Where are my creations stored?',
    content: `All images uploaded to NFT Showroom are stored on IPFS (InterPlanetary File System) and also backed up on an image server.`,
  },
  {
    title: 'Can I gift my creation?',
    content: `Yes, you can transfer an NFT to anyone with a HIVE account.`,
  },
  {
    title:
      'If I tokenize my creations on TUNZ, can I sell the same creations elsewhere?',
    content: `By creating an NFT with a specified number of editions you are ensuring the scarcity of your art for your buyers. Once art is tokenized, it is important you do not tokenize the same creation again as that will negatively affect the value of your artwork and reputation.

        We reserve the right to restrict access to TUNZ if an artist is caught plagiarizing or fraudulently issuing multiple tokens of the same creation.

        Unique and Multiple Editions

        In simple terms editions are like prints, or copies of the same art. When tokenizing art the rarity is a very important aspect. Collectors will take this into consideration when making a purchase. On NFT Showroom we offer the option to tokenize single or multiple editions. It is important that you decide the number of editions when you are minting the token because you can’t create more later or change the details after the transaction has been broadcast.`,
  },
  {
    title: 'How can I get whitelisted?',
    content: `During the alpha we are only allowing verified artists to tokenize. There are future plans to open the site up once we have a verified badge system in place. 

        To apply as an artist, log in to nfttunz.io click your name in the upper right menu > then click profile. Please fill out your profile as best as you can before requesting to be whitelisted! Profiles with no art portfolio or social media links will be denied, and remember to keep an eye on your messages at your submitted social sites. 

        If a sale goes through can it be reversed if I change my mind?

        No. You can contact the buyer/seller, but if they refuse there is no recourse.

        Fraudulent music from verified creators may be refunded up to the price the first owner paid. Otherwise all sales are final.`,
  },
  {
    title: 'What are HIVE and Hive Power?',
    content: `HIVE is a cryptocurrency that powers the Hive blockchain.

      On Hive you can own liquid HIVE or choose to stake (freeze it) as HIVE Power (HP). HP grants you voting rights on the platform as well as resource credits. Resource credits are consumed when you transact on the blockchain (e.g. tokenizing art NFTs or blogging on DAPPs like peakd.com) These credits regenerate. We recommend you have at least 5 HP staked to be able to smoothly transact. For more information about the Hive blockchain such as wallets, dapps, exchanges and more please visit <a href='https://hive.io/' rel='noopener' target='_blank'>https://hive.io/</a>`,
  },
  {
    title: 'What is Keychain?',
    content: `Hive Keychain is a browser extension that works with Chrome, Brave, and Mozilla to encrypt your keys within a browser. By using Keychain you can prevent your keys from ever being shared with a malicious website.`,
  },
  {
    title: 'Why is the sale price in SWAP.HIVE?',
    content: `The smart contract platfrom Hive-Engine, which is the backend of the NFT Showroom website, requires that all contracts be settled in SWAP.HIVE.`,
  },
  {
    title: 'Can I get a refund?',
    content: `Blockchain transactions are irreversible, so all sales are final.`,
  },
  {
    title: 'Contact and support',
    content: `For all support needs please contact us in discord.`,
  },
];


export const hiveQuestions = [

]