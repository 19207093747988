import React from 'react';
import { Link } from 'react-router-dom';
import { toFixedWithoutRounding } from '../../utils/helpers';

const EarningsDetailsCard = ({ earning, thumbnail, usdPrice }) => {
  return (
    <div className='earnings__details__card__wrapper'>
      <div className='earning__card__thumbnail__wrapper'>
        <img
          className='earning__card__thumbnail'
          src={earning?.thumbnail}
          alt={earning?.series}
        />
        {/* <small>{earning?.creator}</small> */}
      </div>
      <div className='earning__card__item collection__name me-2'>
        <strong >Buyer</strong>
        <Link to={`/@${earning?.buyer}`}>
          <span >@{earning?.buyer}</span>
        </Link>
      </div>
      <div className='earning__card__item'>
        <strong>edition </strong>
        <span>No. {earning?.edition}</span>
      </div>

      <div className='earning__card__item'>
        <strong>price</strong>
        <div>
          <span className='text-right'>{earning?.price} </span>
          <span className='text-right'>{earning?.symbol} </span>
        </div>
      </div>
      <div className='earning__card__item'>
        <strong>total spent</strong>
        <div>
          <span className='text-right'>
            {toFixedWithoutRounding(earning?.price * usdPrice)}{' '}
          </span>
          <span className='text-right'>USD </span>
        </div>
      </div>
    </div>
  );
};

export default EarningsDetailsCard;
