import React, { Fragment, useState } from 'react';
import Card from './Card';
import uuid from 'uuid';
import { connect } from 'react-redux';
import MediaPlayer from '../modals/MediaPlayer';
import CatalogueLoader from '../skeleton/CatalogueLoader';

const Cards = ({
  songs,
  settings: { categories },
  showPlayer,
  setShowPlayer,
  setThumbnail,
  left_editions,
  market, auction,
  artiste_profile,
  username,
  trx,
  col,
  nfts,
  home,
  loading,
}) => {
  const [playerFile, setPlayerFile] = useState('');
  const [playerThumbnail, setPlayerThumbnail] = useState('');
  const [playerType, setPlayerType] = useState('');
  const [playerTitle, setPlayerTitle] = useState('');
  const [playerSeries, setPlayerSeries] = useState('');
  const [artiste, setArtiste] = useState('');

  if (loading) {
    return [1, 2, 3, 4, 5, 6, 7, 8].map((l, i) => (
      <div
        key={i}
        className='col-lg-3 col-xl-3 col-md-3 col-sm-1 mt-4 target__skeleton'
      >
        <CatalogueLoader />
      </div>
    ));
  }

  return (
    <Fragment>
      {songs?.map((song) => {
        const {
          collection_name,
          name,
          thumbnail,
          file,
          creator,
          category,
          type,
          series,
          price,
          symbol,
          editions,
          nsfw,
          count,
        } = song;
        const id = uuid();
        const remaining = nfts?.filter((n) => n.properties.series === series);
        return (
          <div key={id} className='col-lg-3 col-xl-3 col-md-3 col-sm-1 mt-4'>
            <Card
              title={collection_name || name}
              thumbnail={thumbnail}
              file={file}
              type={type}
              artiste={creator}
              artiste_profile={artiste_profile.find(
                (artiste) => artiste.username === creator,
              )}
              genre={category}
              duration={type}
              series={series}
              showPlayer={showPlayer}
              setShowPlayer={setShowPlayer}
              setFile={setPlayerFile}
              setPlayerType={setPlayerType}
              setThumbnail={setPlayerThumbnail}
              setPlayerTitle={setPlayerTitle}
              setPlayerSeries={setPlayerSeries}
              setArtiste={setArtiste}
              price={price}
              symbol={symbol}
              market={market}
              auction={auction}
              editions={editions}
              nsfw={nsfw}
              left_editions={left_editions || count || remaining?.length}
            />
          </div>
        );
      })}
      <MediaPlayer
        handleShowPlayer={setShowPlayer}
        show={showPlayer}
        file={playerFile}
        setFile={setPlayerFile}
        thumbnail={playerThumbnail}
        type={playerType}
        title={playerTitle}
        creator={artiste}
        series={playerSeries}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
  artiste_profile: state.profiles.artistes,
  username: state.users.username,
  trx: state.transactions.transactions,
});
export default connect(mapStateToProps, {})(Cards);
