/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import AudioPlayer from 'react-h5-audio-player';
import VideoPlayer from 'react-video-markers';
import Slider from 'react-slick';
import {
  getCollectible,
  getCollectibles,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
} from '../actions/collectibles';
import Card from '../components/cards/Card';
import {
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  auctionToken,
  cancelAuction,
  settleAuction,
} from '../actions/token';
import {
  getNFTInstances,
  getNFTInstance,
  getNFTDefinition,
  getNFTSellBook,
  transferNFT,
  airdropTokens,
  airdropNFT,
  whaleDrop,
  getBalances,
  getAuctionInstances,
} from '../actions/nfts';
import { getCommunity } from '../actions/communities';
import {
  addSellCart,
  removeSellCart,
  clearSellCart,
  addPriceCart,
  removePriceCart,
  clearPriceCart,
} from '../actions/cart';
import ManageModal from '../components/modals/ManageModal';
import TransferModal from '../components/modals/TransferModal';
import SetPriceModal from '../components/modals/SetPriceModal';
import AuctionCreateModal from '../components/modals/AuctionCreateModal';
import Instances from '../components/market/Instances';
import SellBook from '../components/market/SellBook';
import Loader from '../components/Loader';
import AirdropModal from '../components/modals/AirdropModal';
import KcAirdropModal from '../components/modals/KcAirdropModal';
import { copyToClipboard } from '../utils/helpers';
import AuctionList from '../components/market/AuctionList';

let mounted = false;
const Collectible = ({
  match,
  getCollectible,
  getCollectibles,
  getNFTDefinition,
  getNFTInstances,
  getNFTInstance,
  getNFTSellBook,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  auctionToken,
  cancelAuction,
  settleAuction,
  addSellCart,
  removeSellCart,
  clearSellCart,
  clearPriceCart,
  collectibles: {
    collectible,
    collectibles,
    loading: globalCollectibleLoading,
  },
  username,
  sell_cart,
  price_cart,
  addPriceCart,
  removePriceCart,
  nfts: { instances, sellbook, auctions, balances, loading, instanceLoading },
  history,
  series_strings,
  transferNFT,
  airdropTokens,
  whaleDrop,
  airdropNFT,
  usd_price,
  getBalances,
  getAuctionInstances,
  communities: { community },
  getCommunity,
}) => {
  const [isShowPrice, setIsShowPrice] = useState(false);
  const [isShowManage, setIsShowManage] = useState(false);
  const [isShowAuction, setIsShowAuction] = useState(false);
  const [isShowTransfer, setIsShowTransfer] = useState(false);
  const [isShowAirdrop, setIsShowAirdrop] = useState(false);
  const [isShowKcAirdrop, setIsShowKcAirdrop] = useState(false);
  const [isShowWhaledrop, setIsShowWhaledrop] = useState(false);
  const [collectibleLoading, setCollectibleLoading] = useState(false);
  const [receipient, setReceipient] = useState('');
  const [memo, setMemo] = useState('');
  const [song, setSong] = useState('');
  const [price, setPrice] = useState('');
  const [finalPrice, setFinalPrice] = useState('');
  const [auctionExpiry, setAuctionExpiry] = useState('');
  const [minBid, setMinBid] = useState('');
  const [instanceToSell, setInstanceToSell] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [showPlayer, setShowPlayer] = useState(false);
  const [airdropLoading, setAirdropLoading] = useState(false);
  const [multiFiles] = useState(
    collectible?.file?.split(',').length > 2
      ? collectible?.file.split(',')
      : collectible?.file,
  );
  const [file, setFile] = useState(
    Array.isArray(collectible?.file) && collectible?.file.split(',').length > 2
      ? collectible?.file.split(',')[0]
      : collectible?.file,
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const swap_hive_balance = balances.find(
    (balance) => balance?.symbol === 'BEE',
  )?.balance;
  const handleProgress = (e) => {
    // console.log('Current time: ', e.target.currentTime);
  };

  const handleDuration = (duration) => {
    console.log('Duration: ', duration);
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVolume = (value) => {
    setVolume(value);
  };
  const handleSelectAll = (e, instances) => {
    if (!e.target.checked) {
      clearSellCart();
      return;
    }
    let sellArr = [];
    instances.forEach((ins) => {
      if (sell_cart.includes(ins.toString())) {
        sellArr.filter((i) => i !== ins);
      } else {
        sellArr.push(ins._id.toString());
      }
    });
    addSellCart([...sellArr]);
    return;
  };
  const handleNextClick = () => {
    if (
      multiFiles.length < multiFiles.indexOf(file, 0) + 1 ||
      multiFiles.indexOf(file) < 0
    ) {
      setFile(multiFiles[multiFiles.length - 1]);
      console.log('next', multiFiles.indexOf(file));
      return alert('No more files');
    }
    setFile(multiFiles[multiFiles.indexOf(file) + 1]);
    console.log('next clicked', multiFiles.indexOf(file));
  };
  const handlePrevtClick = () => {
    if (multiFiles.indexOf(file, 0) - 1 < 0) {
      setFile(multiFiles[0]);
      console.log(multiFiles.indexOf(file));
      return alert('No more files');
    }
    setFile(multiFiles[multiFiles.indexOf(file) - 1]);
    console.log('prev clicked', multiFiles.indexOf(file));
  };

  // Hanlde select all for multiple price change
  const handleSelectAllChangePrice = (e, sellbook) => {
    if (!e.target.checked) {
      clearPriceCart();
      return;
    }
    let priceArr = [];
    sellbook.forEach((ins) => {
      if (price_cart.includes(ins.nft_id.toString())) {
        priceArr.filter((i) => i !== ins);
      } else {
        priceArr.push(ins.nft_id.toString());
      }
    });
    addPriceCart([...priceArr]);
    return;
  };

  // Airdrop
  const handleAirdrop = async () => {
    try {
      setAirdropLoading(true);
      await airdropTokens(series, song, instances);
      setAirdropLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleKcAirdrop = async () => {
    try {
      setAirdropLoading(true);
      await airdropNFT(series, song);
      setAirdropLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleWhaledrop = async () => {
    try {
      setAirdropLoading(true);
      await whaleDrop(series, song, instances);
      setAirdropLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const {
    params: { series },
  } = match;
  const authorEnd = series.indexOf('_');
  const author = series.substring(0, authorEnd);
  useEffect(() => {
    mounted = true;
    getCollectible(series);
    getCollectibles();
    getNFTDefinition();
    getNFTInstances(
      {
        account: username,
        'properties.series': series,
      },
      series,
    );
    getBalances(username, 'BEE');
    getNFTSellBook({
      account: username,
      'grouping.series': series,
    });
    fetchInterests();
    fetchOnMarket({
      account: username,
    });
    getAuctionInstances(series, username);
    getSeriesString({ account: username });
    getCommunity(series);
    return () => {
      mounted = false;
      clearSellCart();
      clearPriceCart();
    };
  }, [
    series,
    username,
    getCollectible,
    getCollectibles,
    getNFTDefinition,
    getNFTInstances,
    getNFTInstance,
    getNFTSellBook,
    fetchInterests,
    fetchOnMarket,
    getSeriesString,
    author,
    clearSellCart,
    clearPriceCart,
    getBalances,
    getCommunity,
    getAuctionInstances,
  ]);

  return (
    <Fragment>
      <Layout>
        {globalCollectibleLoading ? (
          <Loader />
        ) : (
          <>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='collectible__title mt-3'>
                    <h2 className='text-center'>{collectible?.name}</h2>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <div
                    className={`file__player mt-4 ${
                      (collectible?.type === 'audio' && 'w-100') ||
                      (collectible?.type === 'fan' && 'w-100')
                    }`}
                  >
                    {collectible?.type === 'audio' ? (
                      <div style={{ width: '100%' }}>
                        <div className='audio__thumbnail'>
                          <div
                            className='audio__thumbnail__blurred__background'
                            style={{
                              backgroundImage: `url(${collectible?.thumbnail})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          ></div>
                          <div className='audio__thumbnail__blurred__overlay'></div>
                          <img
                            src={collectible?.thumbnail}
                            alt='thumbnail'
                            width='100%'
                            height='350px'
                            style={{ objectFit: 'cover' }}
                          />
                        </div>
                        <AudioPlayer
                          autoPlay
                          src={collectible?.file}
                          onPlay={(e) => console.log('onPlay')}
                          onClickNext={handleNextClick}
                          onClickPrevious={handlePrevtClick}
                          showSkipControls={
                            Array.isArray(multiFiles) && multiFiles.length > 1
                          } // Do something if current is last song
                          loop={true}
                          // other props here
                        />
                      </div>
                    ) : collectible?.type === 'fan' ? (
                      <div style={{ width: '100%' }}>
                        <div className='audio__thumbnail'>
                          <div
                            className='audio__thumbnail__blurred__background'
                            style={{
                              backgroundImage: `url(${collectible?.thumbnail})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          ></div>
                          <div className='audio__thumbnail__blurred__overlay'></div>
                          <img
                            src={collectible?.file}
                            alt='thumbnail'
                            width='100%'
                            height='450px'
                            style={{ objectFit: 'cover' }}
                          />
                        </div>
                      </div>
                    ) : (
                      mounted && (
                        <VideoPlayer
                          url={collectible?.file}
                          isPlaying={isPlaying}
                          volume={volume}
                          onPlay={handlePlay}
                          onPause={handlePause}
                          onVolume={handleVolume}
                          onProgress={handleProgress}
                          onDuration={handleDuration}
                        />
                      )
                    )}
                  </div>
                  <Fragment>
                    <div className='collectible__description mt-3'>
                      <p className='text-center'>
                        <strong>Description:</strong> {collectible?.description}
                      </p>
                      {collectible?.type === 'fan' && (
                        <p className='text-center'>
                          <strong>Notes:</strong> {collectible?.notes}
                        </p>
                      )}
                      <p className='text-center'>
                        <strong>Type:</strong> {collectible?.type}
                      </p>
                      <p className='text-center'>
                        <strong>Max Editions:</strong> {collectible?.editions}
                      </p>
                      <p className='text-center'>
                        <strong>Tags:</strong>{' '}
                        {collectible?.tags?.map((tag, index) => (
                          <span key={index}>#{tag} </span>
                        ))}
                      </p>
                      <p className='text-center'>
                        <strong>Featured:</strong>{' '}
                        {collectible?.featured === false ? 'false' : 'true'}
                      </p>
                      <p className='text-center'>
                        <strong>NSFW:</strong>{' '}
                        {collectible?.nsfw === false ? 'false' : 'true'}
                      </p>
                      <p className='text-center'>
                        <strong>Published:</strong>{' '}
                        {collectible?.published === false ? 'false' : 'true'}
                      </p>
                      <p className='text-center'>
                        <strong>Creator:</strong> {collectible?.creator}
                      </p>
                      <p className='text-center'>
                        <button
                          className='share__button'
                          onClick={() =>
                            copyToClipboard(
                              `https://market.nfttunz.io/market/${collectible.series}`,
                            )
                          }
                        >
                          Copy Share Link
                        </button>
                      </p>

                      <br />
                      {collectible?.type === 'fan' && username === 'tunzadmin' && (
                        <div className='text-center'>
                          <button
                            disabled={airdropLoading}
                            className=' my-1 text-center airdrop__button'
                            onClick={() => {
                              setIsShowKcAirdrop(true);
                            }}
                          >
                            <span>Airdrop Token</span>
                            <span
                              className={`spinner-border spinner-border-sm margin__left__three ${
                                airdropLoading ? 'd-inline-block' : 'd-none'
                              }`}
                              role='status'
                            >
                              <span className='sr-only'>Loading...</span>
                            </span>
                          </button>

                          <button
                            disabled={true || airdropLoading}
                            className='airdrop__button my-1 margin__left'
                            onClick={() => {
                              setIsShowWhaledrop(true);
                            }}
                          >
                            <span>Whaledrop</span>
                            <span
                              className={`spinner-border spinner-border-sm margin__left__three ${
                                airdropLoading ? 'd-inline-block' : 'd-none'
                              }`}
                              role='status'
                            >
                              <span className='sr-only'>Loading...</span>
                            </span>
                          </button>
                        </div>
                      )}
                      {collectible?.type === 'fan' && username !== 'tunzadmin' && (
                        <div className='text-center'>
                          <button
                            disabled={airdropLoading}
                            className='my-1 text-center airdrop__button'
                            onClick={() => {
                              setIsShowKcAirdrop(true);
                            }}
                          >
                            <span>Airdrop Token</span>
                            <span
                              className={`spinner-border spinner-border-sm margin__left__three ${
                                airdropLoading ? 'd-inline-block' : 'd-none'
                              }`}
                              role='status'
                            >
                              <span className='sr-only'>Loading...</span>
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </Fragment>

                  <Fragment>
                    <div className='d-flex justify-content-lg-between'>
                      <h4>Available for Sale</h4>
                      <button
                        onClick={() => {
                          setInstanceToSell(sell_cart.slice(0, 50));
                          setIsShowPrice(true);
                        }}
                        disabled={sell_cart.length < 2}
                      >
                        Sell Selected
                      </button>
                    </div>
                    <hr />
                    {instances.length > 0 ? (
                      <Instances
                        handleSelectAll={handleSelectAll}
                        instances={instances}
                        setInstanceToSell={setInstanceToSell}
                        setIsShowPrice={setIsShowPrice}
                        setIsShowTransfer={setIsShowTransfer}
                        setIsShowAuction={setIsShowAuction}
                        buyToken={buyToken}
                        auctionToken={auctionToken}
                        usd_price={usd_price}
                        sell_cart={sell_cart}
                        removeSellCart={removeSellCart}
                        addSellCart={addSellCart}
                        username={username}
                        transferNFT={transferNFT}
                        setLoading={setCollectibleLoading}
                        loading={instanceLoading}
                        getInstances={getNFTInstances}
                        series={series}
                      />
                    ) : (
                      <Fragment>
                        <h6 className='text-center mt-3'>
                          No NFTs avaialble here for listing...
                        </h6>
                      </Fragment>
                    )}
                  </Fragment>

                  <Fragment>
                    <div className='d-flex justify-content-lg-between'>
                      <h4>Already on Sale</h4>
                      <button
                        onClick={() => {
                          setInstanceToSell(price_cart.slice(0, 50));
                          setIsShowManage(true);
                        }}
                        disabled={price_cart.length < 2}
                      >
                        Change Price
                      </button>
                    </div>
                    <hr />
                    {sellbook.length > 0 ? (
                      <SellBook
                        sellbook={sellbook.filter(
                          (sb) =>
                            sb.account === username && sb.series === series,
                        )}
                        loading={loading}
                        setInstanceToSell={setInstanceToSell}
                        setIsShowPrice={setIsShowPrice}
                        setIsShowManage={setIsShowManage}
                        cancelSell={cancelSell}
                        buyToken={buyToken}
                        username={username}
                        series={series}
                        collectible={collectible}
                        isUser={true}
                        price_cart={price_cart}
                        usd_price={usd_price}
                        removePriceCart={removePriceCart}
                        addPriceCart={addPriceCart}
                        handleSelectAll={handleSelectAllChangePrice}
                        setMarketLoading={setCollectibleLoading}
                      />
                    ) : (
                      <Fragment>
                        <h6 className='text-center mt-3'>
                          No NFTs listed for sale yet...
                        </h6>
                      </Fragment>
                    )}
                  </Fragment>
                  <Fragment>
                    <div className='d-flex justify-content-lg-between'>
                      <h4>Already on Auction</h4>
                    </div>
                    <hr />
                    {sellbook.length > 0 ? (
                      <AuctionList
                        auctions={auctions}
                        loading={loading}
                        username={username}
                        series={series}
                        usd_price={usd_price}
                        collectible={collectible}
                        isUser={true}
                        marketLoading={collectibleLoading}
                        cancelAuction={cancelAuction}
                        setMarketLoading={setCollectibleLoading}
                        settleAuction={settleAuction}
                      />
                    ) : (
                      <Fragment>
                        <h6 className='text-center mt-3'>
                          No NFTs listed on auction by you...
                        </h6>
                      </Fragment>
                    )}
                  </Fragment>
                </div>
              </div>
              <hr />
              <div className='row'>
                <Slider {...settings}>
                  {collectibles.map((collec, index) => (
                    <Card
                      key={index}
                      title={collec?.collection_name}
                      thumbnail={collec?.thumbnail}
                      file={collec?.file}
                      artiste={collec?.creator}
                      genre={collec?.category}
                      duration={collec?.type}
                      series={collec?.series}
                      carouselClass='mx-1'
                      showPlayer={showPlayer}
                      setShowPlayer={setShowPlayer}
                    />
                  ))}
                </Slider>
              </div>
            </div>
            <ManageModal
              isShow={isShowManage}
              setIsShow={setIsShowManage}
              changePrice={changePrice}
              usd_price={usd_price}
              instance={instanceToSell}
              setLoading={setCollectibleLoading}
            />
            <SetPriceModal
              isShow={isShowPrice}
              setIsShow={setIsShowPrice}
              setPrice={setPrice}
              price={price}
              usd_price={usd_price}
              instance={instanceToSell}
              sellToken={sellToken}
              history={history}
              route={`/collectible/${collectible?.series}`}
              setLoading={setCollectibleLoading}
            />
            <TransferModal
              isShow={isShowTransfer}
              setIsShow={setIsShowTransfer}
              setReceipient={setReceipient}
              receipient={receipient}
              setMemo={setMemo}
              memo={memo}
              instance={instanceToSell}
              transferNFT={transferNFT}
              username={username}
              setLoading={setCollectibleLoading}
            />
            <AirdropModal
              song={song}
              setSong={setSong}
              handleAirdrop={handleAirdrop}
              isShow={isShowAirdrop}
              setIsShow={setIsShowAirdrop}
              memo={memo}
              setMemo={setMemo}
              series_strings={series_strings}
              // setLoading={airdropLoading}
            />
            <KcAirdropModal
              song={song}
              setSong={setSong}
              handleAirdrop={handleKcAirdrop}
              isShow={isShowKcAirdrop}
              setIsShow={setIsShowKcAirdrop}
              memo={memo}
              setMemo={setMemo}
              series_strings={series_strings}
              balance={swap_hive_balance}
              community={community}
              // setLoading={airdropLoading}
            />
            <KcAirdropModal
              song={song}
              setSong={setSong}
              handleAirdrop={handleWhaledrop}
              isShow={isShowWhaledrop}
              setIsShow={setIsShowWhaledrop}
              memo={memo}
              setMemo={setMemo}
              series_strings={series_strings}
              balance={swap_hive_balance}
              community={community}
              // setLoading={airdropLoading}
            />
            <AuctionCreateModal
              isShow={isShowAuction}
              setIsShow={setIsShowAuction}
              setFinalPrice={setFinalPrice}
              finalPrice={finalPrice}
              setMinBid={setMinBid}
              minBid={minBid}
              setAuctionExpiry={setAuctionExpiry}
              auctionExpiry={auctionExpiry}
              usd_price={usd_price}
              instance={instanceToSell}
              createAuction={auctionToken}
              history={history}
              route={`/collectible/${collectible?.series}`}
              setLoading={setCollectibleLoading}
            />
          </>
        )}
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
  communities: state.communities,
  nfts: state.nfts,
  username: state.users.username,
  sell_cart: state.cart.sell_cart,
  price_cart: state.cart.price_cart,
  series_strings: state.market.series,
  usd_price: state.market.usd_price,
  currency: state.settings.currency,
});
export default connect(mapStateToProps, {
  getCollectible,
  getCollectibles,
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  auctionToken,
  cancelAuction,
  settleAuction,
  getNFTDefinition,
  getNFTInstances,
  getNFTInstance,
  getNFTSellBook,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
  addSellCart,
  removeSellCart,
  clearSellCart,
  removePriceCart,
  addPriceCart,
  clearPriceCart,
  transferNFT,
  airdropTokens,
  whaleDrop,
  airdropNFT,
  getBalances,
  getCommunity,
  getAuctionInstances,
})(Collectible);
