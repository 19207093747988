import { GET_ADMIN_COLLECTIBLES } from "../actions/types";

const initialState = {
    collectibles: [],
    whitelist_applications: [],
    users: [],
    reports: [],
    loading: true
}


const adminReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_ADMIN_COLLECTIBLES:
            return {
                ...state,
                collectibles: payload,
                loading: false
            };
    
        default:
            return state;
    }
}

export default adminReducer;