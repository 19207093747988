import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getCollectible } from '../../actions/collectibles';
import { getEachEarningsTransactions } from '../../actions/market';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import EarningsDetailsItemsCard from '../../components/cards/EarningsDetailsItemsCard';

const EarningsDetails = ({
  match,
  collectible,
  getCollectible,
  getEachEarningsTransactions,
  earnings_history,
  usd_price,
}) => {
  const { series } = match.params;
  useEffect(() => {
    getCollectible(series);
    getEachEarningsTransactions({ series, types: 'buy,sell' });
  }, [series, getCollectible, getEachEarningsTransactions]);
  return (
    <Fragment>
      <Layout>
        <Header
          title={collectible?.name}
          backgroundImage={collectible?.thumbnail}
        />
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <h2>Transactions</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <EarningsDetailsItemsCard
                earnings={earnings_history}
                thumbnail={collectible?.thumbnail}
                usdPrice={usd_price}
              />
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectible: state.collectibles.collectible,
  earnings_history: state.market.earnings_transaction_history,
  usd_price: state.market.usd_price,
});
export default connect(mapStateToProps, {
  getCollectible,
  getEachEarningsTransactions,
})(EarningsDetails);
