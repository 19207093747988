import {
  AUTH_ERROR,
  GET_NOTIFICATIONS,
  LOGIN_SUCCESS,
  LOGOUT,
  READ_NOTIFICATIONS,
  SET_LOADING,
  SET_USER,
  GET_FOLLOWING,
  GET_FOLLOWERS,
} from '../actions/types';

const initialState = {
  username: '',
  authenticated: false,
  admin: false,
  notifications: [],
  following: [],
  followers: [],
  loading: true,
  token: localStorage.getItem('token'),
  smartLock: false,
};
// export const users = (state) => state;
export default function usersReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        loading: false,
      };
    case READ_NOTIFICATIONS:
      return {
        ...state,
        notifications:
          payload.length < 2
            ? state.notifications.filter(
                (notification) => notification.id !== payload[0],
              )
            : [],
        loading: false,
      };
    case GET_FOLLOWING:
      return {
        ...state,
        following: payload,
        loading: false,
      };
    case GET_FOLLOWERS:
      return {
        ...state,
        followers: payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_USER:
      return {
        ...state,
        ...payload,
        authenticated: true,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('username', payload.username);
      return {
        ...state,
        ...payload,
        authenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      return {
        ...state,
        token: null,
        authenticated: false,
        notifications: [],
        loading: false,
      };

    default:
      return state;
  }
}
