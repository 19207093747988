/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProfile } from '../actions/profile';
import { copyToClipboard, setToastNotification } from '../utils/helpers';
import axios from '../utils/axios';

const ProfileHeader = ({
  user: {
    username,
    full_name,
    avatar,
    cover_image,
    instagram,
    website,
    twitter,
    soundcloud,
    location,
    followers,
    following,
  },
  getProfile,
}) => {
  const [coverImageName, setCoverImageName] = useState('Choose an avatar');
  const [coverImage, setCoverImage] = useState('');
  const [avatarName, setAvatarName] = useState('Choose an avatar');
  const [avatarFile, setAvatarFile] = useState('');
  const onChangeCoverImageFile = async (e) => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
    setCoverImage(e.target.files[0]);
    setCoverImageName(e.target.files[0].name);
    let prepData = new FormData();
    prepData.append('coverImage', e.target.files[0]);
    await axios.post('/cover/upload', prepData, config);
    setToastNotification('Cover Image uploaded', 'success');
  };
  const onChangeAvatarFile = async (e) => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
    setAvatarFile(e.target.files[0]);
    setAvatarName(e.target.files[0].name);
    let prepData = new FormData();
    prepData.append('image', e.target.files[0]);
    await axios.post('/avatar/upload', prepData, config);
    setToastNotification('Avatar uploaded', 'success');
  };
  useEffect(() => {
    getProfile();
  }, [getProfile]);
  return (
    <Fragment>
      <section
        className='nfttunz__main__header'
        style={{
          marginBottom: '0px',
          backgroundImage: `url(${cover_image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className='nfttunz__profile__header__overlay'>
          <div className='cover__upload__button__wrapper'>
            <input
              placeholder='Enter your password again'
              className='steps__input hidden'
              name='coverImage'
              id='coverImage'
              type='file'
              onChange={onChangeCoverImageFile}
              style={{ display: 'none' }}
            />
            <label
              className='custom-file-label rounded_upload__button'
              htmlFor='coverImage'
            >
              <i className='fas fa-images'></i>
            </label>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='profile__header__title text-center'>
                  <div className='avatar__upload__button__wrapper'>
                    <input
                      placeholder='Enter your password again'
                      className='steps__input hidden'
                      name='image'
                      id='image'
                      type='file'
                      onChange={onChangeAvatarFile}
                      style={{ display: 'none' }}
                    />
                    <label
                      className='custom-file-label avatar__rounded_upload__button'
                      htmlFor='image'
                    >
                      <i className='fas fa-camera'></i>
                    </label>
                  </div>
                  <img
                    src={
                      avatar
                        ? avatar
                        : 'https://cdn.pixabay.com/photo/2016/05/24/22/54/icon-1413583_960_720.png'
                    }
                    alt='user avatar'
                    width={100}
                  />
                  <h1>{full_name ? full_name : 'TUNZ User'}</h1>
                  <small>
                    <a
                      href={`https://www.hive.blog/@${username}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      @{username}, {location}
                    </a>
                  </small>
                </div>
                <div className='profile__header__icons text-center'>
                  <a href={twitter} target='_blank' rel='noreferrer'>
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a href={instagram} target='_blank' rel='noreferrer'>
                    <i className='fab fa-instagram'></i>
                  </a>
                  <a href={soundcloud} target='_blank' rel='noreferrer'>
                    <i className='fab fa-soundcloud'></i>
                  </a>
                  <a href={website} target='_blank' rel='noreferrer'>
                    <i className='fas fa-globe'></i>
                  </a>
                </div>
                <div className='profile__followers__wrapper m-auto text-center'>
                  <span className='profile__header__followers text-center'>
                    {following?.length}
                    <strong>
                      <i className='fa fa-users' aria-hidden='true'></i>
                      Following
                    </strong>
                  </span>
                  <span className='profile__header__followers text-center'>
                    {followers.length}
                    <strong>
                      <i className='fa fa-users' aria-hidden='true'></i>
                      Followers
                    </strong>
                  </span>
                </div>

                <div className='profile__header__button text-center d-flex justify-content-center'>
                  <p className='text-center'>
                    <Link
                      className='btn btn-primary btn-sm text-center ml-2 mt-0 follow__button'
                      style={{ marginRight: '10px' }}
                      to='/profile/edit'
                    >
                      <svg
                        width={10}
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z'
                        />
                      </svg>{' '}
                      Edit Profile
                    </Link>
                  </p>
                  <p className='text-center mr-1'>
                    <button
                      className='btn btn-primary btn-sm text-center ml-2 mt-0 follow__button share__button'
                      onClick={() =>
                        copyToClipboard(
                          `https://market.nfttunz.io/artiste/${username}`,
                        )
                      }
                    >
                      <svg
                        width={10}
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-5 w-5'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path
                          fillRule='evenodd'
                          d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
                          clipRule='evenodd'
                        />
                      </svg>{' '}
                      copy profile link
                    </button>
                  </p>
                  <p className='text-center ms-2'>
                    <button
                      className='btn btn-primary btn-sm text-center ml-2 mt-0 follow__button share__button'
                      onClick={() =>
                        copyToClipboard(
                          `${window.location?.origin}/register/${username}`,
                        )
                      }
                    >
                      <svg
                        width={10}
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-5 w-5'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path d='M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z' />
                      </svg>{' '}
                      copy referral link
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  user: state.users,
});
export default connect(mapStateToProps, { getProfile })(ProfileHeader);
