import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    background-size: cover;
    background-position: center;
    color: ${({ theme }) => theme.text};
    transition: all .5s linear;
  }
  p, a {
    color: ${({ theme }) => theme.text};
    line-height: 1.4rem;
  }
  a > h4 {
    color: ${({ theme }) => theme.text}
  }
  input {
    color: ${({ theme }) => theme.alternative_text};
  }
  input:focus-visible {
    outline: none;
    background: transparent!important;
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  input#recipient-name.form-control {
    color: ${({ theme }) => theme.alternative_text};
  }
  .custom-input-series-div {
    border: 1px solid ${({ theme }) => theme.alternative_text};
  }
  .btn-primary {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.body};
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all .5s linear;
  }
  .music__card__wrapper {
    background: ${({ theme }) => theme.card_background}
  }
  .earning__card__wrapper, .earnings__details__card__wrapper {
    background: ${({ theme }) => theme.card_background}
  }
  .tag__wrapper {
    background: ${({ theme }) => theme.card_background}
  }
  .tag__wrapper > button > strong {
    color: ${({ theme }) => theme.text}
  }
  .play__button > a {
    color: ${({ theme }) => theme.text}
  }
  .music__card__artiste > a {
    color: ${({ theme }) => theme.text}
  }
  .nfttunz__navbar__links li a {
    color: ${({ theme }) => theme.text}
  }
  .nfttunz__navbar__links li a:hover {
    color: ${({ theme }) => theme.text}
  }
  .flex__mobile > i {
    color: white!important;
  }
  .nfttunz__notification__wrapper a i:hover, 
  .nfttunz__notification__wrapper button i:hover {
    color: ${({ theme }) => theme.nav_hover}!important
  }
  .nfttunz__avatar__wrapper {
    background: ${({ theme }) => theme.nav_circles};
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__notification__wrapper {
    background: ${({ theme }) => theme.nav_circles};
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__profile__nav > ul > li > a {
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__notification__wrapper > button {
    background: transparent;
  }
  .nfttunz__notification__wrapper > button > i {
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__notification__wrapper > a > i {
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__navbar__search__wrapper {
    background-color: ${({ theme }) => theme.search_input}
  }
  .nfttunz__login__button {
    background-color: ${({ theme }) => theme.search_input}
  }
  .nfttunz__login__button button {
    background-color: ${({ theme }) => theme.search_input};
    color: ${({ theme }) => theme.text}!important;
  }
  .nfttunz__filter__select, .nfttunz__select {
    background-color: ${({ theme }) => theme.filter_input};
    color: ${({ theme }) => theme.placeholder_text}!important;
  }
  .nfttunz__filter__select:focus, .nfttunz__select:focus {
    background-color: ${({ theme }) => theme.filter_input};
    color: ${({ theme }) => theme.text}!important;
  }
  .double__input__row input,
  .steps__input {
    background-color: ${({ theme }) => theme.steps_input};
    color: ${({ theme }) => theme.text}!important;
  }
  .arrow__wrapper {
    background-color: ${({ theme }) => theme.card_background};
  }
  .table {
    color: ${({ theme }) => theme.text}!important;
  }
  .nfttunz__profile__nav {
    background-color: ${({ theme }) => theme.nfttunz__profile__nav_background};
    z-index: 999;
  }
  .nfttunz__profile__link > a {
    color: ${({ theme }) => theme.text}!important;
  }
  .nfttunz__profile__link > a:hover {
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .nfttunz__profile__link > a:active {
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .preview__card {
    background-color: ${({ theme }) => theme.card_background};
  }
  .wallet__card__wrapper, .register__card__wrapper {
    background-color: ${({ theme }) => theme.card_background};
    backdrop-filter: blur(5px);
  }
  .mint__form__wrapper {
    background-color: ${({ theme }) => theme.card_background};
  }
  .nfttunz__file__wrapper {
    background-color: ${({ theme }) => theme.card_background};
  }
  .nfttunz__textarea {
    background-color: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text};
  }
  .rhap_container {
    background-color: ${({ theme }) => theme.card_background}
  }
  .rhap_time {
    color: ${({ theme }) => theme.placeholder_text}
  }
  .audio__thumbnail {
    border-top: ${({ theme }) => theme.player_border};
  }
  .footer__social__icons a  i {
    color: ${({ theme }) => theme.text}!important
  }
  .footer__social__links a {
    color: ${({ theme }) => theme.footer_text}
  }
  .footer__social__icons a:hover, .footer__social__icons a:hover i {
  color: ${({ theme }) => '#0a58ca'}!important;
  }
  .footer__wrapper p, .footer__wrapper small {
    color: ${({ theme }) => theme.text};
  }
  .nfttunz__loader__wrapper {
    background-color: ${({ theme }) => theme.card_background}!important;
  }
  .market__info {
    background-color: ${({ theme }) => theme.footer_background}!important;
    color: ${({ theme }) => theme.text}!important;
  }
  .market__info__link {
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .nfttunz__profile__link button {
    color: ${({ theme }) => theme.text}!important;
  }
  .nfttunz__profile__link .active__link {
    background-color: ${({ theme }) => theme.card_background}!important;
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .nfttunz__profile__link button:hover {
    color: ${({ theme }) => theme.alternative_text}!important;
    cursor: pointer;
  }
  .nfttunz__card {
    background-color: ${({ theme }) => theme.card_background}!important;
  }
  .modal-header-bg {
    background-color: ${({ theme }) => theme.card_background}!important
    padding: 5px;
  }
  .modal-header .player-modal {
    background: transparent!important;
    color: ${({ theme }) => theme.text}!important;
    font-size: 8px;
  }
  .modal-header {
    padding: 2px 8px!important;
  }
  .custom-bg {
    background-color: ${({ theme }) => theme.footer_background}!important;
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .custom-bg .modal-header {
    background-color: ${({ theme }) =>
      theme.custom_modal_header_background}!important;
  }
  .modal-header-bg img {
    width: 200px;
  }
  .custom-bg input {
    background-color: ${({ theme }) => theme.card_background}!important;
  }
  .custom-bg input[placeholder] {
    color: ${({ theme }) => theme.text}!important;
  }
  
  .music__card__editions__wrapper {
    background: ${({ theme }) => theme.new_background}!important;
    border-top: 2px solid ${({ theme }) => theme.footer_background};
    border-right: 1px solid ${({ theme }) => theme.footer_background};
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  }
  .notification__overflow {
    background: ${({ theme }) => theme.notification_background};
    color: ${({ theme }) => theme.text}!important;
  }
  button.mark-as-read-button {
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .side-menu, .admin-dashboard-card {
    background: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text}!important;
  }
  .admin__report__card {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text}!important;
  }
  .side-menu-links > li {
    border-bottom: 1px solid ${({ theme }) => theme.text}!important;
  }
  .bg__white {
    background: ${({ theme }) => theme.body};
  }
  .custom__modal__background {
    background: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text}!important;
  }
  .custom__modal__background input {
    background: ${({ theme }) => theme.search_input};
  }
  input:focus-visible {
  background-color: lightgrey;
  color: ${({ theme }) => theme.search_input}
  }
  button:focus {
    box-shadow: none!important;
  }
  .register__fee {
    color: ${({ theme }) => theme.register_info_text}
  }
  .register__support__email {
    color: ${({ theme }) => theme.register_info_text}
  }
  .register__form input, .password__container {
    background-color: ${({ theme }) => theme.search_input};
  }
  .nfttunz__modal__dialog {
    // background-color: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.register_info_text}
  }
  .nfttunz__modal__content {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.register_info_text};
  }
  .music__card__artiste a {
    color: ${({ theme }) => theme.wheat_text}!important;
  }
  .music__card__extras h6 i, .music__card__extras h6 {
    color: ${({ theme }) => theme.text}!important;
  }
  .market__sellbook__height table {
    border: 1px solid ${({ theme }) => theme.alternative_border}!important;
  }
  .market__card {
    background-color: ${({ theme }) => theme.body}!important;
    border-top: 2px solid ${({ theme }) => theme.footer_background};
    border-right: 1px solid ${({ theme }) => theme.footer_background};
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  }
  .market__creator__info {
    box-shadow: ${({ theme }) => theme.alternate_shadow}!important;
    border: 1px solid ${({ theme }) => theme.alternative_border}!important;
  }
  .creator__dialog {
    background: ${({ theme }) => theme.creator_dialog}!important;
    box-shadow: ${({ theme }) => theme.alternate_shadow}!important;
    border: 1px solid ${({ theme }) => theme.alternative_border}!important;
  }
  .nfttunz__countup__wrapper {
    background-color: ${({ theme }) => theme.card_background}!important;
    border: 1px solid ${({ theme }) => theme.alternative_border}!important;
    color: ${({ theme }) => theme.text}!important;
    box-shadow: ${({ theme }) => theme.alternate_shadow}!important;
  }
  .nfttunz__countup__wrapper h4, .nfttunz__countup__wrapper span {
    color: ${({ theme }) => theme.text}!important;
  }
  .playing__info, .sellers__card {
    background-color: ${({ theme }) => theme.new_background}!important;
    box-shadow: ${({ theme }) => theme.new_shadow}!important;
  }
  .playing__info a > p {
    color: ${({ theme }) => theme.text}!important;
  }
  .playing__info__player__name {
    color: ${({ theme }) => theme.text}!important;
  }
  .sellers__card {
    color: ${({ theme }) => theme.alternative_text}!important;
  }
  .hide__button__wrapper button {
    color: ${({ theme }) => theme.text}!important;
    background-color: ${({ theme }) => theme.new_background}!important;
  }
  .hide__button__wrapper button i {
    color: ${({ theme }) => theme.text}!important;
  }
  .profile__header__card {
    background-color: ${({ theme }) => theme.profile_card_background}!important;
  }
  .profile__header__card__img__wrapper {
    background-color: ${({ theme }) => theme.profile_card_background}!important;
  }
  .profile__header__info__wrapper {
    color: ${({ theme }) => theme.text}!important;
  }
  .profile__header__info__wrapper p,
  .profile__header__info__wrapper h1 {
    color: ${({ theme }) => theme.card_header_text}!important;
  }
  .profile__header__info__wrapper small a {
    color: ${({ theme }) => theme.primary}!important;
    font-weight: bold;
  }
  .profile__header__info__wrapper small {
    color: ${({ theme }) => theme.card_header_text}!important;
    font-weight: bold;
  }
  .nav__register__button span {
    color: ${({ theme }) => theme.primary}!important;
  }
  .modal-parent > div > div > div:nth-child(1) {
    background-image: ${({ theme }) => theme.modal_background}!important;
  }
  .modal__input {
    border-bottom: ${({ theme }) => theme.border_bottom}!important;
  }
  .modal__body > div select, .input__border__bottom {
    border-bottom: ${({ theme }) => theme.border_bottom}!important;
  }
  .modal__text__button {
    color: ${({ theme }) => theme.text}!important;
  }
  .cart__item__card, .cart__item__card__left {
    background-color: ${({ theme }) => theme.card_background};
    backdrop-filter: blur(5px);
  }
  .empty__cart__info__wrapper h4 {
    color: ${({ theme }) => theme.text}!important;
  }
  .user__profile__dropdown {
    background: ${({ theme }) => theme.modal_background}!important;
    color: ${({ theme }) => theme.text}!important;
  }
  .drop__down__item {
    color: ${({ theme }) => theme.text}!important;
  }
  .drop__down__item:hover {
    background: ${({ theme }) => theme.dropdown_background}!important;
    color: ${({ theme }) => theme.text}!important;
  }
`;

export const lightTheme = {
  body: '#fff',
  text: '#121212',
  card_header_text: '#fff',
  wheat_text: '#121212',
  placeholder_text: '#121212',
  primary: '#6200ee',
  card_background: 'transparent',
  thick_border: '8px solid #eee',
  player_border: '4px solid transparent',
  nav_circles: '#eeeeee',
  search_input: '#eeeeee',
  steps_input: '#eeeeee',
  filter_input: '#eeeeee',
  footer_text: '#121212',
  footer_background: '#eeecec',
  alternative_text: '#CC5914',
  alternative_border: 'transparent',
  register_info_text: '#121212',
  alternate_shadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
  new_shadow: 'box-shadow: 0 0.375rem 0.9375rem -4px rgb(0 0 0 / 20%)',
  new_background: '#ffffff',
  profile_card_background: 'rgba(36, 1, 1, 0.731)',
  custom_modal_header_background: '#702d58',
  nfttunz__profile__nav_background: 'rgba(236, 236, 236)',
  notification_background: 'linear-gradient(to right top,#fff,#eee)',
  modal_background: 'linear-gradient(to right top,#fff,#eee)',
  border_bottom: '1px solid #1d1d1d;',
  dropdown_background: '#fff',
  nav_hover: '#8B08A3',
  creator_dialog:
    'linear-gradient(to left bottom, #040721, #060d23, #081126, #091529, #0b182b)',
};

export const darkTheme = {
  body: 'url(/HERO_BG.png)',
  text: '#fff',
  card_header_text: '#fff',
  wheat_text: 'wheat',
  placeholder_text: '#3f3f3f',
  primary: '#bb86fc',
  card_background: '#2d2d2e29',
  thick_border: '8px solid #121212',
  player_border: '4px solid transparent',
  nav_circles: '#121212',
  search_input: '#121212',
  steps_input: '#12121214',
  filter_input: '#17041b94',
  footer_text: '#1D2127',
  footer_background: '#121212',
  alternative_text: 'wheat',
  alternative_border: '#fff',
  register_info_text: 'rgba(255, 255, 0, 0.459);',
  alternate_shadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
  new_shadow: 'box-shadow: 0 0.375rem 0.9375rem -4px rgb(255 255 255 / 20%)',
  new_background: 'linear-gradient(to right, #120215, #26022c, #120215)',
  profile_card_background: 'rgba(36, 1, 1, 0.731)',
  custom_modal_header_background: '#7bff0010',
  nfttunz__profile__nav_background: 'rgb(32, 31, 31)',
  notification_background: 'linear-gradient(to right top,#000101,#031211)',
  modal_background: 'linear-gradient(to right top,#000101, #031211)',
  border_bottom: '1px solid #ccc;',
  dropdown_background: '#1d1d1d',
  nav_hover: '#8B08A3',
  creator_dialog:
    'linear-gradient(to left bottom, #040721, #060d23, #081126, #091529, #0b182b)',
};
