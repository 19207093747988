import {
  GET_USER_TRANSACTIONS,
  GET_MARKET_TRANSACTIONS,
} from '../actions/types';

const initialState = {
  transactions: [],
  market_transactions: [],
  loading: true,
};

const transactionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
        loading: false,
      };
    case GET_MARKET_TRANSACTIONS:
      return {
        ...state,
        market_transactions: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default transactionsReducer;
