import { VALIDATE_PURCHASE, START_PURCHASE } from '../actions/types';

const initialState = {
  purchase_data: null,
  payment_validation: null,
  loading: true,
};

export const paymentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case VALIDATE_PURCHASE:
      return {
        ...state,
        payment_validation: payload,
        loading: false,
      };
    case START_PURCHASE:
      return {
        ...state,
        purchase_data: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
