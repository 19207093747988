import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import { toFixedWithoutRounding } from '../../utils/helpers';

const AirdropModal = ({
  isShow,
  series_strings,
  setIsShow,
  song,
  setSong,
  memo,
  setMemo,
  handleAirdrop,
  balance,
  community,
}) => {
  const [disableSeriesField, setdisableSeriesField] = useState(false);

  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='470'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Airdrop</h3>
          </div>
          <div className='modal__body'>
            <div>
              <p className='my-2 mb-3'>
                Enter or select the series you would like to airdrop to your
                fans (community). The list is automatically generated from the
                market data of users who have bought this fan token.
              </p>
            </div>

            <div>
              <select
                name='series'
                id='series'
                onChange={(e) => {
                  setdisableSeriesField(true);
                  setSong(e.target.value);
                }}
                className='w-100 mb-2'
              >
                <option value=''>Select Series</option>
                {series_strings?.map((series, i) => {
                  return (
                    <option key={i} value={series}>
                      {series}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='d-flex input__border__bottom'>
              <input
                disabled={disableSeriesField}
                value={song}
                onChange={(e) => setSong(e.target.value)}
                className='w-100 border-0'
                type='text'
                placeholder='Enter a song series...'
              />
              <button
                title='Enter series manually'
                onClick={() => setdisableSeriesField(false)}
                className=' modal__manual__button'
              >
                manual
              </button>
            </div>
            <div className='input__border__bottom'>
              <input
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                className='w-100 mt-2 border-0'
                type='text'
                placeholder='Enter memo (optional)...'
              />
            </div>
            <p>
              {toFixedWithoutRounding(balance) >
              0.01 * community?.members?.length ? (
                <small>
                  Your balance is {toFixedWithoutRounding(balance)} BEE
                </small>
              ) : (
                <small className='text-danger'>
                  Your balance is {toFixedWithoutRounding(balance)} BEE is not
                  enough
                </small>
              )}
              <br />
              <small className='text-info small__info'>
                <i className='fa fa-info-circle'></i> There is a 0.01 BEE fee per
                NFT sent attached to this which will cost you{' '}
                {toFixedWithoutRounding(0.01 * community?.members?.length)} BEE.
              </small>
            </p>
          </div>
          <div className='modal__footer pt-0'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
                setSong('');
                setMemo('');
              }}
            >
              Close
            </button>
            <button
              disabled={
                (toFixedWithoutRounding(balance) <
                0.01 * community?.members?.length) || community?.members?.length < 2
              }
              type='button'
              className='modal__button'
              onClick={() => {
                handleAirdrop();
                setSong('');
                setMemo('');
                setIsShow(false);
              }}
            >
              Airdrop NFT {community?.members?.length} Fans
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AirdropModal;
