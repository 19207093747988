import React from 'react';
import Modal from 'react-awesome-modal';
import { toFixedWithoutRounding } from '../../utils/helpers';

const SetPriceModal = ({
  isShow,
  setIsShow,
  setPrice,
  price,
  instance,
  sellToken,
  setLoading,
  usd_price,
}) => {
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='270'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Set NFT Price</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mt-1'>
              Enter the price you are intending to sell this edition for, you
              can always modify the price at a future time.
            </p>
            <div>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className='w-100 modal__input'
                type='text'
                placeholder='Enter a new price if you wish to change your price'
              />
              <p className='modal__currency__estimate'>
                {price} SWAP.HIVE <span className='text-danger'>=</span> $
                {toFixedWithoutRounding(price * usd_price)}
              </p>
            </div>
          </div>
          <div className='modal__footer'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
                setPrice('');
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                sellToken(price, instance);
                setPrice('');
                setIsShow(false);
                setLoading(true);
              }}
            >
              Set Market Price
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SetPriceModal;
