import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import EarningsDetailsCard from './EarningsDetailsItemCard';

const EarningsDetailsItemsCard = ({ earnings, thumbnail, usdPrice }) => {
  const [page, setPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [localEarnings, setLocalEarnings] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % earnings.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalEarnings(earnings?.slice(offset, endOffset));
    setPage(Math.ceil(earnings?.length / itemPerPage));

  }, [earnings, offset]);

  return (
    <>
      {localEarnings?.map((earning, index) => (
        <EarningsDetailsCard
          earning={earning}
          thumbnail={thumbnail}
          usdPrice={usdPrice}
          key={index}
        />
      ))}
      <div className='pagination__action__buttons'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<button>Next</button>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={page}
          activeClassName='active'
          previousLabel={<button>Prev</button>}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default EarningsDetailsItemsCard;
