import { MINT_TOKEN, WITHDRAW_HIVE } from "../actions/types";

const initialState = {
  loading: null
};

const tokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MINT_TOKEN:
      return {
        ...state,
        ...payload,
      };
      case WITHDRAW_HIVE:
        return {
          ...state,
          loading: payload
        }
    default:
      return state
  }
};

export default tokenReducer;
