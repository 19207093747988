import { combineReducers } from 'redux';
import users from './users';
import settings from './settings';
import token from './token';
import collectibles from './collectibles';
import profiles from './profile';
import nfts from './nfts';
import market from './market';
import admin from './admin';
import communities from './communities';
import cart from './cart';
import artiste from './artiste';
import payments from './payments';
import account from './account';
import statistics from './statistics';
import transactions from './transactions';
import credits from './credits';

export default combineReducers({
  users,
  token,
  settings,
  collectibles,
  profiles,
  nfts,
  market,
  admin,
  communities,
  cart,
  artiste,
  payments,
  account,
  statistics,
  transactions,
  credits,
});
