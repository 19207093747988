import React, { Fragment } from 'react';
import SellersCard from './SellersCard';


const SellersCards = ({artistes}) => {
  return (
    <Fragment>
      {artistes.map((artiste, index) => (
        <div className='seller__card' key={index}>
          <SellersCard artiste={artiste} />
        </div>
      ))}
    </Fragment>
  );
};

export default SellersCards;
