import React, { Fragment } from 'react';
import NotificationItem from './NotificationItem';

const Nofitications = ({ auth, readNotifications }) => {
  return (
    <Fragment>
      <div className='notification__overflow'>
        {auth?.notifications?.length > 1 && (
          <div className='top__mark__as__read__button__wrapper'>
            <button
              className='bg-transparent mark-as-read-button'
              style={{ padding: '0px 10px' }}
              onClick={() =>
                readNotifications(
                  auth.notifications.map((notification) => notification.id),
                )
              }
            >
              mark as all read
            </button>
          </div>
        )}
        {auth?.notifications?.length < 1 ? (
          <p className='p-2 border-bottom notification-paragraph'>
            You currently have no notifications at the moment
          </p>
        ) : (
          auth.notifications.map((notification) => {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification}
                readNotifications={readNotifications}
                type={notification.type}
              />
            );
          })
        )}
      </div>
    </Fragment>
  );
};

export default Nofitications;
