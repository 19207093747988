import {GET_ADMIN_COLLECTIBLES} from './types'
import axios from '../utils/axios'
import { setToastNotification } from '../utils/helpers';

export const getAdminCollectibles = (query) => async dispatch => {
    try {
        const data = axios.get('/admin/collectibles');
        dispatch({
            type: GET_ADMIN_COLLECTIBLES,
            payload: data.data
        })
    } catch (err) {
        console.error(err.message)
    }
}
export const manualIssue = (username, jsonData) => async dispatch => {
    try {
        window.hive_keychain.requestCustomJson(
          username,
          jsonData.id,
          jsonData.key,
          JSON.stringify(jsonData.data),
          jsonData.message,
          (r) => {
            if (r.success) {
              console.log(r);
              setToastNotification(r.message, 'success');
            //   setTimeout(() => {
            //     history.push('/admin');
            //   }, 11000);
            }
          }
        );
    } catch (err) {
        console.log(err.message)
    }
}
export const banUser = (username, jsonData) => async dispatch => {
    try {
        window.hive_keychain.requestCustomJson(
          username,
          jsonData.id,
          jsonData.key,
          JSON.stringify(jsonData.data),
          jsonData.message,
          (r) => {
            if (r.success) {
              console.log(r);
              setToastNotification(r.message, 'success');
                setTimeout(() => {
                window.location.reload();
                // history.push('/admin');
              }, 10000);
            }
          }
        );
    } catch (err) {
        console.log(err.message)
    }
}
export const reportCollectible = (username, jsonData) => async (dispatch) => {
  console.log(jsonData);
  try {
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      (r) => {
        if (r.success) {
          console.log(r);
          setToastNotification(r.message, 'success');
          setTimeout(() => {
            window.location.reload();
            // history.push('/admin');
          }, 20000);
        }
      }
    );
  } catch (err) {
    console.log(err.message);
  }
};
export const processReportCollectible = (username, jsonData) => async (dispatch) => {
  try {
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      (r) => {
        if (r.success) {
          console.log(r);
          setToastNotification(r.message, 'success');
          setTimeout(() => {
            window.location.reload();
            // history.push('/admin');
          }, 10000);
        }
      }
    );
  } catch (err) {
    console.log(err.message);
  }
};