import React from 'react';
import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAdminCollectibles } from '../../actions/admin';
import Layout from '../../components/Layout';
import Applications from './admin-routes/Applications';
import ArtisteApplications from './admin-routes/ArtisteApplications';
import Users from './admin-routes/Users';
import AdminHome from '.';
import { Switch, Route, Link } from 'react-router-dom';
import ManualIssue from './admin-routes/ManualIssue';
import Transactions from './admin-routes/Transactions';
import Collectibles from './admin-routes/Collectibles';
import Reports from './admin-routes/Reports';

const Admin = ({
  getAdminCollectibles,
  admin: { collectibles, loading },
  match,
}) => {
  const { path } = match;
  useEffect(() => {
    getAdminCollectibles();
  }, [getAdminCollectibles]);
  return (
    <Layout>
      <Fragment>
        <div className='admin-container'>
          <div className='side-menu'>
            <ul className='side-menu-links p-0'>
              <li>
                <Link to='/admin'>
                  <i className='glyphicon glyphicon-th-list'></i> Dashboard
                </Link>
              </li>
              <li>
                <Link to='/admin/users'>
                  <i className='glyphicon glyphicon-gift'></i> Users
                </Link>
              </li>
              <li>
                <Link to='/admin/collectibles'>
                  <i className='glyphicon glyphicon-gift'></i> Collectibles
                </Link>
              </li>
              <li>
                <Link to='/admin/applications'>
                  <i className='glyphicon glyphicon-eye-open'></i> Applications
                </Link>
              </li>
              <li>
                <Link to='/admin/artiste-applications'>
                  <i className='glyphicon glyphicon-eye-open'></i> Artiste Applications
                </Link>
              </li>
              <li>
                <Link to='/admin/reports'>
                  <i className='glyphicon glyphicon-folder-open'></i>{' '}
                  Reports
                </Link>
              </li>
              <li>
                <Link to='/admin/transactions'>
                  <i className='glyphicon glyphicon-folder-open'></i>{' '}
                  Transactions
                </Link>
              </li>
              <li>
                <Link to='/admin/manual-issue'>
                  <i className='glyphicon glyphicon-folder-open'></i>{' '}
                  Manual Issue
                </Link>
              </li>
            </ul>
          </div>
          <div className='admin-right-container'>
            {/* <div className='admin-nav-header'>
              <div className='admin-nav'>
                <div className='admin-search-wrapper'>
                  <input
                    className='admin-search'
                    type='text'
                    name='admin-search'
                    id='admin-search'
                    placeholder='Search by player name, trx id or email ...'
                  />
                  <button className='admin-search-icon'>
                    <i className='glyphicon glyphicon-search'></i>
                  </button>
                </div>
                <div className='user'>
                  <a href='#!' className='admin-btn'>
                    Add New
                  </a>
                </div>
              </div>
            </div> */}
            <div className='admin-dashboard-card'>
              <Switch>
                <Route exact path={`${path}/`} component={AdminHome} />
                <Route
                  exact
                  path={`${path}/applications`}
                  component={Applications}
                />
                <Route
                  exact
                  path={`${path}/artiste-applications`}
                  component={ArtisteApplications}
                />
                <Route
                  exact
                  path={`${path}/users`}
                  component={Users}
                />
                <Route
                  exact
                  path={`${path}/reports`}
                  component={Reports}
                />
                <Route
                  exact
                  path={`${path}/transactions`}
                  component={Transactions}
                />
                <Route
                  exact
                  path={`${path}/collectibles`}
                  component={Collectibles}
                />
                <Route
                  exact
                  path={`${path}/manual-issue`}
                  component={ManualIssue}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Fragment>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default connect(mapStateToProps, { getAdminCollectibles })(Admin);
