import { Fragment, useRef } from 'react';
import { copyToClipboard } from '../utils/helpers';

const AdminCollectibleTable = ({
  data,
  handleReportCollectible,
  handleFeatureCollectible,
  handleProcessReportCollectible,
  handleManageCollectible,
  reports,
}) => {
  const text = useRef(null);
  return (
    <Fragment>
      <table className='table nfttunz__table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Date</th>
            <th scope='col'>Name</th>
            <th scope='col'>Creator</th>
            <th scope='col'>Series</th>
            <th className='text-center'>Type</th>
            <th scope='col'>Published</th>
            <th scope='col'>Featured</th>
            <th scope='col' style={{ textAlign: 'right' }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={index}>
              <th scope='row'>{index + 1}</th>
              <th scope='row'>{d.timestamp}</th>
              <td>{d.name}</td>
              <td>{d.creator}</td>
              <td ref={text}>
                {`${d.series.slice(0, 3)}...${d.series.slice(-4)}`}{' '}
                <i
                  className='fas fa-copy text-warning'
                  onClick={() => copyToClipboard(d.series)}
                ></i>
              </td>
              <td>{d.type}</td>
              <td>{d.published === true ? 'True' : 'False'}</td>
              <td>{d.featured === true ? 'True' : 'False'}</td>
              <td className='text-right' style={{ textAlign: 'right' }}>
                {reports.includes(d.series) ? (
                  <button
                    className='btn btn-warning py-1'
                    style={{ fontSize: '8px', marginRight: '4px' }}
                    onClick={() =>
                      handleProcessReportCollectible({
                        series: d.series,
                        report_id: window.prompt('Enter report_id'),
                      })
                    }
                  >
                    process report
                  </button>
                ) : (
                  <button
                    className='btn btn-danger py-1'
                    style={{ fontSize: '8px', marginRight: '4px' }}
                    onClick={() =>
                      // Reasons: plagiarism, nsfw, copyright;
                      // TODO: Implement modal to select reason
                      handleReportCollectible({
                        series: d.series,
                        reason: window.prompt(
                          'Enter reason: (either: plagiarism, nsfw, copyright',
                        ),
                        message: 'Your collectible has been reported',
                      })
                    }
                  >
                    report nft
                  </button>
                )}
                {!d.featured ? (
                  <button
                    className='btn btn-success py-1'
                    style={{ fontSize: '8px', marginRight: '4px' }}
                    onClick={() =>
                      // Reasons: plagiarism, nsfw, copyright;
                      // TODO: Implement modal to select reason
                      handleFeatureCollectible({
                        featured: true,
                        series: d.series,
                      })
                    }
                  >
                    feature
                  </button>
                ) : (
                  <button
                    className='btn btn-info py-1'
                    style={{ fontSize: '8px', marginRight: '4px' }}
                    onClick={() =>
                      // Reasons: plagiarism, nsfw, copyright;
                      // TODO: Implement modal to select reason
                      handleFeatureCollectible({
                        featured: false,
                        series: d.series,
                      })
                    }
                  >
                    unfeature
                  </button>
                )}
                {d.published && (
                  <button
                    className='btn btn-warning py-1'
                    style={{ fontSize: '8px' }}
                    onClick={() =>
                      // Reasons: plagiarism, nsfw, copyright;
                      // TODO: Implement modal to select reason
                      handleManageCollectible({
                        published: false,
                        series: d.series,
                      })
                    }
                  >
                    unpublish
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default AdminCollectibleTable;
