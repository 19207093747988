import React, { Fragment, useEffect } from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import { connect } from 'react-redux';
import { getCommunities } from '../actions/communities';
import CommunityCard from '../components/cards/CommunityCard';
import MetaDecorator from '../components/utils/MetaDecorator';

const Communities = ({ getCommunities, communities, loading, username }) => {
  useEffect(() => {
    getCommunities();
  }, [getCommunities]);
  return (
    <Fragment>
      <MetaDecorator
        title='Communities'
        description='Explore and join fan communities'
        imageUrl='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
        imageAlt='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
      />
      <Layout>
        <Header title='Communities' button={false} />
        <section className='section__wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section__title text-center'>
                  <h2>Categories</h2>
                  <p>
                    An up to date community feed showing proof of music! See all
                    of the recent initial and secondary market purchases
                    happening around the NFTTunz ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              {communities?.length > 0 ? (
                <div className='col-md-8 offset-md-2'>
                  <div className='activity__main__wrapper'>
                    {communities.map((community, index) => {
                      return (
                        <CommunityCard
                          key={community.community_name}
                          community={community}
                          loading={loading}
                          getCommunities={getCommunities}
                          username={username}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <h2 className='text-center'>
                  No community available at the moment
                </h2>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  communities: state.communities.communities,
  loading: state.communities.loading,
  username: state.users.username,
});

export default connect(mapStateToProps, { getCommunities })(Communities);
