import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getArtisteMarket } from '../../actions/artiste';
import UploadLoader from '../../components/UploadLoader';
import Cards from '../../components/cards/Cards';
import MediaPlayer from '../../components/modals/MediaPlayer';

const OnSale = ({
  getArtisteMarket,
  username,
  market: { artiste_market, loading, artiste_market_length },
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [showPlayer, setShowPlayer] = useState(false);
  const [file, setFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVolume = (value) => {
    setVolume(value);
  };
  useEffect(() => {
    getArtisteMarket(username);
  }, [getArtisteMarket, username]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header mt-4'>
              <h6>Here the collections listed for sale by @{username}</h6>
            </div>
          </div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          <div className='arrow__wrapper left__'>
            <i className='fa fa-chevron-left'></i>
          </div>
          <div className='row'>
            {artiste_market === null ? (
              <UploadLoader size='small' />
            ) : artiste_market_length > 0 ? (
              <Cards
                songs={artiste_market}
                showPlayer={showPlayer}
                handleShowPlayer={setShowPlayer}
                setFile={setFile}
                setThumbnail={setThumbnail}
                market={true}
                col={true}
              />
            ) : (
              <div className='col-md-6 offset-3'>
                <div className='card mt-4'>
                  <div className='card-body'>
                    <h2 className='text-center'>Nothing here yet...</h2>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='arrow__wrapper right__'>
            <i className='fa fa-chevron-right'></i>
          </div>
        </div>
      </div>
      <MediaPlayer
        show={showPlayer}
        handleClose={setShowPlayer}
        handlePause={handlePause}
        handlePlay={handlePlay}
        handleVolume={handleVolume}
        volume={volume}
        isPlaying={isPlaying}
        file={file}
        thumbnail={thumbnail}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  market: state.artiste,
});
export default connect(mapStateToProps, { getArtisteMarket })(OnSale);
