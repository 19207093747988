import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import {
  processReportCollectible,
} from '../../../actions/admin';
import axios from '../../../utils/axios';
import AdminReportsTable from '../../../components/AdminReportsTable';

export const Reports = ({
  settings: { prefix },
  processReportCollectible,
  username,
}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [reports, setReports] = useState([]);
 
  const getAdminReports = async () => {
    setLocalLoading(true);
    const data = await axios.get('/admin/reports');
    setReports(data.data);
    setLocalLoading(false);
  };

  const handleProcessReportCollectible = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_process_report`,
      key: 'Active',
      data,
      message: 'Process Report',
      eventName: 'PROCESS_REPORT_PROCESSED',
      emitData: data,
    };
    await processReportCollectible(username, jsonData);
    // setLocalLoading(false);
  };

  useEffect(() => {
    getAdminReports();
  }, []);
  return (
    <Fragment>
      <div className='container'>
        <div className='row bg__white'>
          <div className='col-md-12'>
            {localLoading ? (
              <Loader />
            ) : reports.length < 1 ? (
              <h4 className='text-center p-3 m-0'>
                Oops! No data or reports at the moment...
              </h4>
            ) : (
              <AdminReportsTable
                data={reports}
                type='reports'
                handleProcessReportCollectible={handleProcessReportCollectible}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
  username: state.users.username,
});
export default connect(mapStateToProps, {
  processReportCollectible,
})(Reports);
