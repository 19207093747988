import axios from 'axios';
import {API} from './constants'

/** base url to make request to the BE end point */

const instance = axios.create({
	baseURL: API
});

export default instance;





// const API = $axios.create({
//     baseURL: $config.API_BASE_URL,
//     withCredentials: true
//   })