import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getArtisteEarning } from '../../actions/artiste';
import UploadLoader from '../../components/UploadLoader';
import EarningCard from '../../components/cards/EarningCard';
import { getEarningsTransactions } from '../../actions/market';
import EarningsTransactionHistory from '../../components/transactions/EarningsTransactionHistory';

const Earnings = ({
  getArtisteEarning,
  loading,
  earnings,
  total_earning,
  total_editions,
  getEarningsTransactions,
  earnings_history,
  usd_price,
  username,
}) => {
  useEffect(() => {
    getArtisteEarning();
    getEarningsTransactions({ username, types: 'buy,sell' });
  }, [getArtisteEarning, getEarningsTransactions, username]);
  return (
    <Fragment>
      {earnings === null ||
      total_earning === null ||
      total_editions === null ? (
        <UploadLoader size='small' />
      ) : (
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='earning__card__wrapper top'>
                <h4>
                  Collections <i className='fa fa-book-open'></i>
                </h4>
                <p>
                  {total_editions} <span>Collection(s)</span>
                </p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='earning__card__wrapper top'>
                <h4>
                  TOTAL EARNED <i className='fa fa-dollar-sign'></i>
                </h4>
                <p>
                  {total_earning} <span>SWAP.HIVE</span>
                </p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='earning__card__wrapper top'>
                <h4>
                  HOTTEST <i className='fa fa-fire'></i>
                </h4>
                <p>
                  <span>COMING SOON!</span>
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              {earnings.map((earning, i) => (
                <Link to={`/earnings/${earning.series}`} key={i}>
                  <EarningCard earning={earning} />
                </Link>
              ))}
            </div>
            <div className='col-md-1'></div>
          </div>
          <br />
          <br />
          <br />
          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              <EarningsTransactionHistory
                transactions={earnings_history}
                username={username}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  earnings: state.artiste.earnings,
  loading: state.artiste.loading,
  total_earning: state.artiste.total_earning,
  total_editions: state.artiste.total_editions,
  earnings_history: state.market.earnings_transaction_history,
  usd_price: state.market.usd_price,
  username: state.users.username,
});

export default connect(mapStateToProps, {
  getArtisteEarning,
  getEarningsTransactions,
})(Earnings);
