import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ title, button, backgroundImage }) => {
  return (
    <Fragment>
      <section
        className='nfttunz__main__header'
        style={{
          backgroundImage: `url(${
            backgroundImage
              ? backgroundImage
              : 'https://cdn.discordapp.com/attachments/897101784076668969/972234382410653776/TUNZ_Banner.png'
          })`,
          backgroundPosition: `${backgroundImage ? 'center' : 'center'}`,
        }}
      >
        <div className='nfttunz__main__header__overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='header__title text-center'>
                  <h1>{title && title}</h1>
                </div>
                <div className='header__hero text-center'></div>
                {button && (
                  <div className='header__button text-center'>
                    <Link to='/register'>Enter Tunz</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Header;
