import React from 'react';
import ContentLoader from 'react-content-loader';

const CatalogueLoader = (props) => (
  <ContentLoader
    speed={3}
    viewBox='0 0 400 460'
    backgroundColor='#291448'
    foregroundColor='#3D2365 '
    {...props}
  >
    <circle cx='368' cy='44' r='31' />
    <rect x='389' y='-33' rx='0' ry='0' width='35' height='2' />
    <rect x='21' y='35' rx='0' ry='0' width='373' height='298' />
    <rect x='18' y='318' rx='0' ry='0' width='379' height='133' />
  </ContentLoader>
);



export default CatalogueLoader;
