import React from 'react'
import { Fragment, useState, useEffect } from 'react'
import axios from '../../utils/axios'
import Moment from 'react-moment';


const AdminHome = () => {
  const [stats, setStats] = useState([]);
  const [jData, setJData] = useState({});
  const getStats = async () => {
    const response = await axios.get('/statistics?days=20');
    setJData(JSON.parse(response.data[0]?.transactions));
    setStats(response.data[0]);
  }
  useEffect(() => {
    getStats();
  }, []);
    return (
      <Fragment>
        <h1>Admin Home</h1>
        <p>Stats generated from <Moment fromNow>{stats.timestamp}</Moment></p>
        <div className='row'>
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Total Users</h4>
                <h2>{stats.users || 0}</h2>
              </div>
              
            </div>
          </div>
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Total Sold</h4>
                <h2>{jData.sell || 0}</h2>
              </div>
              
            </div>
          </div>
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Total Bought</h4>
                <h2>{jData.buy || 0}</h2>
              </div>
              
            </div>
          </div>
          <hr />
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Market Change Price</h4>
                <h2>{jData.change_price || 0}</h2>
              </div>
              
            </div>
          </div>
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Total Transactions</h4>
                <h2>{stats.total_transactions || 0}</h2>
              </div>
              
            </div>
          </div>
          <div className='col-md-4'>
            <div className='left__reports__wrapper'>
              <div className='admin__report__card'>
                <h4>Transfers</h4>
                <h2>{jData.transfer || 0}</h2>
              </div>
              
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default AdminHome
