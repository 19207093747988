import React, { Fragment } from 'react';
import UploadLoader from '../UploadLoader';
import { Link } from 'react-router-dom';

const CommunityCard = ({ community, loading, username }) => {
  return (
    <Fragment>
      <div className='activity__card__wrapper'>
        <div className='activity__image'>
          {!loading ? (
            <img
              className='img'
              src={community.thumbnail}
              alt='pixabay'
              width={100}
            />
          ) : (
            <UploadLoader size='small' />
          )}
        </div>
        <div className='activity__desc'>
          <h5>Description:</h5>
          <p>
            {community.description}
            <strong>{community.notes}</strong>
          </p>
          <br />
          Click
          <a
            href={`https://he.dtools.dev/tx/${community.trx_id}`}
            target='_blank'
            rel='noreferrer /market/'
          >
            <strong> here </strong>
          </a>
          to view community on the sidechain
        </div>
        {community.members.includes(username) ? (
          <button
            className={`nfttunz__subscribe__button`}
            disabled={community.members.includes(username)}
          >
            subscribed
          </button>
        ) : (
          <button
            className={`nfttunz__subscribe__button`}
            disabled={community.members.includes(username)}
          >
            <Link to={`/market/${community.series}`}>subscribe</Link>
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default CommunityCard;
