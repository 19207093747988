import {
  GET_BALANCES,
  GET_NFT_DEFINITION,
  GET_NFT_INSTANCES,
  GET_NFT_SELLBOOK,
  GET_NFT_AUCTIONS,
  SET_INSTANCE_LOADING,
  AUCTION_LOADING,
} from '../actions/types';

const initialState = {
  balances: [],
  nfts: [],
  instances: [],
  sellbook: [],
  auctions: [],
  loading: true,
  instanceLoading: true,
};

const nftsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUCTION_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case GET_BALANCES:
      return {
        ...state,
        balances: payload,
        loading: false,
      };
    case GET_NFT_DEFINITION:
      return {
        ...state,
        nfts: payload,
        loading: false,
      };
    case GET_NFT_INSTANCES:
      return {
        ...state,
        instances: payload,
        loading: false,
      };
    case GET_NFT_SELLBOOK:
      return {
        ...state,
        sellbook: payload,
        loading: false,
      };
    case GET_NFT_AUCTIONS:
      return {
        ...state,
        auctions: payload,
        loading: false,
      };
    case SET_INSTANCE_LOADING:
      return {
        ...state,
        instanceLoading: payload,
      };
    default:
      return state;
  }
};

export default nftsReducer;
