import { GENERATE_PASSWORD } from '../actions/types';

const initialState = {
  master_password: '',
  loading: true,
};

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GENERATE_PASSWORD:
      return {
        ...state,
        master_password: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default accountReducer;
