import React from 'react';
import Modal from 'react-awesome-modal';
import { toFixedWithoutRounding } from '../../utils/helpers';

const WithdrawModal = ({
  isShow,
  setIsShow,
  quantity,
  setQuantity,
  withdrawToken,
  setLoading,
  usd_price,
}) => {
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='320'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Withdraw Hive</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mt-1 text-center'>
              Enter the amount of HIVE you would like to withdraw to the
              mainnet.{' '}
              <small className='text-danger'>
                There is a 0.75% fee attached to this by{' '}
                <a
                  href='https://hive-engine.com/faq'
                  target='_blank'
                  rel='noreferrer'
                >
                  Hive Engine
                </a>
              </small>
            </p>
            <div className='modal__input__currency__wrapper mt-3'>
              <input
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className='w-100 modal__input text-center bold'
                type='number'
                placeholder='Enter the amount...'
              />{' '}
              <p className='modal__currency__estimate'>
                {quantity || 0} SWAP.HIVE <span className='text-danger'>=</span>{' '}
                ${toFixedWithoutRounding(quantity * usd_price)}
              </p>
            </div>
          </div>
          <div className='modal__footer pt-0'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
                setQuantity('');
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                withdrawToken(quantity);
                setQuantity('');
                setIsShow(false);
                setLoading(true);
              }}
            >
              Withdraw
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WithdrawModal;
