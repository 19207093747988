import { generatePassword } from '../utils/helpers';
import { GENERATE_PASSWORD } from './types';

export const initAccountKey = () => async (dispatch) => {
  try {
    const master_password = await generatePassword(32);
    dispatch({
      type: GENERATE_PASSWORD,
      payload: master_password,
    });
  } catch (err) {
    console.error(err.message);
  }
};
