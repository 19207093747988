import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from "./reducers";
import storage from 'redux-persist/lib/storage';

const initialState = {};
const middleware = [thunk];
const persistConfig = {
  key: 'reducer',
  storage: storage,
  whitelist: ['reducer'], // or blacklist to exclude specific reducers
};
const presistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  presistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

export default store;
