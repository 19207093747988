import React, { Fragment } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const SocialShare = ({ collectible, url }) => {
  return (
    <Fragment>
      <div className='d-flex social-share mt-3'>
        <div className='social_share__item'>
          <EmailShareButton
            url={url}
            subject={collectible?.name}
            body={`${collectible?.name} - ${collectible?.description}`}
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
        <div className='social_share__item'>
          <FacebookShareButton
            url={url}
            quote={collectible?.description}
            hashtag={collectible?.tags}
            className='social-share__button'
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </div>
        <div className='social_share__item'>
          <TwitterShareButton
            url={url}
            hashtags={collectible?.tags}
            caption={collectible?.description}
            via={collectible?.author}
            className='social-share__button'
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
        <div className='social_share__item'>
          <TelegramShareButton
            url={url}
            title={collectible?.description}
            className='social-share__button'
          >
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
        </div>
        <div className='social_share__item'>
          <WhatsappShareButton
            url={url}
            title={collectible?.description}
            className='social-share__button'
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </div>
      </div>
    </Fragment>
  );
};

export default SocialShare;
