import { Fragment } from 'react';
import Moment from 'react-moment';

const AdminReportsTable = ({ data, handleBanUser, handleProcessReportCollectible }) => {
  return (
    <Fragment>
      <table className='table nfttunz__table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Date</th>
            <th scope='col'>Reported By</th>
            <th scope='col'>Type</th>
            <th scope='col'>
              Series
            </th>
            <th scope='col'>Reason</th>
            <th scope='col'>Processed</th>
            <th scope='col'></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={index}>
              <th scope='row'>{index + 1}</th>
              <th scope='row'>
                <Moment fromNow>{d.timestamp}</Moment>
              </th>
              <td>{d.username}</td>
              <td>{d?.type}</td>
              <td>{d.series}</td>
              <td>{d.message}</td>
              <td>{d.processed === false ? 'false' : 'true'}</td>
              <td className='text-center'>
                <button
                  className='btn btn-warning py-1'
                  style={{ fontSize: '11px' }}
                  onClick={() =>
                    handleProcessReportCollectible({
                      series: d.series,
                      report_id: d.report_id,
                    })
                  }
                >
                  Process
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default AdminReportsTable;
