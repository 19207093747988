import React, { Fragment } from 'react'
import Accordion from '../components/Accordion/Accordion';
import Layout from '../components/Layout';
import { accordionData } from '../utils/data';

const FAQ = () => {
  return (
    <Fragment>
      <Layout>
        <section className=''>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='nfttunz__stats__header text-center'>FAQ</h2>
              <p className='text-center w-75 m-auto'>
                Nonfungible tokens (NFTs) have exploded onto the scene as a way
                for musicians and fans alike to monetize and appreciate their
                art in new ways. To help you understand this craze better, we've
                answered some common questions about what NFTs are and how
                they're being used in music today.
              </p>
            </div>
          </div>
          <div className='accordion'>
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        </section>
      </Layout>
    </Fragment>
  );
}

export default FAQ