import React, { Fragment, useEffect, useState } from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout';
import ActivityCard from '../components/cards/ActivityCard';
import { connect } from 'react-redux';
import { getTransactions } from '../actions/market';
import Loader from '../components/Loader';
import { getCollectible } from '../actions/collectibles';
import InfiniteScroll from 'react-infinite-scroll-component';
import MetaDecorator from '../components/utils/MetaDecorator';
import UploadLoader from '../components/UploadLoader';

const Activity = ({
  getTransactions,
  market: { transaction_history, transaction_length, loading },
  getCollectible,
  collectibles: { history_collectibles, collectible_loading },
}) => {
  const [page, setPage] = useState(1);
  const fetchMoreData = (page) => {
    const newPage = page + 1;
    setTimeout(() => {
      setPage(newPage);
    }, 1500);
  };
  useEffect(() => {
    getTransactions(page);
    return () => {};
  }, [getTransactions, page]);
  if (transaction_history && loading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <MetaDecorator
        title='Activities'
        description='Last few transactions'
        imageUrl='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
        imageAlt='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
      />
      <Layout>
        <Header title='Activities' button={false} />
        <section className='section__wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section__title text-center'>
                  <h2>Categories</h2>
                  <p>
                    An up to date activity feed showing proof of music! See all
                    of the recent initial and secondary market purchases
                    happening around the NFTTunz ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              {transaction_history.length > 0 ? (
                <InfiniteScroll
                  className='row justify-content-center'
                  dataLength={transaction_history.length}
                  next={() => fetchMoreData(page)}
                  hasMore={
                    transaction_history.length < transaction_length
                      ? true
                      : false
                  }
                  loader={
                    <h4 className='text-center mt-4'>
                      <UploadLoader size='small' />
                    </h4>
                  }
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yess! You have seen it all 😁</b>
                    </p>
                  }
                >
                  <div className='col-md-8'>
                    <div className='activity__main__wrapper'>
                      {transaction_history.map((trx, index) => {
                        return (
                          <ActivityCard
                            key={index}
                            transaction={trx}
                            loading={loading}
                            getCollectible={getCollectible}
                            collectible={history_collectibles.filter(
                              (col) => trx.series === col.series,
                            )}
                          />
                        );
                      })}
                    </div>
                  </div>
                </InfiniteScroll>
              ) : (
                <h2 className='text-center'>
                  No activity available at the moment
                </h2>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  market: state.market,
  collectibles: state.collectibles,
});
export default connect(mapStateToProps, { getTransactions, getCollectible })(
  Activity,
);
