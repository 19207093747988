/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Layout from '../../components/Layout';
import AudioPlayer from 'react-h5-audio-player';
import VideoPlayer from 'react-video-markers';
// import Slider from 'react-slick';
import Moment from 'react-moment';
import {
  fetchInterests,
  getCollectible,
  getCollectibles,
  getReports,
} from '../../actions/collectibles';
import { addToCart, removeFromCart } from '../../actions/cart';
import { setUsdPrice } from '../../actions/market';
// import Card from '../../components/cards/Card';
import {
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  placeBid,
  settleAuction,
  cancelBid,
  cancelAuction,
} from '../../actions/token';
import {
  getNFTInstances,
  getNFTInstance,
  getNFTDefinition,
  getNFTSellBook,
  airdropNFT,
  getAuctionInstances,
} from '../../actions/nfts';
import { reportCollectible } from '../../actions/admin';
import { login } from '../../actions/users';
import { SET_LOADING } from '../../actions/types';
import { Link } from 'react-router-dom';
import MetaDecorator from '../../components/utils/MetaDecorator';
import { followUser } from '../../actions/users';
import Loader from '../../components/Loader';
import LoginModal from '../../components/modals/LoginModal';
import SocialShare from '../../components/SocialShare';
import { getArtisteProfile } from '../../actions/profile';
import { getMarketTransactions } from '../../actions/transactions';
import TransactionHistory from '../../components/transactions/MarketTransactionHistory';
import CollectibleProperties from '../../components/cards/CollectibleProperties';
import AuctionList from '../../components/market/AuctionList';
import PlaceBidModal from '../../components/modals/PlaceBidModal';

let mounted = false;
const AuctionCollectible = ({
  match,
  creator_profile,
  getArtisteProfile,
  getCollectible,
  usd_price,
  getAuctionInstances,
  placeBid,
  settleAuction,
  cancelBid,
  cancelAuction,
  setUsdPrice,
  followUser,
  collectibles: { collectible, reports },
  col_loading,
  username,
  following,
  authenticated,
  nfts: { auctions, sellbook, loading },
  history,
  login,
  settings: { prefix },
  reportCollectible,
  getReports,
  getMarketTransactions,
  market_transactions,
}) => {
  const [marketLoading, setMarketLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [usernameField, setUsername] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [isShowPlaceBid, setIsShowPlaceBid] = useState('');
  const [auctionId, setAuctionId] = useState('');
  const [auctionPrice, setAuctionPrice] = useState('');
  const setUsernameField = (e) => {
    setUsername(e.target.value);
  };
  const dispatch = useDispatch();
  const loginUser = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    await login(usernameField);
    setIsShow(false);
  };
  const hideModal = () => {
    setIsShow(false);
  };
  const handleProgress = (e) => {
    // console.log('Current time: ', e.target.currentTime);
  };
  const handleReportCollectible = async (data) => {
    setMarketLoading(true);
    const jsonData = {
      id: `${prefix}_report_collectible`,
      key: 'Active',
      data,
      message: 'Report Collectible',
      eventName: 'REPORT_COLLECTIBLE',
      emitData: data,
    };
    await reportCollectible(username, jsonData);
    // setLocalLoading(false);
  };
  const handleDuration = (duration) => {
    console.log('Duration: ', duration);
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVolume = (value) => {
    setVolume(value);
  };
  const {
    params: { series },
  } = match;

  const authorEnd = series.indexOf('_');
  const author = series.substring(0, authorEnd);
  useEffect(() => {
    window.scrollTo(0, 0);
    getCollectible(series);
    getAuctionInstances(series);
    setUsdPrice();
    getReports(series);
    getArtisteProfile(author);
    getMarketTransactions({ series });
    return () => (mounted = false);
  }, [
    series,
    username,
    getCollectible,
    author,
    setUsdPrice,
    getReports,
    getArtisteProfile,
    getMarketTransactions,
    getAuctionInstances,
  ]);

  if (col_loading || loading || marketLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }
  return (
    <Fragment>
      <MetaDecorator
        description={collectible?.description}
        title={collectible?.name}
        imageUrl={collectible?.thumbnail}
        imageAlt={collectible?.thumbnail}
      />
      <Layout>
        {sellbook.some(
          (d) => d.account === username && d.series === series,
        ) && (
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 p-0'>
                <div className='panel bg-black bg-light panel-success p-2 market__info'>
                  <h6 className='text-center'>
                    <i className='fa fa-info-circle'></i> Kindly modify this
                    collection and manage it in{' '}
                    <Link to={`/collectible/${collectible?.series}`}>
                      <strong className='market__info__link'>
                        Manage Collection
                      </strong>
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='player__wrapper__height'>
                <div
                  className={`file__player player__height__size ${
                    (collectible?.type === 'audio' && 'w-75') ||
                    (collectible?.type === 'fan' && 'w-75')
                  }`}
                >
                  {collectible?.type === 'audio' ? (
                    <div style={{ width: '100%', height: '100%' }}>
                      <div className='audio__thumbnail market__audio__thumbnail'>
                        <div
                          className='audio__thumbnail__blurred__background'
                          style={{
                            backgroundImage: `url(${collectible?.thumbnail})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            zIndex: '-1',
                          }}
                        ></div>
                        <div className='audio__thumbnail__blurred__overlay'></div>
                        <img
                          src={collectible?.thumbnail}
                          alt='thumbnail'
                          width='100%'
                          height='350px'
                          // style={{ objectFit: 'cover' }}
                        />
                      </div>
                      <AudioPlayer
                        autoPlay
                        src={collectible?.file}
                        onPlay={(e) => console.log('onPlay')}
                        // other props here
                      />
                    </div>
                  ) : collectible?.type === 'fan' ? (
                    <div style={{ width: '100%', height: '100%' }}>
                      <div className='audio__thumbnail market__audio__thumbnail__fan'>
                        <div
                          className='audio__thumbnail__blurred__background'
                          style={{
                            backgroundImage: `url(${collectible?.thumbnail})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                        <div className='audio__thumbnail__blurred__overlay'></div>
                        <img
                          src={collectible?.thumbnail}
                          alt='thumbnail'
                          width='100%'
                          height='450px'
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                    </div>
                  ) : (
                    mounted === true && (
                      <VideoPlayer
                        url={collectible?.file}
                        isPlaying={isPlaying}
                        volume={volume}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onVolume={handleVolume}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        width='70em'
                        height='40em'
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='nfttunz__card market__card market__screen__card'>
                <div className='collectible__title'>
                  <h1 className='market__collectible__title'>
                    {collectible?.name}
                    <button
                      disabled={
                        reports.length >= 1 || collectible?.creator === username
                      }
                      className={`btn btn-primary btn-sm mx-1 report__button ${
                        reports.length <= 0 &&
                        collectible?.creator === username &&
                        'display-none'
                      }`}
                      onClick={() => {
                        handleReportCollectible({
                          series: collectible.series,
                          reason: window.prompt(
                            'Enter reason: (either: plagiarism, nsfw, copyright',
                          ),
                          message: 'Your collectible has been reported',
                        });
                      }}
                    >
                      {reports.length >= 1 ? 'REPORTED' : 'REPORT'}
                    </button>
                  </h1>
                  <p className='collectible__created__at'>
                    Minted on{' '}
                    <Moment format='MMM Do, YYYY'>
                      {collectible?.created_at}
                    </Moment>{' '}
                    <a
                      href={`http://https://he.dtools.dev/tx/${'xwwxxx'}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fas fa-external-link-alt'></i>
                    </a>
                  </p>
                </div>
                <div className='creator__info mt-3 d-inline-block'>
                  <p className='created__by'>
                    <strong>Created by</strong>
                  </p>
                  <Link
                    to={`/@${collectible?.creator}`}
                    className='market__creator__info'
                  >
                    <img
                      src={
                        creator_profile?.avatar ||
                        `https://images.hive.blog/u/${creator_profile?.username}/avatar/small`
                      }
                      alt='creator'
                    />
                    <p className='collectible__descriptive__text'>
                      @{collectible?.creator}
                    </p>
                  </Link>
                  {/* Creator Detail Popup */}
                  <div className='creator__dialog'>
                    <div className='d-flex justify-content-between'>
                      <Link
                        to={`/@${collectible?.creator}`}
                        className='market__creator__info d-flex'
                      >
                        {' '}
                        <img
                          src={
                            creator_profile?.avatar ||
                            `https://images.hive.blog/u/${creator_profile?.username}/avatar/small`
                          }
                          alt='creator'
                        />
                        <p className='collectible__descriptive__text'>
                          @{collectible?.creator}
                        </p>
                      </Link>
                      {authenticated && (
                        <div className='d-flex'>
                          {collectible?.creator !== username &&
                            username !== collectible?.creator &&
                            (following.includes(collectible?.creator) ? (
                              <button
                                className='btn btn-primary btn-sm text-center market__follow__button'
                                onClick={() => {
                                  followUser(
                                    collectible?.creator,
                                    false,
                                    history,
                                  );
                                  window.location.reload();
                                }}
                              >
                                unfollow
                              </button>
                            ) : (
                              <button
                                className='btn btn-primary btn-sm text-center m-1 market__follow__button'
                                onClick={() => {
                                  followUser(
                                    collectible?.creator,
                                    true,
                                    history,
                                  );
                                  window.location.reload();
                                }}
                              >
                                follow
                              </button>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className='artiste__info'>
                      <h5 className='mt-2'>Bio</h5>
                      <p className='my-2'>
                        {creator_profile?.bio || 'No bio written yet...'}
                      </p>
                      <p className='followed__by__wrapper'>
                        <strong>Followed By</strong>
                        {creator_profile?.following?.length > 0 ? (
                          creator_profile?.following
                            .slice(0, 10)
                            .map((f, i) => (
                              <Link
                                to={`/@${f}`}
                                className='following__wrapper'
                                key={i}
                              >
                                <img
                                  src={`https://images.hive.blog/u/${f}/avatar/small`}
                                  alt='followere'
                                />
                              </Link>
                            ))
                        ) : (
                          <span>none</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='collectible__description__wrapper mt-3'>
                  <CollectibleProperties collectible={collectible} />
                </div>
                <SocialShare
                  collectible={collectible}
                  url={`https://market.nfttunz.io/market/${collectible?.series}`}
                />
                <p className='collectible__descriptive__text mt-3'>
                  {collectible?.tags?.map((tag, index) => (
                    <span className='collectible__tag' key={index}>
                      #{tag}{' '}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='nfttunz__card market__card market__screen__card market__sellbook__height'>
                {authenticated ? (
                  auctions?.length > 0 ? (
                    <Fragment>
                      <h4>Auction</h4>
                      <AuctionList
                        auctions={auctions}
                        loading={loading}
                        setAuctionId={setAuctionId}
                        setIsShowPlaceBid={setIsShowPlaceBid}
                        username={username}
                        series={series}
                        usd_price={usd_price}
                        collectible={collectible}
                        isUser={false}
                        marketLoading={marketLoading}
                        setMarketLoading={setMarketLoading}
                        settleAuction={settleAuction}
                        cancelBid={cancelBid}
                        cancelAuction={cancelAuction}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h4 className='text-center mt-3'>
                        No music here yet... Get creative and make some!
                      </h4>
                    </Fragment>
                  )
                ) : (
                  <div className='not__auth__button__wrapper text-center my-5'>
                    <button onClick={() => setIsShow(true)}>Login</button>
                    <h4 className='mt-3'>
                      You must login to buy or sell an NFT
                    </h4>
                  </div>
                )}
              </div>
              <div className='nfttunz__card market__card market__screen__card market__sellbook__height'>
                <TransactionHistory
                  market_transactions={market_transactions}
                  series={series}
                  title={'Recent Activities'}
                />
              </div>
            </div>
          </div>
        </div>
        <PlaceBidModal
          isShow={isShowPlaceBid}
          setIsShow={setIsShowPlaceBid}
          auctionId={auctionId}
          auctionPrice={auctionPrice}
          setAuctionPrice={setAuctionPrice}
          usd_price={usd_price}
          placeBid={placeBid}
          history={history}
          route={`/collectible/${collectible?.series}`}
          setLoading={setMarketLoading}
        />
        <LoginModal
          show={isShow}
          handleClose={hideModal}
          username={usernameField}
          setUsernameField={setUsernameField}
          loginUser={loginUser}
        />
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
  col_loading: state.collectibles.loading,
  nfts: state.nfts,
  username: state.users.username,
  following: state.users.following,
  authenticated: state.users.authenticated,
  cart: state.cart.cart,
  usd_price: state.market.usd_price,
  settings: state.settings,
  creator_profile: state.profiles.artiste_profile,
  market_transactions: state.transactions.market_transactions,
});
export default connect(mapStateToProps, {
  getCollectible,
  getCollectibles,
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  addToCart,
  removeFromCart,
  getNFTDefinition,
  getNFTInstances,
  getNFTInstance,
  getNFTSellBook,
  fetchInterests,
  setUsdPrice,
  followUser,
  airdropNFT,
  login,
  reportCollectible,
  getReports,
  getArtisteProfile,
  getMarketTransactions,
  getAuctionInstances,
  placeBid,
  settleAuction,
  cancelBid,
  cancelAuction,
})(AuctionCollectible);
