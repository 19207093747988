import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProfile } from '../../actions/profile';
import { Link } from 'react-router-dom';
import axios from '../../utils/axios';
import { setToastNotification } from '../../utils/helpers';
import Loader from '../../components/Loader';
import { getFollowers, getFollowing } from '../../actions/users';

const Info = ({
  getProfile,
  profiles: { profile, loading },
  user,
  getFollowing,
  getFollowers,
}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [applied_message] = useState(
    'Your application will soon be approved by an admin',
  );

  useEffect(() => {
    getProfile();
    getFollowing(user.username);
    getFollowers(user.username);
  }, [getProfile, getFollowing, getFollowers, user?.username]);
  const applyWhitelist = async () => {
    setLocalLoading(true);
    const whitelist = await axios.post('/users/whitelist/apply');
    const status = whitelist.data.success;
    if (status !== true) {
      setToastNotification('Something went wrong', 'error');
      setLocalLoading(false);
      return;
    }
    setToastNotification('Application Submitted', 'success');
    setLocalLoading(false);
    return;
  };
  const applyArtiste = async () => {
    setLocalLoading(true);
    const whitelist = await axios.post('/users/artiste/apply');
    const status = whitelist.data.success;
    if (status !== true) {
      setToastNotification('Something went wrong', 'error');
      setLocalLoading(false);
      return;
    }
    setToastNotification('Application Submitted', 'success');
    setLocalLoading(false);
    return;
  };
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            {localLoading && <Loader />}
            <div className='sub__profile__header'>
              <h2>My Profile</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <div className='profile__card'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='profile__card__title'>
                    <h4>Username:</h4>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='profile__card__value'>
                    <h4>@{profile?.username}</h4>
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-4'>
                  <div className='profile__card__title'>
                    <h4>Bio:</h4>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='profile__card__value'>
                    <h4>{profile?.bio}</h4>
                    <Link
                      className='btn btn-primary btn-sm text-center report__button false'
                      to='/profile/edit'
                    >
                      edit
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-4'>
                  <div className='profile__card__title'>
                    <h4>Whitelisted:</h4>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='profile__card__value'>
                    <h4>{profile?.whitelisted === false ? 'false' : 'true'}</h4>
                    {!profile?.whitelisted && (
                      <>
                        {' '}
                        <button
                          disabled={profile?.whitelist_applied}
                          onClick={() => applyWhitelist()}
                        >
                          {profile?.whitelist_applied ? 'applied' : 'apply'}{' '}
                          {profile?.application_pending && <span>pending</span>}
                        </button>
                        {profile?.application_pending && (
                          <>
                            <br />
                            <span className='application_message'>
                              <i className='fa fa-info-circle'></i>{' '}
                              {applied_message}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-4'>
                  <div className='profile__card__title'>
                    <h4>Verified:</h4>
                    <small className='text-info'>Verified artists have blue ticks</small>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='profile__card__value'>
                    <h4>{profile?.artiste === false ? 'false' : 'true'}</h4>
                    {!profile?.artiste && (
                      <>
                        <button
                          className='btn btn-primary btn-sm text-center report__button false'
                          disabled={profile?.artiste_applied}
                          onClick={() => applyArtiste()}
                        >
                          {profile?.artiste_applied ? 'applied' : 'apply'}{' '}
                          {profile?.artiste_pending && <span>pending</span>}
                        </button>
                        {profile?.artiste_pending && (
                          <>
                            <br />
                            <span className='application_message'>
                              <i className='fa fa-info-circle'></i>{' '}
                              {applied_message}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-4'>
                  <div className='profile__card__title'>
                    <h4>Reffered by:</h4>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='profile__card__value'>
                    <h4>null</h4>
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6'>
                  <div className='row'>
                    {user?.following.length > 0 && (
                      <div className='col-md-6'>
                        <div className='profile__card__title'>
                          <h4>Following:</h4>
                        </div>
                        <div className='profile__card__value'>
                          <ul className='followers__list'>
                            {user?.following?.length > 0 ? (
                              user?.following?.map((following, index) => (
                                <li key={index} className='each__follow px-1'>
                                  <Link to={`/@${following}`}>
                                    @{following}
                                  </Link>
                                </li>
                              ))
                            ) : (
                              <span>No following yet...</span>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className='col-md-6'>
                      <div className='profile__card__title '>
                        <h4>Followers:</h4>
                      </div>
                      <div className='profile__card__value'>
                        <ul className='followers__list '>
                          {user?.followers?.length > 0 ? (
                            user?.followers?.map((follower, index) => (
                              <li key={index} className='each__follow px-1'>
                                <Link to={`/@${follower}`}>@{follower}</Link>
                              </li>
                            ))
                          ) : (
                            <li>
                              <span>No followers yet...</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'></div>
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  profiles: state.profiles,
  user: state.users,
});
export default connect(mapStateToProps, {
  getProfile,
  getFollowing,
  getFollowers,
})(Info);
