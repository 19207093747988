import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getBalances } from '../../actions/nfts';
import { convertCredits, getCredits } from '../../actions/credits';
import { withdrawHive } from '../../actions/token';
import BuyCreditsModal from '../../components/modals/BuyCredits';
import CoinPaymentsDetails from '../../components/modals/CoinPaymentsDetails';
import {
  toFixedWithoutRounding,
  setToastNotification,
} from '../../utils/helpers';
import hiveLogo from '../../assets/images/hive_logo.svg';
import WithdrawModal from '../../components/modals/WithdrawModal';

const Wallet = ({
  getBalances,
  getCredits,
  user: { username },
  credits,
  currency,
  hive_balances,
  payments,
  hive_price,
  convertCredits,
  withdrawHive,
  tokenLoading,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowPaymentInfo, setIsShowPaymentInfo] = useState(false);
  const [isShowWithdraw, setIsShowWithdraw] = useState(false);
  const [quantity, setQuantity] = useState(1000);
  const [withdrawQuantity, setWithdrawQuantity] = useState(0.5);
  const [creditUnits, setCreditUnits] = useState(1000);
  const [swapHiveUnits, setSwapHiveUnits] = useState(1000 / hive_price);
  const [loading, setLoading] = useState(false);
  const handleConvertCredits = async (data) => {
    if (swapHiveUnits === null) {
      return setToastNotification(
        'Please refresh page to get new price quote',
        'info',
      );
    }
    setLoading(true);

    await convertCredits(data);
    setLoading(false);
  };
  const swap_hive_balance = hive_balances.find(
    (balance) => balance?.symbol === currency,
  )?.balance;
  useEffect(() => {
    getBalances(username, currency);
    getCredits();
    setLoading(tokenLoading);
  }, [getBalances, getCredits, username, currency, tokenLoading]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header'>
              <h2>My Wallet</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='wallet__card__wrapper m-auto w-100 min__height__220'>
              <h6>CREDIT BALANCE</h6>
              <p className='text-center'>
                <strong>Credits</strong>: <br />
                <span>{credits}</span>
                <br />
                <strong className='text-warning wallet__info'>
                  Convert to SWAP.HIVE at any time
                </strong>
              </p>
              <div className='mini__balances'>
                {/* <div className='wallet__cards'>
                  <h6>CREDITS</h6> <p>{balances.credits}</p>
                </div> */}

                {/* <div className='wallet__cards'>
                  <h6>BONUS</h6> <span>{balances.bonus}</span>
                </div>
                <div className='wallet__cards'>
                  <h6>REFERRAL</h6>
                  <span>{balances.referral_earnings}</span>
                </div>
                <div className='wallet__cards'>
                  <h6>TOTAL SPENT</h6>
                  <span>{balances.total_spent}</span>
                </div> */}
              </div>

              <button
                disabled={loading}
                className='auth__button mt-2'
                onClick={() => setIsShow(true)}
              >
                {loading ? (
                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                ) : (
                  'Buy Credits'
                )}
              </button>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='wallet__card__wrapper m-auto w-100 min__height__220'>
              <h6>Swap Credit</h6>
              <p className='text-center'>
                <strong>Credits</strong>: <br />
                <span>{credits}</span>
              </p>
              <div className='mini__balances'>
                <div className='double__input__row'>
                  <div className='nfttunz__credits__input__wrapper nfttunz__input__border w-50'>
                    <button
                      disabled={credits <= creditUnits}
                      onClick={() => {
                        setCreditUnits(creditUnits + 1000);
                      }}
                    >
                      <strong>+</strong>
                    </button>
                    <div name='creditUnits' className='credit__units'>
                      <h5 className='m-0'>{creditUnits}</h5>
                    </div>
                    <button
                      disabled={loading || 1000 === creditUnits}
                      onClick={() => {
                        setCreditUnits(creditUnits - 1000);
                      }}
                    >
                      <strong>-</strong>
                    </button>
                  </div>
                  <div className='nfttunz__credits__input__wrapper nfttunz__input__border w-50'>
                    <input
                      value={
                        toFixedWithoutRounding(
                          creditUnits / 1000 / hive_price,
                        ) || 0
                      }
                      disabled
                      name='swapHiveUnits'
                      onChange={(e) => {
                        setSwapHiveUnits(creditUnits / 1000 / hive_price);
                      }}
                      className='nfttunz__input'
                      type='number'
                      placeholder='Refresh the page'
                    />
                    <button disabled='disabled'>SWAP.HIVE</button>
                  </div>
                </div>
              </div>
              <div className='warning__wrapper'>
                {credits < creditUnits && (
                  <strong className='wallet__info text-warning'>
                    <i className='fa fa-info-circle'></i> Your credit balance is
                    not enough or too low.
                  </strong>
                )}{' '}
                {isNaN(swapHiveUnits) && (
                  <strong className='wallet__info text-info'>
                    <i className='fa fa-info-circle'></i> Refresh page to get
                    new price quote.
                  </strong>
                )}
              </div>
              <button
                disabled={loading || credits < creditUnits}
                className='auth__button mt-2'
                onClick={() =>
                  handleConvertCredits({
                    credits: creditUnits,
                    quantity: toFixedWithoutRounding(
                      creditUnits / 1000 / hive_price,
                    ),
                    currency: 'SWAP.HIVE',
                  })
                }
              >
                {loading ? (
                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                ) : (
                  'Convert Credits'
                )}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-12'>
            <div className='preview__card m-auto w-50'>
              <h6>Chain Balance</h6>
              {hive_balances.map((bal, index) => {
                return (
                  <p key={index} className='text-center'>
                    <strong>{bal.symbol}</strong>: <br />
                    <span>{bal.balance}</span>
                  </p>
                );
              })}
              <button
                disabled={
                  loading || (swap_hive_balance && swap_hive_balance < 0)
                }
                className='auth__button withdraw__flex__button mt-2'
                onClick={() => setIsShowWithdraw(true)}
              >
                {loading ? (
                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                ) : (
                  <>
                    <img src={hiveLogo} alt='hive' width={18} />{' '}
                    <span>Withdraw to HIVE</span>
                  </>
                )}
              </button>
              <p className='w-100 text-center text-info'>
                Withdraw your token to the{' '}
                <a
                  style={{
                    display: 'inline-block',
                    textDecoration: 'underline',
                  }}
                  href={`http://wallet.hive.blog/@${username}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Hive Mainnet
                </a>{' '}
                wallet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BuyCreditsModal
        isShow={isShow}
        setIsShow={setIsShow}
        setQuantity={setQuantity}
        quantity={quantity}
        setLoading={setLoading}
        isShowPaymentInfo={isShowPaymentInfo}
        setIsShowPaymentInfo={setIsShowPaymentInfo}
      />
      <WithdrawModal
        isShow={isShowWithdraw}
        setIsShow={setIsShowWithdraw}
        quantity={withdrawQuantity}
        setQuantity={setWithdrawQuantity}
        withdrawToken={withdrawHive}
        setLoading={setLoading}
        usd_price={hive_price}
      />
      <CoinPaymentsDetails
        payments={payments}
        isShow={isShowPaymentInfo}
        setIsShow={setIsShowPaymentInfo}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  hive_balances: state.nfts.balances,
  user: state.users,
  currency: state.settings.currency,
  hive_price: state.settings.hive_price,
  prefix: state.settings.prefix,
  payments: state.payments,
  credits: state.credits.credits,
  tokenLoading: state.token.loading,
});
export default connect(mapStateToProps, {
  getBalances,
  convertCredits,
  getCredits,
  withdrawHive,
})(Wallet);
