import React from 'react';
import upload_loader from '../assets/images/upload_loader_trans.gif';
const UploadLoader = ({ size }) => {
  return (
    <img
      className='text-center m-auto d-block'
      src={upload_loader}
      alt='loading...'
      style={{width: `${size === 'small' ? '100px' : '30px'}`}}
    />
  );
};

export default UploadLoader;
