import React, { Fragment, useState } from 'react'
import Loader from '../../../components/Loader';
import {connect} from 'react-redux'
import { manualIssue } from '../../../actions/admin';

const ManualIssue = ({settings: {prefix}, username, manualIssue}) => {
    const [localLoading, setLocalLoading] = useState(false);
    const [trx_id, setTrxId] = useState('')
    const handleManualIssue = (data) => {
        setLocalLoading(true)
        const jsonData = {
          id: `${prefix}_manual_issue`,
          key: 'Active',
          data,
          message: 'Manual Issue',
          eventName: 'MANUAL_ISSUE_PROCESSED',
          emitData: data,
        };
        manualIssue(username, jsonData)
        setLocalLoading(false)
    }
    const handleSetTrxId = (e) => {
        setTrxId(e.target.value)
    }
    return (
      <Fragment>
        <div className='container'>
          <div className='row bg__white'>
            <div className='col-md-12'>
              {localLoading && <Loader />}
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content custom__modal__background'>
                  <div className='modal-body'>
                    <form>
                      <div className='mb-3'>
                        <label
                          htmlFor='recipient-name'
                          className='col-form-label'
                        >
                          Manually Issue With Transaction ID:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='recipient-name'
                          name='Transactions ID'
                          placeholder='Enter transaction ID'
                          value={trx_id}
                          onChange={handleSetTrxId}
                        />
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button
                      onClick={() => handleManualIssue({ trx_id: trx_id })}
                      type='button'
                      className='btn btn-primary btnGeneral'
                    >
                      Issue Manually
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}
const mapStateToProps = state => ({
    settings: state.settings,
    username: state.users.username
})
export default connect(mapStateToProps, {manualIssue})(ManualIssue)
