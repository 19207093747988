import {
  GET_MARKET,
  SET_INTERESTS,
  SET_MORE_INTERESTS,
  SET_PAGINATION,
  SET_ON_MARKET,
  SEARCH_COLLECTIBLES,
  GET_TRANSACTION_HISTORY,
  SET_USD_PRICE,
  GET_ADMIN_TRANSACTION_HISTORY,
  SET_TRANSACTION_LENGTH,
  GET_EARNINGS_TRANSACTION_HISTORY,
  SET_SERIES_STRING,
} from '../actions/types';

const initialState = {
  market: [],
  interests: [],
  series: [],
  nfts: [],
  history: [],
  transaction_history: [],
  earnings_transaction_history: [],
  search_results: [],
  usd_price: Number(localStorage.getItem('usd_price')),
  pagination: {
    limit: 30,
    page: 0,
    sort_by: 'newest',
    rights: null,
    category: null,
    price_min: '',
    price_max: '',
    has_more: true,
    market_length: null,
  },
  transaction_length: null,
  loading: true,
};

export const marketReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MARKET:
    case SET_ON_MARKET:
    case SEARCH_COLLECTIBLES:
      return {
        ...state,
        market: payload,
        loading: false,
      };
    case SET_MORE_INTERESTS:
      return {
        ...state,
        interests: payload,
        loading: false,
      };
    case SET_USD_PRICE:
      localStorage.setItem('usd_price', payload);
      return {
        ...state,
        usd_price: payload,
      };
    case SET_SERIES_STRING:
      return {
        ...state,
        series: payload,
        loading: false,
      };
    case SET_INTERESTS:
      return {
        ...state,
        interests: payload,
        loading: false,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
        loading: false,
      };
    case GET_TRANSACTION_HISTORY:
    case GET_ADMIN_TRANSACTION_HISTORY:
      return {
        ...state,
        transaction_history: payload,
        loading: false,
      };
    case GET_EARNINGS_TRANSACTION_HISTORY:
      return {
        ...state,
        earnings_transaction_history: payload,
        loading: false,
      };
    case SET_TRANSACTION_LENGTH:
      return {
        ...state,
        transaction_length: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default marketReducer;
