import './App.css';
import Home from './pages/Home';
import Browse from './pages/Browse';
import Activity from './pages/Activity';
import Market from './pages/Market';
import Auctions from './pages/Auctions';
import Profile from './pages/Profile';
import ArtisteProfile from './pages/ArtisteProfile';
import Collectible from './pages/Collectible';
import Communities from './pages/Communities';
import MarketCollectible from './pages/sub-routes/MarketCollectible';
import AuctionCollectible from './pages/sub-routes/AuctionCollectible';
import MintToken from './pages/MintToken';
import Admin from './pages/admin/Admin';
import Checkout from './pages/Checkout';
import Register from './pages/Register';
import FAQ from './pages/FAQ';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import { useEffect } from 'react';
import { loadUser, getNotifications } from './actions/users';
import { setUsdPrice } from './actions/market';
import setAuthToken from './utils/setAuthToken';
import 'react-toastify/dist/ReactToastify.css';
import { getChainSettings } from './actions/settings';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import EarningsDetails from './pages/sub-routes/EarningsDetails';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-h5-audio-player/lib/styles.css';
import Feeds from './pages/Feeds';
import { getArtistes } from './actions/profile';
import { getTransactions } from './actions/transactions';
import Landing from './pages/Landing';

const token = localStorage.getItem('token');
if (token) {
  setAuthToken(token);
}
const getSettings = async () => await store.dispatch(getChainSettings());
function App() {
  useEffect(() => {
    store.dispatch(loadUser());
    getSettings();
    store.dispatch(getNotifications());
    store.dispatch(setUsdPrice());
    store.dispatch(getArtistes());
    store.dispatch(getTransactions(localStorage.getItem('username')));
  }, []);
  return (
    <div className='App'>
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <Switch>
              <Route exact path='/home' component={Home} />
              <Route exact path='/' component={Landing} />
              <Route exact path='/browse/:search?' component={Browse} />
              <PrivateRoute exact path='/feed' component={Feeds} />
              <Route exact path='/activity' component={Activity} />
              <Route exact path='/market' component={Market} />
              <Route exact path='/auctions' component={Auctions} />
              <Route exact path='/register/:ref?' component={Register} />
              <PrivateRoute path='/profile' component={Profile} />
              <PrivateRoute
                exact
                path='/earnings/:series'
                component={EarningsDetails}
              />
              <Route path='/@:username' component={ArtisteProfile} />
              <Route path='/market/:series' component={MarketCollectible} />
              <Route path='/auction/:series' component={AuctionCollectible} />
              <PrivateRoute
                exact
                path='/collectible/:series'
                component={Collectible}
              />
              <PrivateRoute exact path='/mint' component={MintToken} />
              <Route exact path='/communities' component={Communities} />
              <Route exact path='/faq' component={FAQ} />
              <PrivateRoute exact path='/checkout' component={Checkout} />
              <AdminRoute path='/admin' component={Admin} />
              <Route path='*' render={() => <h1>404 not found</h1>} />
            </Switch>
          </PersistGate>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
