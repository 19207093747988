import { GET_COMMUNITIES, GET_COMMUNITY } from './types';
import axios from '../utils/axios';

export const getCommunities = () => async (dispatch) => {
  try {
    const data = await axios.get('/communities/list');
    dispatch({
        type: GET_COMMUNITIES,
        payload: data.data
    })
  } catch (err) {
    console.error(err);
  }
};
export const getCommunity = (series) => async (dispatch) => {
  try {
    const data = await axios.get(`/communities/info?series=${series}`);
    dispatch({
        type: GET_COMMUNITY,
        payload: data.data
    })
  } catch (err) {
    console.error(err);
  }
};
