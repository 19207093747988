import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import axios from '../../utils/axios';
import tinyLoading from '../../assets/images/tiny__loading.gif';

const TransferModal = ({
  isShow,
  setIsShow,
  setReceipient,
  receipient,
  setMemo,
  memo,
  instance,
  transferNFT,
  setLoading,
  username,
}) => {
  const [available, setAvailable] = useState(true);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [error, setError] = useState('Account not found');
  const handleValidateAccount = async (username) => {
    try {
      setAvailableLoading(true);
      const account = await axios.get(`/account/validate?username=${username}`);
      
      setAvailable(account.data.chain_user === 'existing' ? false : true);
      setAvailableLoading(false);
      return account.data;
    } catch (err) {
      console.error(err.message);
     
        setError('Invalid username');
        setAvailable(true);
    
    }
  };
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='320'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Transfer NFT</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mb-3'>
              Enter the username of the account you would like to transfer the NFT to.
            </p>
            <div>
              <input
                value={receipient}
                onChange={(e) => {
                  setReceipient(e.target.value);
                  handleValidateAccount(e.target.value);
                }}
                className='w-100 modal__input'
                type='text'
                placeholder='Enter a receipient...'
              />
              <div className='text-center'>
                {availableLoading && receipient.length >= 3 ? (
                  <img src={tinyLoading} alt='loading...' />
                ) : (
                  available &&
                  receipient.length >= 3 && (
                    <small
                      id='emailHelp'
                      className='form-text text-danger small'
                    >
                      {error}
                    </small>
                  )
                )}
              </div>
              <input
                value={memo}
                onChange={(e) => {
                  setMemo(e.target.value);
                }}
                className='w-100 modal__input mt-2'
                type='text'
                placeholder='Enter memo (optional)...'
              />
            </div>
          </div>
          <div className='modal__footer'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
                setReceipient('');
                setMemo('');
              }}
            >
              Close
            </button>
            <button
              disabled={available}
              type='button'
              className='modal__button'
              onClick={() => {
                transferNFT(
                  instance._id.toString(),
                  username,
                  receipient,
                  memo,
                );
                setReceipient('');
                setMemo('');
                setIsShow(false);
                setLoading(true);
              }}
            >
              Transfer NFT
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferModal;
