import { GET_ARTISTE_MARKET, GET_ARTISTE_EARNINGS } from '../actions/types';

const initialState = {
  artiste_market: null,
  loading: true,
  artiste_market_length: 0,
  earnings: null,
  total_earning: null,
  total_editions: null
};

const get_artiste_market = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ARTISTE_MARKET:
      return {
        ...state,
        artiste_market: payload,
        loading: false,
        artiste_market_length: payload.length,
      };
    case GET_ARTISTE_EARNINGS:
      return {
        ...state,
        earnings: payload.earnings,
        total_earning: payload.totalEarning,
        total_editions: payload.totalEditions,
        loading: false
      }
    default:
      return state;
  }
};


export default get_artiste_market;