import {
  GET_PROFILE,
  SET_PROFILE,
  GET_ARTISTE_PROFILE,
  GET_ARTISTES,
} from './types';
import axios from '../utils/axios';

export const setProfile = (formData) => async (dispatch) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    const data = axios.post('/users/profile', formData, config);
    dispatch({
      type: SET_PROFILE,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    const data = await axios.get('/users/profile');
    dispatch({
      type: GET_PROFILE,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getArtisteProfile = (param) => async (dispatch) => {
  try {
    const data = await axios.get(
      `/users/profile/artiste?username=${param}`
    );
    dispatch({
      type: GET_ARTISTE_PROFILE,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getArtistes = (param) => async (dispatch) => {
  try {
    const data = await axios.get(
      `/users/profile/artiste/all`
    );
    dispatch({
      type: GET_ARTISTES,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
