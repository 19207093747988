import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { createToken } from '../actions/token';
import {
  maxPossibleEditions,
  setToastNotification,
  uploadFile,
  uploadMultipleFiles,
} from '../utils/helpers';
import UploadLoader from '../components/UploadLoader';
import { getBalances } from '../actions/nfts';
import Loader from '../components/Loader';
import { getMintSeries } from '../actions/collectibles';

const MintToken = ({
  createToken,
  settings: {
    categories,
    currency,
    token_issuance_base_fee,
    token_issuance_fee,
  },
  username,
  artiste,
  getBalances,
  getMintSeries,
  balances,
  history,
  collection_names,
}) => {
  const [formData, setFormData] = useState({
    collectionName: '',
    name: '',
    year: '',
    description: '',
    rights: null,
    tags: '',
    editions: '',
    notes: '',
    contentCategory: '',
  });
  const [agreed, setAgreed] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [nsfw, setNsfw] = useState(false);
  const [category, setCategory] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailName, setThumbnailName] = useState('');
  const [cid, setCid] = useState('');
  const [nftFile, setNftFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [progress, setProgress] = useState('');

  const {
    collectionName,
    name,
    year,
    description,
    rights,
    tags,
    editions,
    contentCategory,
    notes,
  } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onAgreementCheck = (e) => {
    let checkElem = document.getElementById(`${e.target.name}`);
    if (agreements.includes(e.target.value) && !checkElem.checked) {
      let updatedAgreements = agreements.filter(
        (agr) => agr !== e.target.value,
      );
      setAgreements([...updatedAgreements]);
      return;
    }
    setAgreements([...agreements, e.target.value]);
  };
  const handleThumbnailUpload = async (e) => {
    setLoading(true);
    const thumbn = e.target.files[0];
    const uploadData = await uploadFile(thumbn, 'thumbnail');
    if (!uploadData) {
      setToastNotification('Unsurported file type', 'error');
      setLoading(false);
      return;
    }
    setThumbnail(uploadData.data?.url);
    setThumbnailName(e.target.files[0].name);
    setProgress(uploadData.progress);
    setLoading(false);
    setToastNotification('Thumbnail Uploaded successful', 'success');
  };
  const handleFileUpload = async (e) => {
    if (contentCategory === '') {
      setToastNotification('Select content (NFT) type first', 'error');
      return;
    }
    setFileLoading(true);
    const thumbn = e.target.files;
    // console.log(e.target.files)
    let uploadData;
    if (e.target.files.length > 1) {
      uploadData = await uploadMultipleFiles(thumbn, 'file');
      console.log('multiple files');
    } else {
      console.log(thumbn[0]);
      uploadData = await uploadFile(thumbn[0], 'file');
      console.log('single file', uploadData);
    }
    if (!uploadData) {
      setToastNotification('Something went wrong with your upload', 'error');
      setFileLoading(false);
      return;
    }
    const allowedFanFileTypes = ['image/gif', 'image/png', 'image/jpeg'];
    if (
      contentCategory === 'fan' &&
      !allowedFanFileTypes.includes(e.target.files[0].type)
    ) {
      return setToastNotification(
        'File type not allowed for fan token',
        'error',
      );
    }
    if (e.target.files.length > 1) {
      let albumString = '';
      uploadData.data.url.forEach((file) => (albumString += file + ','));
      setNftFile(albumString);
      setCid(uploadData.data.cid);
    } else {
      setNftFile(uploadData.data?.url);
      setCid(uploadData.data?.cid);
    }
    setFileName(e.target.files[0].name);
    setProgress(uploadData.data.progress);
    setFileLoading(false);
    setToastNotification('Thumbnail Uploaded successful', 'success');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name === '') {
      setToastNotification('Name cannot be empty', 'error');
      return;
    }
    if (collectionName === '') {
      setToastNotification('Enter a collection name', 'error');
      return;
    }
    if (category === '') {
      setToastNotification('Please choose a category', 'error');
      return;
    }
    if (rights === null) {
      setToastNotification('Please choose a right', 'error');
      return;
    }
    if (editions === '') {
      setToastNotification('Please enter the number of editions', 'error');
      return;
    }
    if (nsfw === null) {
      setToastNotification('Please confirm NSFW', 'error');
      return;
    }
    if (contentCategory === '') {
      setToastNotification('Please choose either (Audio, Video, Fan)', 'error');
      return;
    }
    if (thumbnail === '') {
      setToastNotification('Please upload a thumbnail', 'error');
      return;
    }
    if (nftFile === '') {
      setToastNotification('Please upload a file', 'error');
      return;
    }
    if (description === '') {
      setToastNotification('Please description cannot be empty', 'error');
      return;
    }
    if (tags === '') {
      setToastNotification('Please enter at least one tag', 'error');
      return;
    }
    if (notes.length > 250) {
      setToastNotification(
        'Notes must not be greater than 250 characters',
        'error',
      );
      return;
    }
    if (agreements.length < 2) {
      setToastNotification('You must agree to all all terms', 'error');
      return;
    }
    if (!agreed) {
      setToastNotification('You must agree to all the terms', 'error');
      return;
    }
    if (editions > 1000 && artiste !== true) {
      setToastNotification(
        'Editions must be less than 1000 for non-exclusive members',
        'error',
      );
      return;
    }
    const payload = {
      action: 'mint',
      name: name,
      collection: collectionName,
      category: category,
      rights: rights,
      editions: editions,
      nsfw: nsfw,
      type: contentCategory,
      thumbnail: thumbnail,
      file: nftFile,
      notes: notes,
      year: year,
      tags: tags.split(' '),
      description: description,
      cid: cid,
    };

    // Do something keychain to broadcast transactions
    setMintLoading(true);
    createToken(payload, history);
    return;
  };
  useEffect(() => {
    getBalances(username, currency);
    getMintSeries();
  }, [getBalances, currency, username, getMintSeries, mintLoading]);
  return (
    <Fragment>
      <Layout>
        <Header title='Mint NFTs' button={false} />
        {mintLoading ? (
          <Loader />
        ) : (
          <section className='section__wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='info__text__wrapper'>
                    <p className='info__text text-center'>
                      Your current balance is{' '}
                      {balances.map((bal, index) => {
                        return balances.length > 0
                          ? parseFloat(balances[0].balance).toFixed(2)
                          : null;
                      })}{' '}
                      {currency}, which is enough to mint up to{' '}
                      {maxPossibleEditions(
                        balances.length > 0 && balances[0].balance,
                        token_issuance_base_fee,
                        token_issuance_fee,
                      ) < 0
                        ? 0
                        : maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          )}{' '}
                      editions. <br />
                      The first edition costs {token_issuance_base_fee}{' '}
                      {currency} and each additional costs {token_issuance_fee}{' '}
                      {currency}. Please check your balance now and gather some{' '}
                      {currency} on your wallet if you plan to issue more than{' '}
                      {maxPossibleEditions(
                        balances.length > 0 && balances[0].balance,
                        token_issuance_base_fee,
                        token_issuance_fee,
                      ) < 0
                        ? 0
                        : maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          )}{' '}
                      tokens.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                  <div className='mint__form__wrapper'>
                    <form className='mint__form' onSubmit={(e) => onSubmit(e)}>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                          <select
                            className='nfttunz__select w-100 text-center'
                            name='contentCategory'
                            id='contentCategory'
                            onChange={onChange}
                            defaultValue='Choose'
                          >
                            <option
                              value='Choose'
                              className='text-center'
                              disabled
                            >
                              Select Content Type
                            </option>
                            <option value='audio'>Audio</option>
                            <option value='video'>Video</option>
                            <option value='fan'>Fan</option>
                          </select>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <select
                            className='nfttunz__select w-100 text-center'
                            name='collectionName'
                            id='collectionName'
                            onChange={onChange}
                            defaultValue={''}
                          >
                            <option value={''} disabled className='text-center'>
                              Choose Existing Collection
                            </option>
                            <option value={name} className='text-center'>
                              {'New Collection'}
                            </option>
                            {collection_names.map((collection, index) => {
                              return (
                                <option key={index} value={collection}>
                                  {collection}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <input
                            value={collectionName}
                            name='collectionName'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='search'
                            placeholder='Collection Name*'
                            aria-label='Search'
                            title='Add ONly New Collection Names or Choose from list'
                            // disabled={collection_names.includes(
                            //   collectionName.toUpperCase(),
                            // )}
                          />
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <input
                            value={name}
                            name='name'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='search'
                            placeholder='Title*'
                            aria-label='Search'
                          />
                        </div>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <input
                            value={year}
                            name='year'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='text'
                            placeholder='Year*'
                            aria-label='year'
                          />
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div
                          className='nfttunz__input__wrapper nfttunz__file__wrapper'
                          style={{ position: 'relative' }}
                        >
                          {thumbnailName.length > 0 && (
                            <img
                              src={thumbnail}
                              alt='thumb'
                              className='thumbnail__preview'
                            />
                          )}
                          <div className='button__and__loader'>
                            <input
                              placeholder='Enter your password again'
                              className='steps__input hidden'
                              name='thumbnail'
                              id='thumbnail'
                              type='file'
                              onChange={handleThumbnailUpload}
                              style={{ display: 'none' }}
                            />
                            <label
                              className='custom-file-label upload__button'
                              htmlFor='thumbnail'
                            >
                              {thumbnailName?.length > 0
                                ? thumbnailName
                                : 'Choose thumbnail'}
                            </label>

                            {loading && (
                              <Fragment>
                                <UploadLoader size='' />
                                <strong>
                                  {progress ? `${progress}%` : '0%'}
                                </strong>
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className='nfttunz__input__wrapper nfttunz__file__wrapper'
                          style={{ position: 'relative' }}
                        >
                          {fileName.length > 0 && (
                            <img
                              src={thumbnail}
                              alt='thumb'
                              className='thumbnail__preview'
                            />
                          )}
                          <div className='button__and__loader'>
                            <input
                              placeholder='Enter your password again'
                              className='steps__input hidden'
                              name='nftFile'
                              id='nftFile'
                              type='file'
                              onChange={handleFileUpload}
                              multiple
                              style={{ display: 'none' }}
                            />
                            <label
                              className='custom-file-label upload__button'
                              htmlFor='nftFile'
                            >
                              {fileName?.length > 0 ? fileName : 'Choose file'}
                            </label>
                            {fileLoading && (
                              <Fragment>
                                <UploadLoader size='' />
                                <small>
                                  {progress ? `${progress}%` : '0%'}
                                </small>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-100 d-flex'>
                          <textarea
                            value={description}
                            name='description'
                            onChange={onChange}
                            className='nfttunz__textarea w-100'
                            type='search'
                            placeholder='Collection Description*'
                            aria-label='Search'
                            rows={6}
                          ></textarea>
                        </div>
                      </div>
                      {contentCategory === 'fan' && (
                        <>
                          <div className='double__input__row'>
                            <div className='nfttunz__input__wrapper nfttunz__input__border w-100 d-flex'>
                              <textarea
                                value={notes}
                                name='notes'
                                onChange={onChange}
                                className='nfttunz__textarea w-100'
                                type='search'
                                placeholder='Special Note to Fan*'
                                aria-label='notes'
                                rows={3}
                              ></textarea>
                            </div>
                          </div>
                          <small
                            className={`info__text text-small__text text__right d-block ml__auto margin__five ${
                              notes.length > 250 ? 'text-danger' : 'text-muted'
                            }`}
                          >
                            {250 - notes.length}{' '}
                            {notes.length > 250
                              ? 'characters'
                              : 'characters left'}
                          </small>
                        </>
                      )}
                      <>
                        <div className='double__input__row'>
                          <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                            <select
                              className='nfttunz__select w-100'
                              name='rights'
                              id='rights'
                              onChange={onChange}
                              defaultValue='Select rights'
                            >
                              <option value='Select rights' disabled>
                                Select production rights
                              </option>
                              <option value='1'>
                                Private(Only you can commercialize)
                              </option>
                              <option value='2'>
                                Limited Production (Earn Royalty on secondary
                                sales)
                              </option>
                              <option value='3'>
                                Public (You don't earn Royalty on secondary
                                sales)
                              </option>
                            </select>
                          </div>
                          <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                            <input
                              value={editions}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  editions: e.target.value,
                                })
                              }
                              className='nfttunz__input'
                              type='number'
                              placeholder='Number of editions*'
                              aria-label='editions'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div
                              style={{
                                fontSize: '8px',
                                marginLeft: '5px',
                                display: 'block',
                              }}
                            >
                              <small
                                className={`info__text text-small__text d-block ml__auto margin__five text-muted`}
                                style={{
                                  fontSize: '8px',
                                  marginLeft: '10px',
                                  display: 'block',
                                }}
                              >
                                Choose the rights you want to sell your
                                collection with.
                              </small>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div
                              className='double__input__wrapper d-flex justify-content-end'
                              style={{
                                marginLeft: 'auto',
                                paddingRight: '5px',
                                fontSize: '10px',
                                color: 'red',
                                marginBottom: '5px',
                              }}
                            >
                              {maxPossibleEditions(
                                balances.length > 0 && balances[0].balance,
                                token_issuance_base_fee,
                                token_issuance_fee,
                              ) > 0 && (
                                <span className='info__text text-muted text-small__text'>
                                  {' '}
                                  You can mint up to{' '}
                                  {maxPossibleEditions(
                                    balances.length > 0 && balances[0].balance,
                                    token_issuance_base_fee,
                                    token_issuance_fee,
                                  )}{' '}
                                  editions
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>

                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                          <select
                            className='nfttunz__select w-100'
                            name='nsfw'
                            id='nsfw'
                            onChange={(e) => setNsfw(e.target.value)}
                            defaultValue=''
                          >
                            <option value='' disabled>
                              Sensitive Content (NSFW)?
                            </option>
                            <option value={true}>
                              NSFW - Yes (Sensitive Content)
                            </option>
                            <option value={false}>
                              NSFW - No (No Sensitive Content)
                            </option>
                          </select>
                        </div>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                          <select
                            className='nfttunz__select w-100'
                            name='category'
                            id='category'
                            onChange={(e) => setCategory(e.target.value)}
                            defaultValue='select category'
                          >
                            <option value='select category' disabled>
                              Select Category
                            </option>
                            {categories?.map((cat, index) => {
                              return (
                                <option key={index} value={cat}>
                                  {cat}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='info__text__wrapper margin__five padding__ten'>
                          <p className='info__text small__text muted p-0'>
                            By selecting 'Private' the owner has no rights to
                            reproduce or use the work commercially. By selecting
                            'Limited Reproduction Rights' the artist grants the
                            owner full commercial rights for the work to be used
                            or recreated in commerce but does not give away the
                            creator's license.
                          </p>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-100'>
                          <input
                            value={tags}
                            name='tags'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='text'
                            placeholder='Enter tags* separated by space'
                            aria-label='Search'
                          />
                        </div>
                      </div>

                      {/* Agreements */}
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border nfttunz__checkbox__wrapper w-100'>
                          <input
                            value='violence'
                            name='violence'
                            id='violence'
                            onChange={onAgreementCheck}
                            className='nfttunz__checkbox'
                            type='checkbox'
                            aria-label='Search'
                          />
                          <label
                            className='checkbox__label small__text'
                            htmlFor='avatar'
                          >
                            Graphic violence of flesh and blood of humans has
                            been marked "NSFW".
                          </label>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border nfttunz__checkbox__wrapper w-100'>
                          <input
                            value='understand'
                            name='understand'
                            id='understand'
                            onChange={onAgreementCheck}
                            className='nfttunz__checkbox'
                            type='checkbox'
                            aria-label='Search'
                          />
                          <label
                            className='checkbox__label small__text'
                            htmlFor='avatar'
                          >
                            I understand that if I violate the Terms of Service
                            I may be blacklisted from the site.
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border nfttunz__checkbox__wrapper info__text__wrapper w-100'>
                          <input
                            value={agreed}
                            name='agreed'
                            id='agreed'
                            onChange={(e) => setAgreed(!agreed)}
                            className='nfttunz__checkbox'
                            type='checkbox'
                            aria-label='Search'
                          />
                          <label
                            className='checkbox__label small__text'
                            htmlFor='avatar'
                          >
                            I have read the TOS and I agree to only tokenize art
                            I created that does not infringe on any copyright.
                          </label>
                        </div>
                      </div>
                      {/* TODO: Prgrammatically calculate editions and fee from state */}
                      <strong className='margin__five'>
                        Total Issuance fee for {editions} edition(s):{' '}
                        {token_issuance_base_fee * editions} {currency}
                      </strong>
                      <br />
                      <br />
                      <input
                        data-toggle='tooltip'
                        data-placement='top'
                        title={
                          maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          ) < 0
                            ? 'You do not have sufficient balance'
                            : 'Mint NFTs'
                        }
                        type='submit'
                        value='Mint Tokens'
                        disabled={
                          maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          ) < 0
                            ? true
                            : false
                        }
                      />
                    </form>
                  </div>
                </div>
                <div className='col-md-2'></div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  token: state.token,
  settings: state.settings,
  username: state.users.username,
  artiste: state.users.artiste,
  balances: state.nfts.balances,
  collection_names: state.collectibles.collection_names,
});
export default connect(mapStateToProps, {
  createToken,
  getBalances,
  getMintSeries,
})(MintToken);
