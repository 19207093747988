import React, { Fragment, useState } from "react";
import { login } from "../actions/users";
import Footer from "./Footer";
import LoginModal from "./modals/LoginModal";
import Navbar from "./Navbar";
import { connect, useDispatch } from "react-redux";
import Loader from "./Loader";
import { SET_LOADING } from "../actions/types";
import { logout } from "../actions/users";
import { useDarkMode } from '../utils/themes/useDarkMode'
import { GlobalStyles, lightTheme, darkTheme} from '../utils/themes/theme'
import {ThemeProvider} from 'styled-components'
import AudioPlayer from 'react-h5-audio-player'
import ConfirmModal from "./modals/ConfirmModal";

const Layout = ({ children, auth, logout }) => {
  const [username, setUsername] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
   const [theme, toggleTheme] = useDarkMode();
   const themeMode = theme === 'light' ? lightTheme : darkTheme;
  const dispatch = useDispatch();
  const setUsernameField = (e) => {
    setUsername(e.target.value);
  };
  const refName = window.location.href.split('=')[1];
  const loginUser = () => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch(login(username, refName));
    setIsShow(false);
  };
  const hideModal = () => {
    setIsShow(false);
  };
  const hideConfirmModal = () => {
    setIsShowConfirm(false);
  };

  return (
    <Fragment>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <Navbar
          isShowLogIn={setIsShow}
          setIsShowConfirm={setIsShowConfirm}
          isShow={isShow}
          loading={loading}
          setLoading={setLoading}
          toggleTheme={toggleTheme}
          theme={theme}
        />
        {auth.loading ? <Loader /> : <main style={{minHeight: '78vh'}}>{children}</main>}
        <div className='player__wrapper'>
          <AudioPlayer />
        </div>
        <LoginModal
          show={isShow}
          handleClose={hideModal}
          username={username}
          setUsernameField={setUsernameField}
          loginUser={loginUser}
        />
        <ConfirmModal
          show={isShowConfirm}
          handleClose={hideConfirmModal}
          username={auth.username}
          logout={logout}
        />
        <Footer />
      </ThemeProvider>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  auth: state.users,
});
export default connect(mapStateToProps, {logout})(Layout);
