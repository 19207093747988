import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import {
  reportCollectible,
  processReportCollectible,
} from '../../../actions/admin';
import AdminCollectibleTable from '../../../components/AdminCollectibleTable';
import axios from '../../../utils/axios';

export const Collectibles = ({
  reportCollectible,
  processReportCollectible,
  username,
}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [collectibles, setCollectibles] = useState([]);
  const [reports, setReports] = useState([]);
  const getAdminCollectibles = async () => {
    setLocalLoading(true);
    const data = await axios.get('/admin/collectibles');
    setCollectibles(data.data.results);
    setLocalLoading(false);
  };
  const prefix = 'nfttunz';
  const getAdminReports = async () => {
    setLocalLoading(true);
    const data = await axios.get('/admin/reports');
    const reportsSeries = data.data.map(d => d.series)
    setReports(reportsSeries);
    setLocalLoading(false);
  };

  const handleReportCollectible = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_report_collectible`,
      key: 'Active',
      data,
      message: 'Report Collectible',
      eventName: 'REPORT_COLLECTIBLE_PROCESSED',
      emitData: data,
    };
    await reportCollectible(username, jsonData);
    // setLocalLoading(false);
  };
  const handleFeatureCollectible = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_feature_collectible`,
      key: 'Active',
      data,
      message: 'Feature Collectible',
      eventName: 'FEATURE_COLLECTIBLE_PROCESSED',
      emitData: data,
    };
    await reportCollectible(username, jsonData);
    // setLocalLoading(false);
  };
  const handleMangeCollectible = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_manage_collectible`,
      key: 'Active',
      data,
      message: 'Feature Collectible',
      eventName: 'FEATURE_COLLECTIBLE_PROCESSED',
      emitData: data,
    };
    await reportCollectible(username, jsonData);
    // setLocalLoading(false);
  };
  const handleProcessReportCollectible = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_process_report`,
      key: 'Active',
      data,
      message: 'Process Report',
      eventName: 'PROCESS_REPORT_PROCESSED',
      emitData: data,
    };
    await processReportCollectible(username, jsonData);
    // setLocalLoading(false);
  };

  useEffect(() => {
    getAdminCollectibles();
    getAdminReports()
  }, []);
  return (
    <Fragment>
      <div className='container'>
        <div className='row bg__white'>
          <div className='col-md-12'>
            {localLoading ? (
              <Loader />
            ) : collectibles.length < 1 ? (
              <h4 className='text-center p-3 m-0'>
                Oops! No data or collectibles at the moment...
              </h4>
            ) : (
              <AdminCollectibleTable
                data={collectibles}
                type='collectibles'
                handleReportCollectible={handleReportCollectible}
                handleFeatureCollectible={handleFeatureCollectible}
                handleProcessReportCollectible={handleProcessReportCollectible}
                handleManageCollectible={handleMangeCollectible}
                reports={reports}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
  username: state.users.username,
});
export default connect(mapStateToProps, {
  reportCollectible,
  processReportCollectible,
})(Collectibles);
