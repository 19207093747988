import React from 'react'

const BannerImage = () => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <img
          className='rounded__corners banner__image d-block mx-auto'
          src='https://cdn.discordapp.com/attachments/897101784076668969/972234383249518612/Twitter_Header_2.png'
          alt=''
        />
      </div>
    </div>
  );
}

export default BannerImage