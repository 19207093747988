import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal'
import AudioPlayer from 'react-h5-audio-player';
import { Link } from 'react-router-dom';
import VideoPlayer from 'react-video-markers';


const MediaPlayer = ({ handleShowPlayer, show, file, thumbnail, type, title, creator, setFile, series }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const handleProgress = (e) => {
    // console.log('Current time: ', e.target.currentTime);
  };

  const handleDuration = (duration) => {
    console.log('Duration: ', duration);
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVolume = (value) => {
    setVolume(value);
  };
  
  useEffect(() => {
    return setIsPlaying(false);
  }, [setIsPlaying]);
  return (
    <div className='modal-parent'>
      <Modal
        visible={show}
        width='1200'
        height='760'
        effect='fadeInUp'
        onClickAway={() => handleShowPlayer()}
      >
        <div className=''>
          <div className='modal__header'>
            <div className='d-flex align-items-end'>
              <img alt='file-icon' src={thumbnail} width={80} />
              <div className='player__description ms-2'>
                <p className='modal-title m-0 text-muted mb-0'>Now Playing</p>
                <Link to={`/market/${series}`}>
                  <h4 className='m-0'>{title}</h4>
                </Link>
                <p className='m-0'>
                  <small className='text-muted'>by</small>{' '}
                  <strong>{creator}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className='modal__body p-0'>
            <div
              className={`file__player mt-1 ${
                (type === 'audio' && 'w-100') || (type === 'fan' && 'w-100')
              }`}
            >
              {type === 'audio' ? (
                <div style={{ width: '100%' }}>
                  <div className='modal__audio__thumbnail'>
                    <div
                      className='audio__thumbnail__blurred__background'
                      style={{
                        backgroundImage: `url(${thumbnail})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                    <div className='audio__thumbnail__blurred__overlay'></div>
                    <img
                      src={thumbnail}
                      alt='thumbnail'
                      width='100%'
                      height='350px'
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <AudioPlayer
                    autoPlay
                    src={file}
                    onPlay={(e) => console.log('onPlay')}
                    onAbort={handlePause}
                    // other props here
                  />
                </div>
              ) : type === 'fan' ? (
                <div style={{ width: '100%' }}>
                  <div className='modal__audio__thumbnail fan'>
                    <div
                      className='audio__thumbnail__blurred__background'
                      style={{
                        backgroundImage: `url(${thumbnail})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                    <div className='audio__thumbnail__blurred__overlay'></div>
                    <img
                      className='fan__thumbnail'
                      src={file}
                      alt='thumbnail'
                      width='100%'
                      height='450px'
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
              ) : (
                <VideoPlayer
                  url={file}
                  isPlaying={isPlaying}
                  volume={volume}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  onVolume={handleVolume}
                  onProgress={handleProgress}
                  onDuration={handleDuration}
                  width='950%'
                  height='950%'
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MediaPlayer;
