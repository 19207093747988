import { useRef } from 'react';
import Modal from 'react-awesome-modal';
import '../../assets/styles/modal.css';

const LoginModal = ({
  handleClose,
  show,
  children,
  username,
  setUsernameField,
  loginUser,
}) => {
  const submitRef = useRef()
  return (
    <div className='modal-parent'>
      <Modal
        visible={show}
        width='500'
        height='250'
        effect='fadeInUp'
        onClickAway={() => handleClose()}
      >
        {(
          <div className=''>
            <div className='modal__header'>
              <h3>Login</h3>
            </div>
            <div className='modal__body'>
              <form onSubmit={(e) =>{
                e.preventDefault();
                loginUser(username)
                }}>
                <div className='mt-3 mb-2'>
                  <input
                    type='text'
                    className='form-control modal__input'
                    id='recipient-name'
                    name='username'
                    placeholder='Enter your username'
                    value={username}
                    onChange={setUsernameField}
                    // onKeyUp={(e) => {
                    //   e.preventDefault();
                    //   if (e.key === 'Enter') {
                    //     submitRef.current.click();
                    //   }
                    // }}
                  />
                </div>
              </form>
            </div>

            <div className='modal__footer'>
              <button
                type='button'
                className='modal__button'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                Close
              </button>
              <button
                onClick={loginUser}
                type='button'
                ref={submitRef}
                className='modal__button'
              >
                Login User
              </button>
            </div>
          </div>
        ) || { children }}
      </Modal>
    </div>
  );
};

export default LoginModal;
