import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from '../../../utils/axios';
import Loader from '../../../components/Loader';
import AdminTable from '../../../components/AdminTable';
import { banUser } from '../../../actions/admin';

const AdminUsers = ({ settings: { prefix }, banUser, username }) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const getAdminUsers = async () => {
    setLocalLoading(true);
    const data = await axios.get('/admin/users');
    setUsers(data.data.results);
    setLocalLoading(false);
  };

  const handleBanUser = async (data) => {
    setLocalLoading(true);
    const jsonData = {
      id: `${prefix}_manage_user`,
      key: 'Active',
      data,
      message: 'Manage User',
      eventName: 'MANAGE_USER_PROCESSED',
      emitData: data,
    };
    banUser(username, jsonData);
    // setLocalLoading(false);
  };

  useEffect(() => {
    getAdminUsers();
  }, []);
  return (
    <Fragment>
      <div className='container'>
        <div className='row bg__white'>
          <div className='col-md-12'>
            {localLoading === true ? (
              <Loader />
            ) : users.length < 1 ? (
              <h4 className='text-center p-3 m-0'>
                Oops! No data or users at the moment...
              </h4>
            ) : (
              <AdminTable
                data={users}
                type='users'
                handleBanUser={handleBanUser}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
  username: state.users.username,
});
export default connect(mapStateToProps, { banUser })(AdminUsers);
