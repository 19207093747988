import React, { Fragment, useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from '../utils/axios';
import { connect } from 'react-redux';
import { initAccountKey } from '../actions/account';
import tinyLoading from '../assets/images/tiny__loading.gif';
import registerLoader from '../assets/images/register__loader.gif';
import {
  copyToClipboard,
  setToastNotification,
  toFixedWithoutRounding,
} from '../utils/helpers';
import PaypalAccountCreation from '../components/PaypalAccountCreation';
import { startPurchase, validatePaypalPurchase } from '../actions/payments';
import { Redirect } from 'react-router-dom';
import MetaDecorator from '../components/utils/MetaDecorator';
import { ACCOUNT_CREATION_FEE} from '../utils/constants';

const Register = ({
  master_password,
  usd_price,
  payment_info,
  auth,
  initAccountKey,
  startPurchase,
  validatePaypalPurchase,
  match,
}) => {
  const [username, setUsername] = useState('');
  const [disableUsername, setDisableUsername] = useState(false);
  const [email, setEmail] = useState('');
  const [available, setAvailable] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);
  const [backup, setBackup] = useState(false);
  const [refIsValid, setRefIsValid] = useState(false);
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  const { ref } = match.params;

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/account/create`,
        {
          username: username.toLocaleLowerCase().trim(),
          email: email.trim(),
          master_pass: master_password,
          uid: payment_info?.uid,
          referred_by: ref,
        },
        options,
      );
      if (response.data.results.status !== 'success') {
        setToastNotification('Account creation failed', 'info');
      }
      setToastNotification('Account creation successful', 'success');
      setLoading(false);
      setPaymentMade(false);
      setUsername('');
      setEmail('');
    } catch (err) {
      console.log(err.message);
      setToastNotification(
        'Something went wrong, kindly make sure you paid successfully',
        'info',
      );
      setLoading(false);
      setPaymentMade(false);
    }
  };
  const handleDacterSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/account/create-dacter-account`,
        {
          username: username.toLocaleLowerCase().trim(),
          email: email.trim(),
          master_pass: master_password,
          uid: payment_info?.uid,
          referred_by: ref,
        },
        options,
      );
      if (response.data.results.status !== 'success') {
        setToastNotification('Account creation failed', 'info');
      }
      setToastNotification('Account creation successful', 'success');
      setLoading(false);
      setPaymentMade(false);
      setUsername('');
      setEmail('');
    } catch (err) {
      console.log(err.message);
      setToastNotification(
        'Something went wrong, kindly make sure you paid successfully',
        'info',
      );
      setLoading(false);
      setPaymentMade(false);
    }
  };
  const handleValidateAccount = async (username) => {
    try {
      setAvailableLoading(true);
      const account = await axios.get(`/account/validate?username=${username}`);
      setAvailable(account.data.chain_user === 'existing' ? false : true);
      setAvailableLoading(false);
      return account.data;
    } catch (err) {
      console.error(err.message);
    }
  };
  const getKeys = async () => {
    try {
      setKeyLoading(true);
      const data = await axios.get(
        `account/get-keys?username=${username}&master_pass=${master_password}`,
      );
      const element = document.createElement('a');
      const keysToFile = `
Please be very careful with your password & private keys.
If you lose your password, you will not be able to access your account.
Nobody can help you recover the password!

Our recommendation:
1. Print this file out multiple times and store it securely in multiple locations.
2. NEVER use your password/owner key unless it's absolutely required.
3. Save your Active + Posting + Memo keys within a password manager and/or browser extension/mobile wallet, as you will need them frequently.
4. Delete this file once you've done all of the steps before.

Your Hive Account
Username: ${username}
Password: ${master_password}

Owner: ${data.data.keys.owner}

Active: ${data.data.keys.active}

Posting: ${data.data.keys.posting}

Memo: ${data.data.keys.memo}

Active Public Key: ${data.data.keys.activePubkey}

Posting Public Key: ${data.data.keys.postingPubkey}

Owner Public Key: ${data.data.keys.ownerPubkey}

Memo Public Key: ${data.data.keys.memoPubkey}


What are those keys used for?

Owner:   Change Password, Change Keys, Recover Account
Active:  Transfer Funds, Power up/down, Voting Witnesses/Proposals
Posting: Post, Comment, Vote, Reblog, Follow, Profile
Memo:    Send/View encrypted messages on transfers
      `;
      const file = new Blob([keysToFile.replace(/\n/g, '\r\n')], {
        type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${username}_hive_keys.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      setBackup(true);
      setKeyLoading(false);
      setDisableUsername(true);
      return data.data;
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    initAccountKey();
    axios
      .get(`/account/get-discount?promo_code=${ref}`)
      .then((res) => {
        setRefIsValid(res.data.valid);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [initAccountKey, ref]);
  if (auth) {
    return <Redirect to='/profile' />;
  }
  return (
    <Fragment>
      <MetaDecorator
        title='Register'
        description='Share and Create amazing musical collections'
        imageUrl='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
        imageAlt='https://app.nfttunz.io/static/media/TUNZ_Token.d96cb795.png'
      />
      <Layout>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 col-xs-12 col-sm-12'>
              <h1 className='my-3 text-center'>Register</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <div className='register__card__wrapper m-auto w-100'>
                <div
                  className={`register__loading__container ${
                    loading ? 'show' : 'hide'
                  }`}
                >
                  <img src={registerLoader} alt='loading...' />
                </div>
                <div className='register__info__container'>
                  <div className='register__fee__wrapper'>
                    <h6 className='register__fee text-center'>
                      Registration costs
                        {' '}3 HIVE{' '} or {' '}
                      <span className='text-underline'>
                        $
                        {toFixedWithoutRounding(
                          usd_price * ACCOUNT_CREATION_FEE,
                        )}
                      </span>{' '}
                      per Hive account
                    </h6>
                  </div>
                  <p className='register__info__text text-center'>
                    <strong>Please read carefully</strong> TUNZ runs on the HIVE
                    blockchain with every new user requiring a HIVE account.
                    This explains why you are paying a small fee for an account
                    which will allow you access to the entire HIVE ecosystem. If
                    you have any questions about this, please contact{' '}
                    <a
                      className='register__support__email'
                      href='mailto:billing@nfttunz.io'
                    >
                      billing@nfttunz.io
                    </a>
                  </p>
                </div>
                <form className='w-75 register__form__container'>
                  <div className='register__form'>
                    <label htmlFor='exampleInputEmail1'>Username</label>
                    <input
                      disabled={disableUsername}
                      type='text'
                      className='form-control nfttunz__navbar__search__wrapper'
                      placeholder='Enter username'
                      id='username'
                      value={username.trim()}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        handleValidateAccount(e.target.value);
                      }}
                    />
                    {availableLoading ? (
                      <img src={tinyLoading} alt='loading...' />
                    ) : !available && username.length > 0 ? (
                      <small id='emailHelp' className='form-text text-danger'>
                        User already exists!
                      </small>
                    ) : (
                      <small
                        id='emailHelp'
                        className='form-text register__info__text'
                      >
                        This will be your username
                      </small>
                    )}
                  </div>

                  <div className='register__form'>
                    <label htmlFor='exampleInputEmail1'>Email address</label>
                    <input
                      type='email'
                      className='form-control nfttunz__navbar__search__wrapper'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <small
                      id='emailHelp'
                      className='form-text register__info__text'
                    >
                      We\&apos'll never share your email with anyone else.
                    </small>
                  </div>

                  <div className='register__form'>
                    <p
                      onClick={() => copyToClipboard(master_password)}
                      className='password__container'
                    >
                      {master_password} <i className='fa fa-clipboard'></i>
                    </p>
                    <p
                      id='emailHelp'
                      className='form-text register__info__text text-warning'
                    >
                      <i className='fas fa-exclamation-triangle'></i> Please
                      copy your master password and/or download your keys. Also
                      understand that a new password is generated on every page
                      refresh.
                    </p>
                  </div>
                </form>
                <button
                  disabled={!available || username.trim().length < 3}
                  className='btn btn-primary register__button'
                  onClick={() => getKeys()}
                >
                  Download Keys{' '}
                  {keyLoading ? (
                    <i className='fa fa-spinner fa-spin fa-fw'></i>
                  ) : (
                    <i className='fa fa-download'></i>
                  )}
                </button>
                {refIsValid === false ? (
                  paymentMade === false ? (
                    backup &&
                    available === true && (
                      <div className={available ? 'show' : 'hide'}>
                        <PaypalAccountCreation
                          startPurchase={startPurchase}
                          validatePaypalPurchase={validatePaypalPurchase}
                          setPaymentMade={setPaymentMade}
                          username={username}
                          price={usd_price * ACCOUNT_CREATION_FEE}
                        />
                      </div>
                    )
                  ) : (
                    <button
                      className='btn btn-primary register__button'
                      onClick={() => {
                        // handleSubmit();
                        handleDacterSubmit();
                      }}
                    >
                      Submit
                    </button>
                  )
                ) : (
                  backup && (
                    <button
                      className='btn btn-primary register__button'
                      onClick={() => handleSubmit()}
                    >
                      Claim Fan Account
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  master_password: state.account.master_password,
  usd_price: state.market.usd_price,
  payment_info: state.payments.payment_validation,
  auth: state.users.authenticated,
});

export default connect(mapStateToProps, {
  initAccountKey,
  startPurchase,
  validatePaypalPurchase,
})(Register);
