import { Fragment } from 'react';

const AdminTable = ({ data, type, handleBanUser }) => {
  return (
    <Fragment>
      <table className='table nfttunz__table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Username</th>
            <th scope='col' className='w-50'>
              Bio
            </th>
            <th scope='col'>Location</th>
            <th scope='col'>Socials</th>
            <th scope='col'>Banned</th>
            <th scope='col'></th>
            {type !== 'users' && (
              <th className='text-center' scope='col'>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={d.username}>
              <th scope='row'>{index + 1}</th>
              <td>{d.username}</td>
              <td>{d.bio}</td>
              <td>{d.location}</td>
              <td>
                {d.facebook || d.twitter || d.soundcloud ? (
                  <span className='flex justify-content-between'>
                    <a
                      href={d.facebook}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-facebook'></i>
                    </a>{' '}
                    <a
                      href={d.twitter}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-twitter'></i>
                    </a>{' '}
                    <a
                      href={d.soundcloud}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-soundcloud'></i>
                    </a>
                  </span>
                ) : (
                  <span>No social info</span>
                )}
              </td>
              <td>{d.banned === false ? 'false' : 'true'}</td>
              <td className='text-center'>
                {d.banned ? (
                  <button
                    className='btn btn-success py-1'
                    style={{ fontSize: '11px' }}
                    onClick={() =>
                      handleBanUser({
                        ban: false,
                        ban_reason: 'Ban issue resolved',
                        username: d.username,
                      })
                    }
                  >
                    unban
                  </button>
                ) : (
                  <button
                    className='btn btn-danger py-1'
                    style={{ fontSize: '11px' }}
                    onClick={() =>
                      handleBanUser({
                        ban: true,
                        ban_reason: 'Plagiarism of content',
                        username: d.username,
                      })
                    }
                  >
                    ban
                  </button>
                )}
              </td>
              {/* {type !== 'users' && (
                <td>
                  <div className='flex justify-content-end text-center'>
                    <button
                      className='btn btn-success btn-sm mx-2'
                      onClick={() =>
                        approveWhitelist(d.username, 'approve', true)
                      }
                    >
                      approve
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() =>
                        approveWhitelist(d.username, 'pending', true)
                      }
                    >
                      pending
                    </button>
                  </div>
                </td>
              )} */}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default AdminTable;
