import React, { Fragment } from 'react';
import Moment from 'react-moment';
import {connect} from 'react-redux';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import UploadLoader from './UploadLoader';

const AdminTransactionTable = ({ data, setPage, page, transaction_length }) => {
  //   const fetchMoreData = () => {
  //     const newPage = page + 1;
  //     setTimeout(() => {
  //       setPage(newPage);
  //     }, 1500);
  //   };
  return (
    <Fragment>
      <table className='table nfttunz__table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Date</th>
            <th scope='col'>Account</th>
            <th scope='col' className='w-'>
              Trx_ID
            </th>
            <th scope='col'>Type</th>
            <th scope='col'>Chain Block</th>
            <th scope='col'>Sidechain Block</th>
            <th scope='col'>Counterparty</th>

            <th className='text-center' scope='col'>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <InfiniteScroll
            className='w-100'
            dataLength={data.length}
            next={() => fetchMoreData(page)}
            hasMore={data.length < 200 ? true : false}
            loader={
              <h4 className='text-center mt-4'>
                <UploadLoader />
              </h4>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yess! You have seen it all 😁</b>
              </p>
            }
          > */}
          {data?.map((d, index) => (
            <tr key={index}>
              <th scope='row'>{index + 1}</th>
              <th scope='row'>
                <Moment fromNow>{d.timestamp}</Moment>
              </th>
              <td>{d.account}</td>
              <td>{d.trx_id}</td>
              <td>{d.type}</td>
              <td>{d.chain_block}</td>
              <td>{d.sidechain_block}</td>
              <td>{d.counterparty}</td>
              <td>
                <div className='flex justify-content-end text-center'>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    className='btn btn-success btn-sm mx-2'
                    href={`https://he.dtools.dev/tx/${d.trx_id}`}
                  >
                    view on sidechain
                  </a>
                </div>
              </td>
            </tr>
          ))}
          {/* </InfiniteScroll> */}
        </tbody>
      </table>
      <hr />
      <div className='text-center'>
        <button
        disabled={data.length === transaction_length}
          className='text-center btn btn-primary btn-sm'
          style={{ fontSize: '11px' }}
          onClick={() => setPage(page + 1)}
        >
          more...
        </button>
      </div>
      <br />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
    transaction_length: state.market.transaction_length,
});
export default connect(mapStateToProps, {})(AdminTransactionTable);
