import React, { Fragment } from 'react';
import logo from '../assets/images/TUNZ_Token.png';

const Footer = () => {
  return (
    <Fragment>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='footer__wrapper text-center'>
              <div className='footer__logo'>
                <img src={logo} width={30} alt='tunz token' />
                <div className='footer__copyright'>
                  <p>© NFTTUNZ, 2022.</p>
                </div>
              </div>
              <div className='footer__social__icons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://www.instagram.com/_tunz/'
                >
                  <i className='fab fa-instagram' /> <span>Instagram</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://twitter.com/nft_tunz'
                >
                  <i className='fab fa-twitter' /> <span>Twitter</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://discord.gg/bzNyKVrtbK'
                >
                  <i className='fab fa-discord' /> <span>Discord</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://peakd.com/@nfttunz'
                >
                  <i className='fab fa-soundcloud' /> <span>Peakd</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
