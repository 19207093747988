import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getArtisteProfile } from '../actions/profile';
import { followUser, getFollowers, getFollowing } from '../actions/users';
import { copyToClipboard } from '../utils/helpers';

const ArtisteProfileHeader = ({
  artiste_profile,
  getArtisteProfile,
  followUser,
  following,
  param,
  history,
  authenticated,
  user,
  getFollowers,
  getFollowing,
  artiste_followers,
}) => {
  useEffect(() => {
    getArtisteProfile(param);
    getFollowers(param);
    getFollowing(param);
  }, [getArtisteProfile, param, getFollowers, getFollowing]);
  return (
    <Fragment>
      <section
        style={{
          marginBottom: '0px',
          backgroundImage: `url(${
            artiste_profile?.cover_image ||
            'https://images.pexels.com/photos/2111015/pexels-photo-2111015.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
          })`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '88vh',
          display: 'flex',
          position: 'relative',
        }}
      >
        <div className='nfttunz__profile__header__overlay__2'></div>
        <section
          className='nfttunz__artiste__main__header'
          style={{
            marginBottom: '0px',
          }}
        ></section>
        <div className='profile__header__card'>
          <div className='profile__header__card__img__wrapper'>
            <img
              className='profile__header__card__image'
              src={
                artiste_profile?.avatar
                  ? artiste_profile?.avatar
                  : 'https://cdn.pixabay.com/photo/2016/05/24/22/54/icon-1413583_960_720.png'
              }
              alt='user avatar'
              width={100}
            />
          </div>
          <div className='profile__header__info__wrapper'>
            <h1>
              {artiste_profile?.full_name
                ? artiste_profile?.full_name
                : 'TUNZ User'}
              <sup
                className='ms-1'
                data-toggle='tooltip'
                data-placement='top'
                title='Whitelisted'
              >
                <i
                  style={{
                    color: `${
                      artiste_profile?.artiste === true
                        ? '#63A6FF'
                        : 'lightblue'
                    }`,
                  }}
                  className='fas fa-certificate'
                ></i>
              </sup>
            </h1>

            <small>
              <i className='fa fa-map-marker'></i>{' '}
              {artiste_profile?.location
                ? artiste_profile?.location
                : 'nfttunz'}
            </small>
            <p>
              {artiste_profile?.bio
                ? artiste_profile?.bio
                : 'No bio written yet...'}
            </p>
          </div>
          <div className='follow__social__icons'>
            <div className='profile__header__icons text-center'>
              <a
                href={artiste_profile?.twitter}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-twitter'></i>
              </a>
              <a
                href={artiste_profile?.instagram}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-instagram'></i>
              </a>
              <a
                href={artiste_profile?.soundcloud}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-soundcloud'></i>
              </a>
              <a
                href={artiste_profile?.website}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fas fa-globe'></i>
              </a>
            </div>
            <div className='text-center'>
              {user !== param &&
                (following.includes(param) ? (
                  <button
                    disabled={authenticated === false ? true : false}
                    className='btn btn-primary btn-sm text-center follow__button'
                    onClick={() => {
                      followUser(param, false, history);
                      window.location.reload();
                    }}
                  >
                    unfollow
                  </button>
                ) : (
                  <button
                    disabled={authenticated === false ? true : false}
                    className='btn btn-primary btn-sm text-center follow__button'
                    onClick={() => {
                      followUser(param, true, history);
                      window.location.reload();
                    }}
                  >
                    follow
                  </button>
                ))}
            </div>
          </div>
          <div className='profile__followers__wrapper'>
            <span className='profile__header__followers text-center'>
              {artiste_profile?.following?.length || 0}
              <strong>
                <i className='fa fa-users' aria-hidden='true'></i>
                Following
              </strong>
            </span>
            <span className='profile__header__followers text-center'>
              {artiste_followers?.length || 0}
              <strong>
                <i className='fa fa-users' aria-hidden='true'></i>
                Followers
              </strong>
            </span>
          </div>
          <button
            className='btn btn-primary btn-sm text-center ml-2 mt-3 follow__button share__button'
            onClick={() =>
              copyToClipboard(
                `https://market.nfttunz.io/artiste/${artiste_profile?.username}`,
              )
            }
          >
            <svg
              width={20}
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
                clipRule='evenodd'
              />
            </svg>{' '}
            Share Profile
          </button>
        </div>
      </section>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  artiste_profile: state.profiles.artiste_profile,
  artiste_followers: state.profiles.artiste_followers,
  following: state.users.following,
  authenticated: state.users.authenticated,
  user: state.users.username,
});
export default connect(mapStateToProps, {
  getArtisteProfile,
  followUser,
  getFollowers,
  getFollowing,
})(ArtisteProfileHeader);
