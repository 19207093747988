import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { connect } from 'react-redux';
import { removeFromCart } from '../actions/cart';
import { buyToken } from '../actions/token';
import { getBalances } from '../actions/nfts';
import BuyCredits from '../components/modals/BuyCredits';
import { toFixedWithoutRounding } from '../utils/helpers';
// import { startPurchase, validatePaypalPurchase } from '../actions/payments';

const Checkout = ({
  cart,
  total,
  usd_price,
  removeFromCart,
  buyToken,
  getBalances,
  balances,
  username,
  currency,
  startPurchase,
  validatePaypalPurchase,
}) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState('1000');
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    getBalances(username, currency);
  }, [getBalances, username, currency]);
  return (
    <Fragment>
      <Layout>
        <div className='section__wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-9 offset-2'>
                <div className='cart__header__wrapper'>
                  <h2 className='cart__header__title'>Checkout</h2>
                  <p>
                    Kindly review your choices before checking out. Don't
                    forget to check the market screen for some amazing FUSIC
                    stuffs...
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-2'></div>
              <div className='col-md-6'>
                <div className='cart__item__card__left'>
                  {cart?.length > 0 ? (
                    cart.map((c) => (
                      <div key={c.nft_id} className='cart__item__card'>
                        <div className='row'>
                          <div className='col-2'>
                            <img
                              className='cart__image__preview'
                              src={c?.thumbnail}
                              alt='cart__preview'
                            />
                          </div>
                          <div className='col-10'>
                            <div className='cart__inner__wrapper'>
                              <div className='cart__item__title'>
                                <h2>{c?.name}</h2>
                              </div>
                              <div className='cart__item__body'>
                                {c?.description}
                                <ul className='cart__item__features'>
                                  <li>
                                    <strong>Edition</strong> {c.nft_id}
                                  </li>
                                  <li>
                                    <strong>Price</strong> {c.price} {c.symbol}
                                  </li>
                                  <li>
                                    <strong>Series</strong> {c.series}
                                  </li>
                                  <li>
                                    <strong>Creator</strong> {c.account}
                                  </li>
                                </ul>
                              </div>
                              <div className='cart__item__footer'>
                                <button
                                  className='btn-danger'
                                  onClick={() => removeFromCart(c.nft_id)}
                                >
                                  remove
                                </button>
                                {/* <button>favorite</button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='empty__cart__info__wrapper'>
                      <h4>There's nothing here yet...</h4>
                      <p>
                        There's nothing in your basket yet so why not look
                        around the market for some new Tunz!
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-md-3'>
                <div className='cart__item__card'>
                  <h3 className='cart__preview__title'>Checkout Preview</h3>
                  <p>
                    Subtotal of
                    <strong> {cart?.length} Item(s)</strong>
                    <br />
                    <strong className='text-warning'>
                      {toFixedWithoutRounding(total)} {currency}
                    </strong>
                    <br />
                    <strong>OR</strong>
                    <br />
                    <strong>${toFixedWithoutRounding(total * usd_price)}</strong>
                  </p>
                  <br />
                  <p>
                    {' '}
                    <span>
                      Balance: {toFixedWithoutRounding(balances[0]?.balance)}
                    </span>
                  </p>
                  {balances[0]?.balance < total && (
                    <>
                      <strong
                        className='text-muted'
                        style={{ fontSize: '10px', color: 'red' }}
                      >
                        Oops! You do not have enough {currency} to make this
                        purchase
                      </strong>
                      <br />
                    </>
                  )}
                  <button
                    className='mt-2 mb-2 d-block checkout__button'
                    disabled={
                      balances[0]?.balance < total || total === 0 ? true : false
                    }
                    onClick={() =>
                      buyToken(
                        total,
                        cart.map((c) => c.nft_id.toString()),
                      )
                    }
                  >
                    Checkout
                  </button>
                  {/* <button
                    className='btn btn-primary mt-2 mb-2 d-block'
                    disabled={
                      balances[0]?.balance < total || total === 0 ? true : false
                    }
                    onClick={() =>
                      buyToken(
                        total,
                        cart.map((c) => c.nft_id.toString()),
                      )
                    }
                  >
                    Paypal Checkout
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BuyCredits
          isShow={isShow}
          setIsShow={setIsShow}
          setQuantity={setQuantity}
          quantity={quantity}
          setLoading={setLoading}
          isLoading={loading}
          startPurchase={startPurchase}
          validatePaypalPurchase={validatePaypalPurchase}
          username={username}
        />
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  total: state.cart.total,
  usd_price: state.market.usd_price,
  balances: state.nfts.balances,
  username: state.users.username,
  currency: state.settings.currency,
});
export default connect(mapStateToProps, {
  removeFromCart,
  buyToken,
  getBalances,
})(Checkout);
