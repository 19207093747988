import React, {  useState } from 'react';
import Modal from 'react-awesome-modal'
import logo from '../../assets/images/logo_gray_scale.png';

const AirdropModal = ({
  isShow,
  series_strings,
  setIsShow,
  song,
  setSong,
  memo,
  setMemo,
  handleAirdrop,
}) => {
  const [disableSeriesField, setdisableSeriesField] = useState(false);

  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='250'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-dark'>
              <img src={logo} alt='modal-header' width={40} />
              <button
                type='button'
                className='btn-close bg-white'
                onClick={() => {
                  setIsShow(false);
                  setSong('');
                }}
              ></button>
            </div>
            <div className='modal__body'>
              <p className='my-2 mb-3 text-dark'>
                Enter the price you are intending to sell this edition for, you
                can always modify the price at a future time.
              </p>
              <div>
                <select
                  name='series'
                  id='series'
                  onChange={(e) => {
                    setdisableSeriesField(true);
                    setSong(e.target.value);
                  }}
                  className='w-100 mb-2'
                >
                  <option value=''>Select Series</option>
                  {series_strings?.map((series, i) => {
                    return (
                      <option key={i} value={series}>
                        {series}
                      </option>
                    );
                  })}
                </select>
                <div className='d-flex border-1 custom-input-series-div'>
                  <input
                    disabled={disableSeriesField}
                    value={song}
                    onChange={(e) => setSong(e.target.value)}
                    className='w-100 border-0'
                    type='text'
                    placeholder='Enter a song series...'
                  />
                  <button
                    onClick={() => setdisableSeriesField(false)}
                    className='btn btn-sm btn-warning'
                  >
                    manual
                  </button>
                </div>
                <input
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  className='w-100 mt-2'
                  type='text'
                  placeholder='Enter memo (optional)...'
                />
              </div>
            </div>
            <div className='modal__footer'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => {
                  setIsShow(false);
                  setSong('');
                  setMemo('');
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  handleAirdrop();
                  setSong('');
                  setMemo('');
                  setIsShow(false);
                }}
              >
                Airdrop NFT
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AirdropModal;
