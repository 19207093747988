import React from 'react';
import Modal from 'react-awesome-modal';
import { toFixedWithoutRounding } from '../../utils/helpers';

const AuctionModal = ({
  isShow,
  setIsShow,
  setFinalPrice,
  finalPrice,
  setMinBid,
  minBid,
  setAuctionExpiry,
  auctionExpiry,
  instance,
  createAuction,
  setLoading,
  usd_price,
}) => {
  const auctionDates = [
    { text: '24 Hours', value: 1440 },
    { text: '2 Days', value: 1440 * 3 },
    { text: '7 Days', value: 1440 * 7 },
    { text: '15 Days', value: 1440 * 15 },
    { text: '30 Days', value: 1440 * 30 },
  ];
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='370'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Auction NFT</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mt-1'>Set the parameters to Auction this NFT.</p>
            <div>
              <input
                value={minBid}
                onChange={(e) => setMinBid(e.target.value)}
                className='w-100 modal__input'
                type='text'
                placeholder='Enter minimum bid...'
              />
              <p className='modal__currency__estimate'>
                {minBid} SWAP.HIVE <span className='text-danger'>=</span> $
                {toFixedWithoutRounding(minBid * usd_price)}
              </p>
              <input
                value={finalPrice}
                onChange={(e) => setFinalPrice(e.target.value)}
                className='w-100 modal__input'
                type='text'
                placeholder='Enter final price...'
              />
              <p className='modal__currency__estimate'>
                {finalPrice} SWAP.HIVE <span className='text-danger'>=</span> $
                {toFixedWithoutRounding(finalPrice * usd_price)}
              </p>
              <select
                className='crypto__select w-100'
                name='category'
                id='category'
                onChange={(e) => {
                  setAuctionExpiry(e.target.value);
                }}
                defaultValue='select'
              >
                <option value='select' disabled>
                  Select Expiry Date
                </option>
                {auctionDates?.map((day, index) => (
                  <option key={index} value={day.value}>
                    {day.text}
                  </option>
                ))}
              </select>
              {/* <DatePicker
                className='w-100 modal__input'
                selected={startDate}
                showTimeSelect
                dateFormat='Pp'
                onChange={(date) => {
                  setStartDate(date);
                  console.log(typeof date);
                  console.log(date.getTime() / (1000 * 60 * 60 * 24));
                  setAuctionExpiry(date);
                }}
              /> */}
            </div>
          </div>
          <div className='modal__footer'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
                setFinalPrice('');
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                createAuction(
                  minBid,
                  finalPrice,
                  auctionExpiry,
                  instance._id.toString(),
                );
                setFinalPrice('');
                setAuctionExpiry('');
                setMinBid('');
                setIsShow(false);
                setLoading(true);
              }}
            >
              Set Market Price
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AuctionModal;
