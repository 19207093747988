import React, { Fragment } from 'react';
import { copyToClipboard } from '../../utils/helpers';

const CollectibleProperties = ({ collectible }) => {
  return (
    <Fragment>
      <h2 className='mt-5'>Details</h2>
      <p className='collectible__descriptive__text'>
        <strong>Description:</strong> {collectible?.description}
      </p>
      <div className='collectible__descriptive__text__wrapper'>
        <div className='collectible__descriptive__text__each__wrapper'>
          <p className='collectible__descriptive__text group__text'>
            <strong>Type</strong> <strong>{collectible?.type}</strong>
          </p>
        </div>
        <div className='collectible__descriptive__text__each__wrapper'>
          <p className='collectible__descriptive__text group__text'>
            <strong>Max Editions</strong>{' '}
            <strong>{collectible?.editions}</strong>
          </p>
        </div>
        <div className='collectible__descriptive__text__each__wrapper'>
          <p className='collectible__descriptive__text group__text'>
            <strong>Featured</strong>{' '}
            <strong>
              {collectible?.featured === false ? 'no' : 'yes'}
            </strong>
          </p>
        </div>
        <div className='collectible__descriptive__text__each__wrapper'>
          <p className='collectible__descriptive__text group__text'>
            <strong>Rights</strong>{' '}
            <strong>{collectible?.rights === 3 ? 'public' : 'private'}</strong>
          </p>
        </div>
        <div className='collectible__descriptive__text__each__wrapper'>
          <p className='collectible__descriptive__text group__text'>
            <strong>Category</strong> <strong>{collectible?.category}</strong>
          </p>
        </div>
        {collectible?.nsfw === true && (
          <div className='collectible__descriptive__text__each__wrapper'>
            <p className='collectible__descriptive__text group__text'>
              <strong>NSFW</strong> <strong>sensitive info</strong>
            </p>
          </div>
        )}
        {collectible?.type === 'fan' && (
          <div className='collectible__descriptive__text__each__wrapper'>
            <p className='collectible__descriptive__text group__text start__align'>
              <strong>Notes</strong> <strong>{collectible?.notes}</strong>
            </p>
          </div>
        )}
        <br />
      </div>
      <p className=''>
        <button
          className='btn btn-primary btn-sm text-center mx-1 follow__button share__button'
          onClick={() =>
            copyToClipboard(
              `https://market.nfttunz.io/market/${collectible.series}`,
            )
          }
        >
          Copy Share Link
        </button>
      </p>
    </Fragment>
  );
};

export default CollectibleProperties;
