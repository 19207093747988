import React, { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import UploadLoader from '../UploadLoader';
import { toFixedWithoutRounding } from '../../utils/helpers';
import Moment from 'react-moment';

const AuctionList = ({
  auctions,
  loading,
  addToCart,
  cart,
  removeFromCart,
  username,
  series,
  usd_price,
  collectible,
  isUser,
  cancelAuction,
  marketLoading,
  settleAuction,
  cancelBid,
  setAuctionId,
  setIsShowPlaceBid,
}) => {
  const [page, setPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [localAuctions, setLocalAuctions] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % auctions.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalAuctions(
      auctions
        .filter((d) => String(d?.properties?.series) === String(series))
        .slice(offset, endOffset),
    );
    setPage(Math.ceil(auctions.length / itemPerPage));
  }, [auctions, series, offset]);
  if (!isUser) {
    return (
      <Fragment>
        {marketLoading ? (
          <h6>Loading...</h6>
        ) : (
          <>
            <div className='collectible__action__buttons nft__table__max__height my-3'>
              <div className='nft__table__header'>
                <div className='nft__table__header__content'>
                  <div className='nft__table__row'>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Edition
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Owner
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Expires In
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Lowest Bid
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Settle Price
                    </div>
                    <div className='nft__table__row__item nft__table__market  nft__table__market__buttons w-auto'></div>
                    {/* auctions[0]?.account !== username */}
                    {auctions.some(
                      (d) => d.account !== username && d.series === series,
                    ) && (
                      <div className='nft__table__row__item nft__table__market nft__table__market__actions w-auto'>
                        {' '}
                        Action
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='nft__table'>
                <div className='nft__table__content'>
                  {loading && !auctions.length ? (
                    <div className='nft__table__row'>
                      <div className='nft__table__row__item'>
                        {' '}
                        <UploadLoader />
                      </div>
                    </div>
                  ) : (
                    localAuctions
                      .filter((sb) => sb?.properties?.series === series)
                      ?.map((instance) => {
                        return (
                          <div
                            className='nft__table__row nft__auction__table__row'
                            key={instance?._id}
                          >
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              No. {instance?.properties?.edition}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.previousAccount}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {
                                <Moment fromNow>
                                  {instance?.expiryTimestamp}
                                </Moment>
                              }
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.minBid} {instance?.priceSymbol} ($
                              {toFixedWithoutRounding(
                                instance?.minBid * usd_price,
                              )}
                              )
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.finalPrice} {instance?.priceSymbol}( $
                              {toFixedWithoutRounding(
                                instance?.finalPrice * usd_price,
                              )}
                              )
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__market__buttons w-auto'>
                              {instance?.previousAccount === username ? (
                                <div className='d-flex'>
                                  <button
                                    type='button'
                                    className='btn btn-success btn-sm m-1'
                                    onClick={() => {
                                      settleAuction(instance._id);
                                    }}
                                  >
                                    <i className='fa fa-check'></i>
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-danger btn-sm m-1'
                                    onClick={() => {
                                      cancelAuction(instance?.auctionId);
                                    }}
                                  >
                                    <i className='fa fa-times'></i>
                                  </button>
                                </div>
                              ) : (
                                <div className='nft__market__button__group d-flex justify-content-end w-100'>
                                  <button
                                    className='nft__buy__button'
                                    onClick={() => {
                                      setAuctionId(instance?.auctionId);
                                      setIsShowPlaceBid(true);
                                    }}
                                  >
                                    Bid
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-danger btn-sm m-1'
                                    onClick={() => {
                                      cancelBid(instance?.auctionId);
                                    }}
                                    disabled={!instance?.bids
                                      .map((b) => b.account)
                                      .includes(username)}
                                  >
                                    <i className='fa fa-times'></i>
                                  </button>
                                </div>
                              )}
                            </div>
                            {auctions.some(
                              (d) =>
                                d.account !== username && d.series === series,
                            ) && (
                              <div className='nft__table__row__item nft__table__market nft__table__market__actions'>
                                {cart.find(
                                  (item) => item._id === instance._id,
                                ) === undefined ? (
                                  <button
                                    className='nft__add__cart__button'
                                    onClick={() =>
                                      addToCart({
                                        ...instance,
                                        thumbnail: collectible.thumbnail,
                                        name: collectible.name,
                                        description: collectible.description,
                                      })
                                    }
                                  >
                                    <i
                                      className='fa fa-shopping-cart'
                                      aria-hidden='true'
                                    ></i>{' '}
                                    {''}
                                    <span>add</span>
                                  </button>
                                ) : (
                                  <button
                                    className={
                                      cart.find(
                                        (item) => item._id === instance._id,
                                      ) !== undefined
                                        ? `nft__del__cart__button btn-danger`
                                        : ''
                                    }
                                    onClick={() => removeFromCart(instance._id)}
                                  >
                                    <i
                                      className='fa fa-shopping-cart'
                                      aria-hidden='true'
                                    ></i>{' '}
                                    <span>del</span>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
            <div className='pagination__action__buttons'>
              <ReactPaginate
                breakLabel='...'
                nextLabel={<button>Next</button>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={page}
                activeClassName='active'
                previousLabel={<button>Prev</button>}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        )}
      </Fragment>
    );
  }
  if (isUser) {
    return (
      <Fragment>
        {marketLoading ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className='collectible__action__buttons nft__table__max__height my-3'>
              <div className='nft__table__header'>
                <div className='nft__table__header__content'>
                  <div className='nft__table__row'>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Edition
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Bids
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Expires In
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Lowest Bid
                    </div>
                    <div className='nft__table__row__item nft__table__market nft__table__auction'>
                      Leading Bid
                    </div>
                    <div className='nft__table__row__item nft__table__market  nft__table__market__buttons'></div>
                    {/* auctions[0]?.account !== username */}
                    {auctions.some(
                      (d) => d.account !== username && d.series === series,
                    ) && (
                      <div className='nft__table__row__item nft__table__market nft__table__market__actions w-auto'>
                        {' '}
                        Action
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='nft__table'>
                <div className='nft__table__content'>
                  {loading && !auctions.length ? (
                    <div className='nft__table__row'>
                      <div className='nft__table__row__item'>
                        {' '}
                        <UploadLoader />
                      </div>
                    </div>
                  ) : (
                    localAuctions
                      .filter((sb) => sb?.properties?.series === series)
                      ?.map((instance) => {
                        return (
                          <div
                            className='nft__table__row nft__auction__table__row'
                            key={instance?._id}
                          >
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              No. {instance?.properties?.edition}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.bids?.length} bids
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {
                                <Moment fromNow>
                                  {instance?.expiryTimestamp}
                                </Moment>
                              }
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.minBid} {instance?.priceSymbol} ($
                              {toFixedWithoutRounding(
                                instance?.minBid * usd_price,
                              )}
                              )
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__auction'>
                              {instance?.bids[instance?.currentLead]?.bid
                                ? `${
                                    instance?.bids[instance?.currentLead]?.bid
                                  } ${instance?.priceSymbol}( $
                              ${toFixedWithoutRounding(
                                instance?.finalPrice * usd_price,
                              )})`
                                : 'No bids yet'}
                            </div>
                            <div className='nft__table__row__item nft__table__market nft__table__market__buttons w-auto'>
                              <div className='d-flex'>
                                <button
                                  type='button'
                                  className='btn btn-success btn-sm m-1'
                                  onClick={() => {
                                    settleAuction(instance?.auctionId);
                                  }}
                                  disabled={!instance?.bids?.length}
                                >
                                  <i className='fa fa-check'></i>
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-danger btn-sm m-1'
                                  onClick={() => {
                                    cancelAuction(instance?.auctionId);
                                  }}
                                >
                                  <i className='fa fa-times'></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
            <div className='pagination__action__buttons'>
              <ReactPaginate
                breakLabel='...'
                nextLabel={<button>Next</button>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={page}
                activeClassName='active'
                previousLabel={<button>Prev</button>}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        )}
      </Fragment>
    );
  }
};

export default AuctionList;
