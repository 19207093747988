import { GET_TOP_CREATORS, GET_HOME_STATS } from '../actions/types';

const initialState = {
  top_creators: [],
  home_stats: {},
  loading: true,
};

const statisticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOP_CREATORS:
      return {
        ...state,
        top_creators: payload,
        loading: false,
      };
    case GET_HOME_STATS:
      return {
        ...state,
        home_stats: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default statisticsReducer;
