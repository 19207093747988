export const sidechain_rpc = process.env.REACT_APP_RPC;
export const sidechain_id = process.env.REACT_APP_SIDECHAIN_ID
export const API = process.env.REACT_APP_API
export const symbol = process.env.REACT_APP_SYMBOL;
export const ACCOUNT_CREATION_FEE = 3;
export const NODES = [
  'https://api.hive.blog',
  'https://api.deathwing.me',
  'https://rpc.ausbit.dev',
  'https://rpc.ecency.com',
];
export const tags = [
  {
    icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Music NFT',
    value: 'audio',
  },
  {
    icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Video NFT',
    value: 'video',
  },
  {
    icon: 'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Fan Club NFT',
    value: 'fan',
  },
  {
    icon: 'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Merchandise NFT',
    value: 'merchandise',
  },
];
export const songs = [
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Dj',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Party',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Music',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Concert',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Festival',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/2114365/pexels-photo-2114365.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Club',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/3784566/pexels-photo-3784566.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Dj Background',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
];


// export const sidechain_rpc =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_RPC_TEST
//     : process.env.NODE_ENV === 'staging'
//     ? process.env.REACT_APP_RPC_TEST
//     : process.env.REACT_APP_RPC_MAIN;
// export const sidechain_id =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_TEST_SIDECHAIN_ID
//     : process.env.NODE_ENV === 'staging'
//     ? process.env.REACT_APP_TEST_SIDECHAIN_ID
//     : process.env.REACT_APP_PROD_SIDECHAIN_ID;
// export const symbol = 'TUNZ';
// export const API =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_API_LOCAL
//     : process.env.NODE_ENV === 'staging'
//     ? process.env.REACT_APP_API_STAGE
//     : process.env.REACT_APP_API_PROD;
// export const ACCOUNT_CREATION_FEE = 3;
// export const NODES = [
//   'https://api.hive.blog',
//   'https://api.deathwing.me',
//   'https://rpc.ausbit.dev',
//   'https://rpc.ecency.com',
// ];
// export const tags = [
//   {
//     icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Music NFT',
//     value: 'audio',
//   },
//   {
//     icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Video NFT',
//     value: 'video',
//   },
//   {
//     icon: 'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Fan Club NFT',
//     value: 'fan',
//   },
//   {
//     icon: 'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Merchandise NFT',
//     value: 'merchandise',
//   },
// ];
// export const songs = [
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Nightclub',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Dj',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Party',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Nightclub',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Music',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Nightclub',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Concert',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Nightclub',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Festival',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Nightclub',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/2114365/pexels-photo-2114365.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Club',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
//   {
//     thumbnail:
//       'https://images.pexels.com/photos/3784566/pexels-photo-3784566.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
//     title: 'Dj Background',
//     artiste: 'bait002',
//     genre: 'pop',
//     duration: '3 mins',
//   },
// ];
