import { Fragment } from 'react';

const ApplicationsTable = ({ data, approveWhitelist, category }) => {
  return (
    <Fragment>
      <h3>
        {category === 'artiste'
          ? 'Artiste Application'
          : 'Whitelist Application'}
      </h3>
      <table className='table nfttunz__table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Username</th>
            <th scope='col'>Following</th>
            <th scope='col'>Referred By</th>
            <th scope='col'>Status</th>
            <th scope='col'>Banned</th>
            <th className='text-center' scope='col'>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={d.username}>
              <th scope='row'>{index + 1}</th>
              <td>{d.username}</td>
              <td>{d.following.length}</td>
              <td>{d.referred_by ? d.referred_by : 'null'}</td>
              <td>{d.application_pending === true ? 'true' : 'false'}</td>
              <td>{d.banned === false ? 'false' : 'true'}</td>

              <td>
                <div className='flex justify-content-end text-center'>
                  <button
                    className='btn btn-success btn-sm mx-2'
                    onClick={() =>
                      approveWhitelist(d.username, 'approve', true)
                    }
                  >
                    approve
                  </button>
                  <button
                    className='btn btn-danger btn-sm'
                    onClick={() =>
                      approveWhitelist(d.username, 'pending', true)
                    }
                    disabled={d.application_pending === true ? true : false}
                  >
                    pending
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ApplicationsTable;
