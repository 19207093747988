/* eslint-disable no-unused-vars */
import {
  GET_MARKET,
  GET_TRANSACTION_HISTORY,
  SET_USD_PRICE,
  SET_PAGINATION,
  GET_ADMIN_TRANSACTION_HISTORY,
  SET_TRANSACTION_LENGTH,
  GET_EARNINGS_TRANSACTION_HISTORY,
} from './types';
import axios from '../utils/axios';
import {
  fetchSeries,
  getUsdPrice,
  toFixedWithoutRounding,
} from '../utils/helpers';
// import store from '../store';

export const getMarketListed = (params) => async (dispatch) => {
  try {
    const data = await axios.get(`collectibles/market?series=${params.series}`);
    console.log(data.data);
  } catch (err) {
    console.error(err.message);
  }
};
export const getMarket =
  (page, limit = 20) =>
  async (dispatch) => {
    try {
      const data = await axios.get(`market`);
      dispatch({
        type: GET_MARKET,
        payload: data.data.slice(0, page * limit),
      });
      dispatch({
        type: SET_PAGINATION,
        payload: {
          limit,
          page,
          sort_by: 'newest',
          rights: null,
          category: null,
          price_min: '',
          price_max: '',
          has_more: true,
          market_length: data.data.length,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getMarketByFilters =
  (page, sort_by, price_min, price_max, rights, category, limit = 12) =>
  async (dispatch) => {
    try {
      const params = { sort_by: sort_by };

      if (price_min) {
        params.price_min = price_min;
      }
      if (price_max) {
        params.price_max = price_max;
      }
      if (rights) {
        params.rights = rights;
      }
      if (category) {
        params.category = category;
      }
      const data = await axios.get(`market`, { params });
      dispatch({
        type: GET_MARKET,
        payload: data.data.slice(0, page * limit),
      });
      dispatch({
        type: SET_PAGINATION,
        payload: {
          limit,
          page,
          sort_by: 'newest',
          rights: null,
          category: null,
          price_min: '',
          price_max: '',
          has_more: true,
          market_length: data.data.length,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };

export const getTransactions =
  (page, limit = 10) =>
  async (dispatch) => {
    try {
      const resp = await axios.get('/transactions/history');
      const data = resp.data;
      const filterdData = data.filter((d) => d.type === 'buy');
      const spreadData = filterdData.map((t) => ({
        ...t,
        data: JSON.parse(t.data),
      }));
      const uSeries = Array.from(new Set(spreadData.map((t) => t.data.series)));
      const _series = await fetchSeries(uSeries);
      const history = spreadData.map((n) => {
        const series = _series.find((s) => s.series === n.series);
        return {
          ...n,
          ...n.data,
          buyer: n.account,
          seller: n.data.seller,
          name: series?.name,
          file: series?.file,
          thumbnail: series?.thumbnail,
          series: n.series,
          nsfw: series?.nsfw,
        };
      });
      dispatch({
        type: GET_TRANSACTION_HISTORY,
        payload: history.slice(0, page * limit),
      });
      dispatch({
        type: SET_TRANSACTION_LENGTH,
        payload: filterdData.length,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

export const getAdminTransactions =
  (page, limit = 20) =>
  async (dispatch) => {
    try {
      const resp = await axios.get('/transactions/history');
      const data = resp.data;

      dispatch({
        type: GET_ADMIN_TRANSACTION_HISTORY,
        payload: data.splice(0, page * limit),
      });
      dispatch({
        type: SET_TRANSACTION_LENGTH,
        payload: data.length,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getEachEarningsTransactions = (query) => async (dispatch) => {
  try {
    let params = {};
    if (query.username) {
      params.username = query.username;
    }
    if (query.series) {
      params.series = query.series;
    }
    if (!query.types) {
      params.types =
        'issue,buy,burn,transfer,auction_create,auction_bid,auction_update_bid,auction_cancel_bit,auction_cancel,auction_settle,auction_expired';
    } else {
      params.types = query.types;
    }

    const trx = await axios.get(`/transactions/history`, { params: params });
    const data = trx.data;
    const filterdData = data.filter((d) => d.type === 'buy');
    const spreadData = filterdData.map((t) => ({
      ...t,
      data: JSON.parse(t.data),
    }));
    const uSeries = Array.from(new Set(spreadData.map((t) => t.data.series)));
    const _series = await fetchSeries(uSeries);
    const history = spreadData.map((n) => {
      const series = _series.find((s) => s.series === n.series);
      return {
        ...n,
        ...n.data,
        buyer: n.account,
        seller: n.data.seller,
        name: series?.name,
        file: series?.file,
        thumbnail: series?.thumbnail,
        series: n.series,
        nsfw: series?.nsfw,
      };
    });
    dispatch({
      type: GET_EARNINGS_TRANSACTION_HISTORY,
      payload: history,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getEarningsTransactions = (query) => async (dispatch) => {
  try {
    let params = {};
    if (query.username) {
      params.username = query.username;
    }
    if (query.series) {
      params.series = query.series;
    }
    if (!query.types) {
      params.types =
        'issue,buy,burn,transfer,auction_create,auction_bid,auction_update_bid,auction_cancel_bit,auction_cancel,auction_settle,auction_expired';
    } else {
      params.types = query.types;
    }
    const resp = await axios.get(`/transactions/history`, { params: params });
    const data = resp.data;
    const filterdData = data.filter((d) => d.type === 'buy');
    const spreadData = filterdData.map((t) => ({
      ...t,
      data: JSON.parse(t.data),
    }));
    const uSeries = Array.from(new Set(spreadData.map((t) => t.data.series)));
    const _series = await fetchSeries(uSeries);
    const history = spreadData.map((n) => {
      const series = _series.find((s) => s.series === n.series);
      return {
        ...n,
        ...n.data,
        buyer: n.account,
        seller: n.data.seller,
        name: series?.name,
        file: series?.file,
        thumbnail: series?.thumbnail,
        series: n.series,
        nsfw: series?.nsfw,
      };
    });
    dispatch({
      type: GET_EARNINGS_TRANSACTION_HISTORY,
      payload: history,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const setUsdPrice = () => async (dispatch) => {
  try {
    const usdPrice = await getUsdPrice();
    const roundedPrice = toFixedWithoutRounding(usdPrice);
    dispatch({
      type: SET_USD_PRICE,
      payload: roundedPrice,
    });
  } catch (err) {
    console.log(err.message);
  }
};
export const getAuctions =
  (page, limit = 20) =>
  async (dispatch) => {
    try {
      const data = await axios.get(`market/auctions`);
      dispatch({
        type: GET_MARKET,
        payload: data.data.slice(0, page * limit),
      });
      dispatch({
        type: SET_PAGINATION,
        payload: {
          limit,
          page,
          sort_by: 'newest',
          rights: null,
          category: null,
          price_min: '',
          price_max: '',
          has_more: true,
          market_length: data.data.length,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getAuctionsListed =
  (page, sort_by, price_min, price_max, rights, category, limit = 12) =>
  async (dispatch) => {
    try {
      const params = {};
      if (sort_by) {
        params.sort_by = sort_by;
      }

      if (price_min) {
        params.price_min = price_min;
      }
      if (price_max) {
        params.price_max = price_max;
      }
      if (rights) {
        params.rights = rights;
      }
      if (category) {
        params.category = category;
      }
      const data = await axios.get(`market/auctions`, { params });
      dispatch({
        type: GET_MARKET,
        payload: data.data.slice(0, page * limit),
      });
      dispatch({
        type: SET_PAGINATION,
        payload: {
          limit,
          page,
          sort_by: 'newest',
          rights: null,
          category: null,
          price_min: '',
          price_max: '',
          has_more: true,
          market_length: data.data.length,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };
