import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../assets/images/TUNZ_Token.png';
import { logout, readNotifications } from '../actions/users';
import { searchCollectibles } from '../actions/collectibles';
import Notifications from './Notifications';

// import { users } from "../reducers/users";

const Navbar = ({
  isShowLogIn,
  isShow,
  auth,
  logout,
  readNotifications,
  searchCollectibles,
  cart,
  toggleTheme,
  theme, history
}) => {
  const [search, setSearch] = useState('');

  const toogleAction = async () => {
    if (!auth.authenticated) {
      isShowLogIn(!isShow);
    } else {
      const answer = window.confirm('Are you sure to log out?');
      if (answer) {
        logout();
      }
    }
  };

  return (
    <Fragment>
      <nav className='navbar navbar-expand-lg navbar-toggleable-sm p-0'>
        <div className='nfttunz__navbar__landing'>
          <div className='d-flex unset__flex__mobile'>
            <div className='d-flex mobile__flex__col'>
              <Link className='navbar-brand nfttunz__navbar__brand' to='/'>
                <img src={logo} alt='nav logo' width={60} />
              </Link>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon flex__mobile'>
                  <i className='fas fa-bars'></i>
                </span>
              </button>
            </div>
            <div
              className='collapse navbar-collapse nfttunz__navbar__links__wrapper'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav nfttunz__navbar__links'>
                <li className='nav-item'>
                  <Link className='nav-link' to='/browse'>
                    Browse
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/market'>
                    Market
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link className='nav-link' to='/auctions'>
                    Auctions
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link className='nav-link' to='/faq'>
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='nfttunz__navbar__search__main__wrapper hide__mobile'>
            <form className='d-flex'>
              <div className='nfttunz__navbar__search__wrapper'>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className='nfttunz__navbar__search__input'
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                />
                <Link to={`/browse/${search}`} className='btn nfttunz__icon'>
                  <i className='fa fa-search'></i>
                </Link>
              </div>
            </form>
            {auth.authenticated && (
              <Fragment>
                {auth.whitelisted && (
                  <div className='nfttunz__notification__wrapper margin__left'>
                    <Link
                      className='nav-link'
                      to='/mint'
                      datatype='tool-tip'
                      title='Mint'
                    >
                      <i className='fa fa-plus'></i>
                    </Link>
                  </div>
                )}
                <div className='dropdown nfttunz__notification__wrapper margin__left'>
                  <button
                    className='dropdown-toggl'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    {auth.notifications.length > 0 && (
                      <span className='notification'>
                        {auth.notifications.length}
                      </span>
                    )}
                    <i className='fas fa-bell'></i>
                  </button>
                  <ul
                    className='dropdown-menu dropdown-menu-lg-end p-0'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <Notifications
                      auth={auth}
                      readNotifications={readNotifications}
                    />
                  </ul>
                </div>
                <div className='nfttunz__notification__wrapper margin__left'>
                  <Link className='dropdown-toggl' to='/checkout'>
                    {cart?.length > 0 && (
                      <span className='notification'>{cart?.length}</span>
                    )}
                    <i className='fas fa-shopping-cart'></i>
                  </Link>
                </div>
                <div className='nfttunz__notification__wrapper margin__left'>
                  <Link className='dropdown-toggl' to='/profile/wallet'>
                    <i className='fas fa-wallet'></i>
                  </Link>
                </div>
                <div className='nfttunz__avatar__wrapper margin__left'>
                  <Link to='/profile'>
                    <img
                      src={
                        auth.avatar
                          ? auth.avatar
                          : 'https://cdn.pixabay.com/photo/2016/05/24/22/54/icon-1413583_960_720.png'
                      }
                      alt={auth.username}
                      width={30}
                    />
                  </Link>
                </div>
              </Fragment>
            )}

            <div className='dropdown nfttunz__login__button margin__left d-flex'>
              {!auth.authenticated ? (
                <button className='nav__login__button' onClick={toogleAction}>
                  <span>Login</span>
                </button>
              ) : (
                <button
                  className='dropdown-toggl'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {auth.username} <i className='fa fa-caret-down'></i>
                </button>
              )}

              <ul
                className='dropdown-menu dropdown-menu-lg-end'
                aria-labelledby='dropdownMenuButton1'
              >
                <li>
                  <Link className='dropdown-item' to='/profile'>
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/market'>
                    Gallery
                  </Link>
                </li>
                {auth.admin === true && (
                  <li>
                    <Link className='dropdown-item' to='/admin'>
                      Admin
                    </Link>
                  </li>
                )}
                <li>
                  <button onClick={toogleAction} className='dropdown-item'>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
            {!auth.authenticated && (
              <div className='dropdown nfttunz__register__button margin__left d-flex'>
                <Link className='nav__register__button' to='/register'>
                  <span>Register</span>
                </Link>
              </div>
            )}
            {/* To be re-introduced after a while */}
            {/* <div className='nfttunz__notification__wrapper margin__left d-flex'>
              <button onClick={() => toggleTheme('dark')}>
                {theme === 'dark' ? (
                  <i className='fas fa-sun'></i>
                ) : (
                  <i className='fas fa-moon'></i>
                )}
              </button>
            </div> */}
          </div>
        </div>
      </nav>
      {/* <nav className='navbar p-0 hide__desktop'>
        <div className='nfttunz__navbar'>
          <Link className='navbar-brand nfttunz__navbar__brand' to='/'>
            <img src={logo} alt='nav logo' width={60} />
          </Link>
          <button
            className='navbar-toggler hide__sm'
            id='nfttunz__navbar__toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className='fas fa-bars' style={{ color: '#9CA0A3' }}></i>
          </button>
          <div
            className='nfttunz__navbar__links__wrapper'
            id='navbarSupportedContent'
          >
            <ul className='nfttunz__navbar__links'>
              
              <li className='nav-item'>
                <Link
                  className='nav-link active'
                  aria-current='page'
                  to='/activity'
                >
                  Activity
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/feed'>
                  Feed
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/market'>
                  Market
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/communities'>
                  Community
                </Link>
              </li>
            </ul>
          </div>
          <div className='nfttunz__navbar__search__main__wrapper hide__mobile'>
            <form className='d-flex' onSubmit={handleSubmit}>
              <div className='nfttunz__navbar__search__wrapper'>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className='nfttunz__navbar__search__input'
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                />
                <button className='btn nfttunz__icon' type='submit'>
                  <i className='fa fa-search'></i>
                </button>
              </div>
            </form>
            {auth.authenticated && (
              <Fragment>
                {auth.whitelisted && (
                  <div className='nfttunz__notification__wrapper margin__left'>
                    <Link className='nav-link' to='/mint'>
                      <i className='fa fa-plus'></i>
                    </Link>
                  </div>
                )}
                <div className='dropdown nfttunz__notification__wrapper margin__left'>
                  <button
                    className='dropdown-toggl'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    {auth.notifications.length > 0 && (
                      <span className='notification'>
                        {auth.notifications.length}
                      </span>
                    )}
                    <i className='fas fa-bell'></i>
                  </button>
                  <ul
                    className='dropdown-menu dropdown-menu-lg-end p-0'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <div className='notification__overflow'>
                      {auth.notifications.length > 1 && (
                        <button
                          className='bg-transparent mark-as-read-button'
                          onClick={() =>
                            readNotifications(
                              auth.notifications.map(
                                (notification) => notification.id,
                              ),
                            )
                          }
                        >
                          mark as all read
                        </button>
                      )}
                      {auth.notifications.length < 1 ? (
                        <p className='p-2 border-bottom notification-paragraph'>
                          You currently have no notifications at the moment
                        </p>
                      ) : (
                        auth.notifications.map((notification) => {
                          return (
                            <li key={notification.id}>
                              <p className='p-2 border-bottom notification-paragraph'>
                                You have just{' '}
                                {notification.type === 'sell'
                                  ? 'sold'
                                  : 'bought'}{' '}
                                a song: <br />
                                <strong>Editon: </strong>
                                {JSON.parse(notification.data).edition} of
                                series {JSON.parse(notification.data).series} to{' '}
                                {JSON.parse(notification.data).buyer} for{' '}
                                {JSON.parse(notification.data).price}{' '}
                                <strong>
                                  {JSON.parse(notification.data).symbol}
                                </strong>
                              </p>
                              <button
                                className='bg-transparent mark-as-read-button'
                                onClick={() =>
                                  readNotifications([notification.id])
                                }
                              >
                                mark as read
                              </button>
                            </li>
                          );
                        })
                      )}
                    </div>
                  </ul>
                </div>
                <div className='nfttunz__notification__wrapper margin__left'>
                  <Link className='dropdown-toggl' to='/checkout'>
                    {cart?.length > 0 && (
                      <span className='notification'>{cart?.length}</span>
                    )}
                    <i className='fas fa-shopping-cart'></i>
                  </Link>
                </div>
                <div className='nfttunz__avatar__wrapper margin__left'>
                  <Link to='/profile'>
                    <img
                      src={
                        auth.avatar
                          ? auth.avatar
                          : 'https://cdn.pixabay.com/photo/2016/05/24/22/54/icon-1413583_960_720.png'
                      }
                      alt={auth.username}
                      width={30}
                    />
                  </Link>
                </div>
              </Fragment>
            )}

            <div className='dropdown nfttunz__login__button margin__left d-flex'>
              {!auth.authenticated ? (
                <>
                  <button className='' onClick={toogleAction}>
                    <span>Login</span>
                  </button>
                </>
              ) : (
                <button
                  className='dropdown-toggl'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {auth.username} <i className='fa fa-caret-down'></i>
                </button>
              )}

              <ul
                className='dropdown-menu dropdown-menu-lg-end'
                aria-labelledby='dropdownMenuButton1'
              >
                <li>
                  <Link className='dropdown-item' to='/profile'>
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/market'>
                    Gallery
                  </Link>
                </li>
                {auth.admin === true && (
                  <li>
                    <Link className='dropdown-item' to='/admin'>
                      Admin
                    </Link>
                  </li>
                )}
                <li>
                  <button onClick={toogleAction} className='dropdown-item'>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
            <div className='nfttunz__notification__wrapper margin__left d-flex'>
              <button onClick={() => toggleTheme('dark')}>
                {theme === 'dark' ? (
                  <i className='fas fa-sun'></i>
                ) : (
                  <i className='fas fa-moon'></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.users,
  cart: state.cart.cart,
});

export default connect(mapStateToProps, {
  logout,
  readNotifications,
  searchCollectibles,
})(Navbar);
