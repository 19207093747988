import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  ADD_SELL_CART,
  REMOVE_SELL_CART,
  ADD_PRICE_CART,
  REMOVE_PRICE_CART,
  CLEAR_SELL_CART,
  CLEAR_PRICE_CART,
} from './types';

export const addToCart = (instance) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TO_CART,
      payload: instance,
    });
  } catch (err) {
    console.log(err.message);
  }
};

export const removeFromCart = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: id,
    });
  } catch (err) {
    console.log(err.message);
  }
};

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART,
  });
};

export const addSellCart = (id) => async (dispatch) => {
  dispatch({
    type: ADD_SELL_CART,
    payload: id,
  });
};


export const removeSellCart = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_SELL_CART,
    payload: id,
  });
};

export const clearSellCart = () => async dispatch => {
  dispatch({
    type: CLEAR_SELL_CART
  })
}

export const addPriceCart = (id) => async (dispatch) => {
  dispatch({
    type: ADD_PRICE_CART,
    payload: id,
  });
};

export const removePriceCart = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_PRICE_CART,
    payload: id,
  });
};
export const clearPriceCart = () => async dispatch => {
  dispatch({
    type: CLEAR_PRICE_CART,
  });
}