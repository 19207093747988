import React from 'react';
import Modal from 'react-awesome-modal';
import { toFixedWithoutRounding } from '../../utils/helpers';

const PlaceBidModal = ({
  isShow,
  setIsShow,
  auctionId,
  auctionPrice,
  setAuctionPrice,
  placeBid,
  usd_price,
}) => {

  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='270'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Auction NFT</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mt-1'>Set the parameters to Auction this NFT.</p>
            <div>
              <input
                value={auctionPrice}
                onChange={(e) => setAuctionPrice(e.target.value)}
                className='w-100 modal__input'
                type='text'
                placeholder='Enter minimum bid...'
              />
              <p className='modal__currency__estimate'>
                {auctionPrice} SWAP.HIVE <span className='text-danger'>=</span>{' '}
                ${toFixedWithoutRounding(auctionPrice * usd_price)}
              </p>
            </div>
          </div>
          <div className='modal__footer'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                setIsShow(false);
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                placeBid(auctionId, auctionPrice);
                setAuctionPrice('');
                setIsShow(false);
              }}
            >
              Place Bid
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PlaceBidModal;
