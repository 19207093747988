import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import UploadLoader from '../UploadLoader';
const ActivityCard = ({ transaction, loading }) => {
  return (
    <Fragment>
      <div className='activity__card__wrapper'>
        <div className='activity__image'>
          {!loading ? (
            <img
              className='img'
              src={transaction.thumbnail}
              alt='pixabay'
              width={100}
            />
          ) : (
            <UploadLoader size='small' />
          )}
        </div>
        <div className='activity__desc'>
          <h5>Description:</h5>
          <p>
            {transaction.type === 'buy' ? (
              <span>
                <a
                  href={`/@${transaction.buyer}`}
                  rel='noreferrer'
                >
                  @{transaction.buyer.toUpperCase()}
                </a>{' '}
                just bought a collection named{' '}
                <strong>{transaction.name.toUpperCase()}</strong> and series (
                {transaction.series}) from{' '}
                <a
                  href={`/@${transaction.seller}`}
                  rel='noreferrer'
                >
                  @{transaction.seller}
                </a>{' '}
                for {transaction.price} {transaction.symbol} {' '}
               <br />
                <small>
                  <Moment fromNow>{transaction.timestamp}</Moment>
                </small>
              </span>
            ) : (
              <span>
                A new {transaction.type} series {transaction.series} has been
                minted by {transaction.account} with {transaction.data.editions}{' '}
                numbers of editions.
              </span>
            )}
          </p>
          <br />
          Click{' '}
          <a
            href={`https://he.dtools.dev/tx/${transaction.trx_id}`}
            target='_blank'
            rel='noreferrer'
          >
            <strong>here</strong>
          </a>{' '}
          to view transaction on the sidechain
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
});
export default connect(mapStateToProps)(ActivityCard);
