import { toast } from 'react-toastify';
import axios from './axios';
import _axios from 'axios';
import dsteem from '@hivechain/dsteem';
import { PrivateKey, utils } from '@hiveio/dhive';
import { randomBytes } from 'crypto';

export const setToastNotification = async (message, type) => {
  toast(message, { type, autoClose: 2000 });
};

export const toFixedWithoutRounding = (t, l = 3) => {
  const a = 10 ** l;
  const s = t * a;
  return Math.trunc(s) / a;
};

export const parseJSON = (json) => {
  let result = {};

  try {
    result = JSON.parse(json);
  } catch {
    console.log('Unable to parse JSON');
  }

  return result;
};

export const uploadFile = async (file, type) => {
  try {
    var percentage;
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function (progressEvent) {
        const { loaded, total } = progressEvent;
        console.log(
          (loaded * 100) / total,
          `${loaded / 1024 / 1024}MB`,
          total,
          `${total / 1024 / 1024}MB`,
        );
        var percentCompleted = parseInt(Math.round((loaded * 100) / total));
        percentage = percentCompleted;
      },
    };
    const data_upload = new FormData();
    data_upload.append('file', file);
    data_upload.append('type', type);
    const data = await axios.post('/upload', data_upload, config);
    if (!data) {
      setToastNotification('Unsurpported file type', 'error');
      return {};
    }
    const obj = {
      data: data.data,
      progress: percentage,
    };
    return obj;
  } catch (err) {
    console.error(err.message);
  }
};
export const uploadMultipleFiles = async (files, type) => {
  try {
    var percentage;
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
        percentage = percentCompleted;
      },
    };
    const data_upload = new FormData();
    data_upload.append('type', type);
    for (let file in files) {
      if (isNaN(Number(file)) || files[file] === 'length') break;
      data_upload.append('file', files[file]);
    }
    const data = await axios.post('/upload-album', data_upload, config);
    console.log(data.data);
    if (!data) {
      setToastNotification('Upload Failed', 'error');
      return {};
    }
    const obj = {
      data: data.data,
      progress: percentage,
    };
    return obj;
  } catch (err) {
    console.error(err.message);
  }
};

export const arrayChunk = (array, size = 20) => {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index));
    index += size;
  }

  return chunkedArray;
};

export const fetchSeries = async (seriesNames) => {
  let seriesData = {};

  if (Array.isArray(seriesNames)) {
    const promises = [];

    const chunks = arrayChunk(seriesNames, 500);

    for (let i = 0; i < chunks.length; i += 1) {
      let eachSeries = await axios.post('collectibles/info', {
        series: chunks[i].toString(),
      });
      promises.push(eachSeries.data);
    }
    seriesData = (await Promise.all(promises)).flat(Infinity);
  } else {
    seriesData = await axios.call('collectibles/info', {
      series: seriesNames,
    });
  }

  return seriesData;
};

export const tagValidator = (tag) => {
  return (
    tag === tag.toLowerCase() &&
    tag.length > 1 &&
    tag.length < 16 &&
    /^[a-z0-9_-]*$/.test(tag)
  );
};

export const maxPossibleEditions = (bal, base_fee, fee) => {
  return Math.floor((bal - base_fee) / (fee * 1));
};

export const getUsdPrice = async () => {
  try {
    const result = await _axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=HIVE&vs_currencies=USD',
    );
    return result.data.hive.usd;
  } catch (err) {
    console.log(err.message);
  }
};
export const copyToClipboard = async (e) => {
  const selected = await navigator.permissions.query({
    name: 'clipboard-write',
  });
  if (selected.state === 'granted' || selected.state === 'prompt') {
    try {
      await navigator.clipboard.writeText(e);
    } catch (err) {
      setToastNotification('Error copying to clipboard', 'error');
    }
  }
  setToastNotification('Text Copied!', 'success');
};

export const generatePassword = async (length) => {
  // const charset =
  //   'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  // let retVal = '';
  // for (let i = 0, n = charset.length; i < length; ++i) {
  //   retVal += charset.charAt(Math.floor(Math.random() * n));
  // }
  // return retVal;
  const password = `P${PrivateKey.fromSeed(
    randomBytes(32).toString('hex'),
  ).toString()}`;
  return password;
};

export const getPrivateKeys = (
  username,
  password,
  roles = ['owner', 'active', 'posting', 'memo'],
) => {
  const privKeys = {};
  roles.forEach((role) => {
    privKeys[role] = dsteem.PrivateKey.fromLogin(
      username,
      password,
      role,
    ).toString();
    privKeys[`${role}Pubkey`] = dsteem.PrivateKey.from(privKeys[role])
      .createPublic()
      .toString();
  });

  return privKeys;
};

export const kFormatter = (num) => {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
};

export const submitPost = async (
  account,
  title,
  body,
  tags,
  permlink,
  payload,
) => {
  try {
    const json_metadata = {
      tags: ['test', 'tunz'],
      app: 'hiveblog/0.1',
      format: 'markdown',
      description: 'Testing Post from HiveBlog',
    };
    const json = {
      author: account,
      body: body,
      json_metadata: JSON.stringify(json_metadata),
      parent_author: '',
      parent_permlink: tags[0],
      permlink: permlink,
      title: title,
    };

    // const comment_options = {
    //   author: account,
    //   permlink,
    //   max_accepted_payout: '1000000.000 HBD',
    //   percent_hbd: 10000,
    //   allow_votes: true,
    //   allow_curation_rewards: true,
    //   extensions: [],
    // };
    const data = [
      [
        'comment',
        {
          ...json,
        },
      ],
      [
        'comment_options',
        {
          author: account,
          permlink,
          max_accepted_payout: '1000000.000 HBD',
          percent_hbd: 10000,
          allow_votes: true,
          allow_curation_rewards: true,
          extensions: [],
        },
      ],
      [
        'custom_json',
        {
          required_auths: [account],
          required_posting_auths: [],
          id: 'ssc-mainnet-hive',
          json: JSON.stringify(payload),
        },
      ],
    ];

    window.hive_keychain.requestBroadcast(account, data, 'Active', (r) => {
      if (r.success) {
        console.log(r);
        setToastNotification(r.message, 'success');
      }
      console.log('error', r);
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const sleep = (ms) => utils.sleep(ms);
