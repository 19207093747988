import React, { Fragment } from 'react'
import Tag from './Tag'
import uuid from "uuid";
import { connect } from 'react-redux';


const Tags = ({ tags, settings: { categories }, handleSearch }) => {
  return (
    <Fragment>
      <div className='tags__wrapper'>
        <Tag
          title='All'
          value='all'
          icon='https://images.pexels.com/photos/3784566/pexels-photo-3784566.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50'
          handleSearch={handleSearch}
        />
        {tags.map((tag) => {
          const id = uuid();
          return (
            <Tag
              key={id}
              title={tag.title}
              icon={tag.icon}
              value={tag.value}
              handleSearch={handleSearch}
            />
          );
        })}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
});
export default connect(mapStateToProps, {})(Tags)
