import React from 'react';
import Modal from 'react-awesome-modal';

const ConfirmModal = ({ show, handleClose, logout, username }) => {
  return (
    <div className='modal-parent'>
      <Modal
        visible={show}
        width='500'
        height='220'
        effect='fadeInUp'
        onClickAway={() => handleClose()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Logout</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mb-3 text-center'>
              Hi {username} are you sure you would like to logout?
            </p>
            <div></div>
          </div>
          <div className='modal__footer'>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                handleClose();
              }}
            >
              No
            </button>
            <button
              type='button'
              className='modal__button'
              onClick={() => {
                logout();
                handleClose();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
